import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthStoreService } from 'src/app/services/auth-store.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { DataOperation } from 'src/app/shared/enums';
import { MasterShip, Ship } from 'src/app/shared/models';
import { MasterShipOperationsRequest, MasterShipOperationsResult, ShipOperationsRequest, ShipOperationsResult } from 'src/app/shared/request-models';

@Component({
  selector: 'app-add-ship-to-master-ship',
  templateUrl: './add-ship-to-master-ship.component.html',
  styleUrls: ['./add-ship-to-master-ship.component.css']
})
export class AddShipToMasterShipComponent {

  masterShipId: string = "";
  currentMasterShip: MasterShip = {} as MasterShip;

  selectedShips: Ship[] = [] as Ship[];
  currentShips: Ship[] = [] as Ship[];


  showActive: string = "active";
  filteredShips: Ship[] = [];
  allShips: Ship[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _auth: AuthStoreService,
    private _dataService: DataService,
    private _errorHandler: ErrorHandlerService,
    private _toastr: ToastrService

  ) { }

  ngOnInit(): void {
    try {
      this.masterShipId = this._route.snapshot.paramMap.get('mastershipid');
      this.getItem();
    }
    catch (e) {
      console.error(e);
      this._router.navigate(["/"]);
    }
    console.log(this._auth.currentUser);
  }

  getItem(){
    let request: MasterShipOperationsRequest = new MasterShipOperationsRequest();
    request.item = {} as MasterShip;
    request.item.id = this.masterShipId;
    request.action = DataOperation.Read;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.masterShipOperations(request).subscribe((data: MasterShipOperationsResult) => {

      if (data.message.ok === true && data.items?.length > 0) {
        this.currentMasterShip = data.items[0];
        this.getShips();
      }
      else {
        console.error("message", data.message);
      }
    }, (err) => this.ifError(err));
  }

  getShips(){
    let request: ShipOperationsRequest = new ShipOperationsRequest();
    request.action = DataOperation.ListByAgencies;
    request.item = {} as Ship;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.shipOperations(request).subscribe((data: ShipOperationsResult) => {

      if (data.message.ok === true) {
        this.allShips = data.items;
        this.filteredShips = this.allShips.filter(item => item?.masterShipId?.length == 0);
        this.getCurrentShipsForMasterShip(this.masterShipId);
        console.log('items', this.filteredShips);
      }
      else {
        console.error("message", data.message);
      }

    }, (err) => this.ifError(err));
  }

  getCurrentShipsForMasterShip(masterShipId: string){
    let request: ShipOperationsRequest = new ShipOperationsRequest();
    request.action = DataOperation.ListByMasterShip;
    request.item = {} as Ship;
    request.item.masterShipId = masterShipId;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.shipOperations(request).subscribe((data: ShipOperationsResult) => {

      if (data.message.ok === true) {
        this.currentShips = data.items;
        console.log('items', this.currentShips);
      }
      else {
        console.error("message", data.message);
      }

    }, (err) => this.ifError(err));
  }

  shipSelected(item: Ship){
    return this.selectedShips.includes(item);
  }

  shipAction(item: Ship, action: string){
    switch(action.toLowerCase()){
      case "add":
        this.selectedShips.push(item);
        break;
      case "remove":
        const index = this.selectedShips.indexOf(item, 0);
        if (index > -1) {
          this.selectedShips.splice(index, 1);
        }
        break;
      default:
        break;
    }
  }

  actionItem(items: Ship[], action: string){
    switch(action.toLowerCase()){
      case "insert":
        let multiRequest: ShipOperationsRequest[] = [];
        items.forEach(item => {
          let request: ShipOperationsRequest = new ShipOperationsRequest();
          request.action = DataOperation.SetMasterShipId;
          if (this._auth.currentUser) {
            request.key = this._auth.currentUser.id;
          }
          request.item = item;
          request.item.masterShipId = this.currentMasterShip.id;
          multiRequest.push(request);
          console.log(multiRequest);
          this.multiAction(multiRequest);
        });
        break;
      default:
        break;
    }
  }

  removeShipFromMasterShip(item: Ship){
    let request: ShipOperationsRequest = new ShipOperationsRequest();
    request.action = DataOperation.SetMasterShipId;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    request.item = item;
    request.item.masterShipId = null;
    this._dataService.shipOperations(request).subscribe((data: ShipOperationsResult) => {
      if (data.message.ok === true) {
        this._toastr.success("Fjarlægði " + item.name + " af " + this.currentMasterShip.name);
        this.getCurrentShipsForMasterShip(this.masterShipId);
      }
      else {
        console.error("message", data.message);
      }
    }, (err) => this.ifError(err));
  }

  multiAction(requests: ShipOperationsRequest[]){
    let index: number = 0;
    requests.forEach(request => {
      this._dataService.shipOperations(request).subscribe((data: ShipOperationsResult) => {
        index += 1;
        if (data.message.ok === true) {
          if (index === requests.length) {
            this.selectedShips = [];
            this.getShips();
            this.getCurrentShipsForMasterShip(this.masterShipId);
            if(requests.length > 1){
              this._toastr.success("Bætti " + requests.length.toString() + " notendum við á " + this.currentMasterShip.name);
            }else{
              this._toastr.success("Bætti " + data.items[0]?.name + " á " + this.currentMasterShip.name);
            }
          }
        }
        else {
          console.error("message", data.message);
        }
      }, (err) => this.ifError(err));
    })
  }

  getCreateAgencyText(size: number){
    if(size == 1){
      return "Tengja " + this.selectedShips[0]?.name + " við " + this.currentMasterShip.name;
    }else if(size > 1){
      return "Tengja " +  size.toString() + " skip við " + this.currentMasterShip.name;
    }
  }




  ifError(err: any) {
    console.log(err);

    return this._errorHandler.handleError(err);
  };

}

<div>
  <div class="top-section">
    <h2 class="page-title">Skip</h2>
  </div>
<div>
  <div class="filters-section btn-group btn-group-toggle" data-toggle="buttons">
    <label class="btn btn-secondary" [ngClass]="{'active':showActive=='all'}">
      <input type="radio" name="options" id="option1" autocomplete="off" [(ngModel)]="showActive" (ngModelChange)="onFilterStringChange()" [value]="'all'"> Með og án
    </label>
    <label class="btn btn-secondary" [ngClass]="{'active':showActive=='assigned'}">
      <input type="radio" name="options" id="option2" autocomplete="off" [(ngModel)]="showActive" (ngModelChange)="onFilterStringChange()" [value]="'assigned'"> Aðeins Skráð
    </label>
    <label class="btn btn-secondary" [ngClass]="{'active':showActive=='unassigned'}">
      <input type="radio" name="options" id="option3" autocomplete="off" [(ngModel)]="showActive" (ngModelChange)="onFilterStringChange()" [value]="'unassigned'"> Aðeins Óskráð
    </label>
  </div>
</div>

<div *ngIf="allShips?.length > 0">
  <table class="table">
      <thead>
        <th>Nafn</th>
      </thead>
      <tbody>
        <tr *ngFor="let item of filteredItems">
          <td>{{item.name}}</td>
        </tr>
      </tbody>
  </table>
</div>

<h4 *ngIf="allShips?.length === 0">
  Engar heimildir fundust
</h4>

</div>

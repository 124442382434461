<button class="input-group-text" (click)="generatePDF()" *ngIf="pdfGenerationReady">Sækja PDF</button>
<p *ngIf="displayPDFContent">Er að smíða PDF...</p>
<div class="landingreport-container prent-report" #pdfContent>
  <h2 class="flex-100">Löndunarskýrsla</h2>
  <div class="flex-50">
    <h3>Kaupandi</h3>
    <h4><span>Brim hf.</span></h4>
    <h4><span>541185-0389</span></h4>
    <br>
  </div>
  <div class="flex-50">
    <h3>Seljandi</h3>
    <h4><span>{{(currentMasterShip$ | async)?.customer?.name}}</span></h4>
    <h4><span>{{(currentMasterShip$ | async)?.customer?.ssn}}</span></h4>
    <h4><span>{{(currentMasterShip$ | async)?.customer?.address}}</span></h4>
    <h4><span>{{(currentMasterShip$ | async)?.customer?.city}}</span></h4>
    <br>
  </div>
  <div class="underline flex-100"></div>
  <div class="flex-50">
    <h3>Skráning</h3>
    <h4>Vigtarmaður: <span>{{(currentLandingSum$ | async)?.weighterName}}</span></h4>
    <h4>Löndun byrjar: <span>{{landingStats()?.startTime | date: 'dd.MM.yyyy HH:mm'}}</span></h4>
    <h4>Löndun endar: <span>{{landingStats()?.endTime | date: 'dd.MM.yyyy HH:mm'}}</span></h4>
    <h4><span>Samþykkisnúmer: H445</span></h4>
    <h4 *ngIf="!displayPDFContent">TVN númer:
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <button class="input-group-text" id="agent" (click)="updateTVNNumber()">Uppfæra</button>
        </div>
        <input type="number" placeholder="TVN númer" class="form-control" aria-label="Default"
          aria-describedby="inputGroup-sizing-default" [(ngModel)]="tvnNumber">
      </div>
    </h4>
    <h4 *ngIf="displayPDFContent">TVN númer: <span>{{(currentLandingSum$ | async)?.tvn_Number}}</span></h4>
  </div>
  <div class="flex-50">
    <h3>Hitastig</h3>
    <h4>Hæsta hitastig: <span>{{landingStats()?.maxHeat | number: '1.1-1'}} °C</span></h4>
    <h4>Lægsta hitastig: <span>{{landingStats()?.minHeat | number: '1.1-1'}} °C</span></h4>
    <h4>Meðalhiti vigt 1: <span>{{landingStats()?.averageHeatVigt1 | number: '1.1-1'}} °C</span></h4>
    <!-- <h4>Meðalhiti vigt 2: <span>{{landingStats()?.averageHeatVigt2 | number: '1.1-1' }} °C</span></h4> -->
  </div>
  <div class="underline flex-100"></div>
  <div class="flex-100">
    <h3>Farmur</h3>
    <table class="table">
      <thead>
        <th>Veiðiferð<i class="fa fa-solid fa-pencil" (click)="navCodeEditOnToggle()"></i></th>
        <th>Skip</th>
        <th>Skipanúmer</th>
        <th>Veiðarfæri<i class="fa fa-solid fa-pencil" (click)="veidarfaeriEditOnToggle()"></i></th>
        <th>Veiðisvæði</th>
        <th>Fisktegund<i class="fa fa-solid fa-pencil" (click)="fisktegundEditOnToggle()"></i></th>
        <th>Heildarmagn</th>
      </thead>
      <tbody>
        <tr>
          <td style="width: 12.5%" *ngIf="navCodeEditOn">
            <div class="row">
              <input style="width: 80%" pInputText [(ngModel)]="navCode">
              <button style="width: 20%" pButton icon="pi pi-check" (click)="updateNavCode()" pTooltip="Staðfesta"></button>
            </div>
          </td>
          <td style="width: 14.28%" *ngIf="!navCodeEditOn">{{(currentLandingSum$ | async)?.navCode}}</td>
          <td>{{(currentMasterShip$ | async)?.name}}</td>
          <td>{{(currentMasterShip$ | async)?.shipNumber}}</td>
          <td style="width: 14.28%" *ngIf="veidarfaeriEditOn">
            <div class="row">
              <select style="width: 80%" [(ngModel)]="veidarfaeri">
                <option *ngFor="let item of veidarfaeriOptions" [value]="item">{{item}}</option>
              </select>
              <button style="width: 20%" pButton icon="pi pi-check" (click)="updateVeidarfaeri()" pTooltip="Staðfesta"></button>
            </div>
          </td>
          <td style="width: 14.28%" *ngIf="!veidarfaeriEditOn">{{(currentLandingSum$ | async)?.veidarfaeri}}</td>
          <td>{{(currentLandingSum$ | async)?.veidisvaedi}}</td>
          <td *ngIf="!fisktegundEditOn">{{(currentLandingSum$ | async)?.fishSpeciesName}}</td>
          <td style="width: 14.28%" *ngIf="fisktegundEditOn">
            <div class="row">
              <select style="width: 80%" [(ngModel)]="fisktegund">
                <option *ngFor="let item of fisktegundOptions" [value]="item.id">{{item?.name}}</option>
              </select>
              <button style="width: 20%" pButton icon="pi pi-check" (click)="updateFisktegund()" pTooltip="Staðfesta"></button>
            </div>
          </td>
          <td>{{(currentLandingSum$ | async)?.heildarmagn | number: '1.1-1' }}kg</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="page-break"></div>
  <ng-container *ngIf="!displayPDFContent"
    [ngTemplateOutlet]="fullWidthGraph"
  ></ng-container>
  <div *ngIf="displayPDFContent">
    <div class="flex-100" style="margin-bottom: 10px;">
      <h4 style="margin-top: 10px; margin-left: 20px;">Hitastig</h4>

      <input pInputNumber>
      <div style="width: 777px;">
        <p-chart type="line" [data]="heat1GraphData()" [options]="heatOptions" ></p-chart>
      </div>
    </div>
    <div class="page-break"></div>
    <div class="flex-100">
      <h4 style="margin-left: 10px">Vigt 1: þyngd</h4>
      <div style="width: 777px;">
        <p-chart type="line" [data]="vigt1HourlyWeightGraphData()" [options]="options" ></p-chart>
      </div>
    </div>
    <div class="flex-100">
      <h4 style="margin-top: 20px; margin-left: 20px;">Vigt 2: þyngd</h4>
      <div style="width: 777px;">
        <p-chart type="line" [data]="vigt2HourlyWeightGraphData()" [options]="options" ></p-chart>
      </div>
    </div>
  </div>
</div>

<ng-template #fullWidthGraph let-graphData="graphData" let-currentGraph="currentGraph" >
  <div class="flex-100">
    <div style="width: 100%;">
      <h4 style="margin-left: 10px">Bein löndun: Hitastig</h4>
      <div *ngIf="heatGraphEditOnSignal()">
        <p-inputNumber id="heatFront" [(ngModel)]="heatGraphOffset.front" locale="de-DE" [maxFractionDigits]="0"></p-inputNumber>
        <p-inputNumber id="heatBack" [(ngModel)]="heatGraphOffset.back" locale="de-DE" [maxFractionDigits]="0"></p-inputNumber>
        <ng-container
          [ngTemplateOutlet]="editButton"
          [ngTemplateOutletContext]="{isActive: heatGraphEditOnSignal(), graphId: heatGraphOffsetEnum}"
        ></ng-container>
      </div>
      <div *ngIf="!heatGraphEditOnSignal()">
        <p-inputNumber id="heatFront" [(ngModel)]="heatGraphOffsetSignal().front" locale="de-DE" [disabled]="true" [maxFractionDigits]="0"></p-inputNumber>
        <p-inputNumber id="heatBack" [(ngModel)]="heatGraphOffsetSignal().back" locale="de-DE" [disabled]="true" [maxFractionDigits]="0"></p-inputNumber>
        <ng-container
          [ngTemplateOutlet]="editButton"
          [ngTemplateOutletContext]="{isActive: heatGraphEditOnSignal(), graphId: heatGraphOffsetEnum}"
        ></ng-container>
      </div>
      <ng-container
        [ngTemplateOutlet]="singleGraph"
        [ngTemplateOutletContext]="{graphData: heat1GraphData(), currentGraph: heat1Enum, options: heatOptions}"
      ></ng-container>
    </div>
  </div>
  <div class="page-break"></div>
  <div class="flex-100">
    <div style="width: 100%;">
      <h4 style="margin-left: 10px">Vigt 1: Þyngd</h4>
      <div *ngIf="weightGraphEditOnSignal()">
        <p-inputNumber id="heatFront" [(ngModel)]="weightGraphOffset.front" locale="de-DE" [maxFractionDigits]="0"></p-inputNumber>
        <p-inputNumber id="heatBack" [(ngModel)]="weightGraphOffset.back" locale="de-DE" [maxFractionDigits]="0"></p-inputNumber>
        <ng-container
          [ngTemplateOutlet]="editButton"
          [ngTemplateOutletContext]="{isActive: weightGraphEditOnSignal(), graphId: weight1GraphOffsetEnum}"
        ></ng-container>
      </div>
      <div *ngIf="!weightGraphEditOnSignal()">
        <p-inputNumber id="heatFront" [(ngModel)]="weightGraphOffsetSignal().front" locale="de-DE" [disabled]="true" [maxFractionDigits]="0"></p-inputNumber>
        <p-inputNumber id="heatBack" [(ngModel)]="weightGraphOffsetSignal().back" locale="de-DE" [disabled]="true" [maxFractionDigits]="0"></p-inputNumber>
        <ng-container
          [ngTemplateOutlet]="editButton"
          [ngTemplateOutletContext]="{isActive: weightGraphEditOnSignal(), graphId: weight1GraphOffsetEnum}"
        ></ng-container>
      </div>
      <ng-container
        [ngTemplateOutlet]="singleGraph"
        [ngTemplateOutletContext]="{graphData: vigt1HourlyWeightGraphData(), currentGraph: hourlyWeight1Enum, options: options}"
      ></ng-container>
    </div>
  </div>
  <div class="page-break"></div>
  <div class="flex-100">
    <div style="width: 100%;">
      <h4 style="margin-left: 10px">Vigt 2: Þyngd</h4>
      <div *ngIf="weightGraph2EditOnSignal()">
        <p-inputNumber id="heatFront" [(ngModel)]="weightGraph2Offset.front" locale="de-DE" [maxFractionDigits]="0"></p-inputNumber>
        <p-inputNumber id="heatBack" [(ngModel)]="weightGraph2Offset.back" locale="de-DE" [maxFractionDigits]="0"></p-inputNumber>
        <ng-container
          [ngTemplateOutlet]="editButton"
          [ngTemplateOutletContext]="{isActive: weightGraph2EditOnSignal(), graphId: weight2GraphOffsetEnum}"
        ></ng-container>
      </div>
      <div *ngIf="!weightGraph2EditOnSignal()">
        <p-inputNumber id="heatFront" [(ngModel)]="weightGraph2OffsetSignal().front" locale="de-DE" [disabled]="true" [maxFractionDigits]="0"></p-inputNumber>
        <p-inputNumber id="heatBack" [(ngModel)]="weightGraph2OffsetSignal().back" locale="de-DE" [disabled]="true" [maxFractionDigits]="0"></p-inputNumber>
        <ng-container
          [ngTemplateOutlet]="editButton"
          [ngTemplateOutletContext]="{isActive: weightGraph2EditOnSignal(), graphId: weight2GraphOffsetEnum}"
        ></ng-container>
      </div>
      <ng-container
        [ngTemplateOutlet]="singleGraph"
        [ngTemplateOutletContext]="{graphData: vigt2HourlyWeightGraphData(), currentGraph: hourlyWeight2Enum, options: options}"
      ></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #pdfWidthGraph let-graphData="graphData" let-currentGraph="currentGraph" >
  <div class="flex-100">
    <div style="width: 777px;">
      <ng-container
        [ngTemplateOutlet]="pdfGraph"
        [ngTemplateOutletContext]="{graphData: heat1GraphData(), currentGraph: heat1Enum, options: heatOptions}"
      ></ng-container>
    </div>
  </div>
  <div class="page-break"></div>
  <div class="flex-100">
    <div style="width: 777px;">
      <ng-container
        [ngTemplateOutlet]="pdfGraph"
        [ngTemplateOutletContext]="{graphData: vigt1HourlyWeightGraphData(), currentGraph: hourlyWeight1Enum, options: options}"
      ></ng-container>
    </div>
  </div>
  <div class="page-break"></div>
  <div class="flex-100">
    <div style="width: 777px;">
      <ng-container
        [ngTemplateOutlet]="pdfGraph"
        [ngTemplateOutletContext]="{graphData: vigt2HourlyWeightGraphData(), currentGraph: hourlyWeight2Enum, options: options}"
      ></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #singleGraph let-graphData="graphData" let-currentGraph="currentGraph" let-options="options">
    <p-chart type="line" [data]="graphData" [options]="options" ></p-chart>
</ng-template>

<ng-template #editButton let-isActive="isActive" let-graphId="graphId">
    <button *ngIf="!isActive" pButton (click)="graphEditToggle(graphId)" label="Breyta"></button>
    <button *ngIf="isActive" pButton (click)="updateGraphOffset(graphId)" label="Stilla"></button>
</ng-template>
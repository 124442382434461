import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStoreService } from 'src/app/services/auth-store.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { DataOperation } from 'src/app/shared/enums';
import { AgentShip, Ship, User } from 'src/app/shared/models';
import { AgentShipOperationsRequest, ShipOperationsRequest, ShipOperationsResult } from 'src/app/shared/request-models';
import { AddCustomerToShipComponent } from './add-customer-to-ship/add-customer-to-ship.component';

@Component({
  selector: 'app-ships',
  templateUrl: './ships.component.html',
  styleUrls: ['./ships.component.css']
})
export class ShipsComponent implements OnInit {
  currentUser$: Observable<User> = this._auth.currentUser$;

  isLoadingItems: boolean = false;

  allShips: Ship[] = [];
  items: Ship[] = [];

  filteredItems: Ship[] = [];

  showActive: string = "unassigned";
  constructor(
    private _auth: AuthStoreService,
    private _dataService: DataService,
    private _errorHandler: ErrorHandlerService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.getItems();
  }

  getItems(){
    let request: ShipOperationsRequest = new ShipOperationsRequest();
    request.action = DataOperation.ListByAgencies;
    request.item = {} as Ship;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this.isLoadingItems = true;
    this._dataService.shipOperations(request).subscribe((data: ShipOperationsResult) => {

      if (data.message.ok === true) {
        this.allShips = data.items;
        this.items = this.allShips;
        this.onFilterStringChange();
        console.log('items', this.items);
      }
      else {
        console.error("message", data.message);
      }

      this.isLoadingItems = false;
    }, (err) => this.ifError(err));
  }

  // assignUsers(item: Ship){
  //   this._router.navigate(["/ships/nyttumbod/" + item.id]);
  // }

  toggleActive(item: Ship, action: string){
    let request: ShipOperationsRequest = new ShipOperationsRequest;
    request.item = {} as Ship;
    switch(action.toLowerCase()){
      case "show":
        request.action = DataOperation.SetActive;
        request.item.id = item.id;
        request.item.active = true;
        if (this._auth.currentUser) {
          request.key = this._auth.currentUser.id;
        }
        this._dataService.shipOperations(request).subscribe((data) => {
          if (data.message.ok === true) {
            this.getItems();
          }
          else {
            console.error("message", data.message);
          }
        }, (err) => this.ifError(err));
        break;
      case "hide":
        request.action = DataOperation.SetActive;
        request.item.id = item.id;
        request.item.active = false;
        if (this._auth.currentUser) {
          request.key = this._auth.currentUser.id;
        }
        this._dataService.shipOperations(request).subscribe((data) => {
          if (data.message.ok === true) {
            this.getItems();
          }
          else {
            console.error("message", data.message);
          }
        }, (err) => this.ifError(err));
        break;
      default:
        break;
    }
  }

  onFilterStringChange(){
    let filteredItems = this.allShips;
    switch(this.showActive.toLowerCase()){
      case "all":
        break;
      case "assigned":
        filteredItems = filteredItems.filter(item =>  item?.masterShipId?.length > 0);
        break;
      case "unassigned":
        filteredItems = filteredItems.filter(item => item?.masterShipId?.length == 0);
        break;
    }
    this.filteredItems = filteredItems;
  }

  updateCustomer(item: Ship){
    // const refUser = this.dialogItem.open(AddCustomerToShipComponent, {
    //   data: {
    //     selectedRow: item,
    //   },
    //   width: '800px'
    // });
    // refUser.afterClosed().subscribe((result) => {
    //   console.log('Dialog result', result);
    //   if (result.status != "cancelled") {
    //     this.getItems();
    //   }
    // });
  }

  ifError(err: any) {
    //not implemented
    console.log(err);

    this.isLoadingItems = false;

    return this._errorHandler.handleError(err);
  };

}

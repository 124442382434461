<div>
  <div class="top-section">
    <h2 class="page-title">Heimildir</h2>
  </div>
  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text" id="agent">Sía notendur</span>
    </div>
    <input type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"
      [(ngModel)]="filterString" (ngModelChange)="onFilterStringChange()">
  </div>
  <div class="col-md-12">
    <table class="table">
      <thead>
        <th>Nafn notanda</th>
      </thead>
      <tbody>
        <tr [ngClass]="row.id === (selectedUserForAgency$ | async)?.id ? 'expandable-table' : ''" *ngFor="let row of (filteredUsersForAgency$ | async)" (click)="toggleRow(row)">
          <td>
            {{ row.name }}
            <br>
            <table class="table" *ngIf="row === (selectedUserForAgency$ | async)">
              <thead>
                <th>Nafn skips</th>     
                <th>Aðgerðir</th>
              </thead>
              <tbody *ngIf="(filteredAgentMasterShipsForAgency$ | async)?.length > 0">
                <tr *ngFor="let item of (filteredAgentMasterShipsForAgency$ | async)">
                    <td>{{item.masterShipName}}</td>
                    <td>
                      <i class="fas fa-trash-alt fa-action-button" pTooltip="Fjarlægja {{item.agentName}} af {{item.masterShipName}}" (click)="deleteAgency(item)"></i>
                    </td>
                </tr>
              </tbody>
              <tbody *ngIf="(filteredAgentMasterShipsForAgency$ | async)?.length == 0">
                <tr >
                    <td>{{(selectedUserForAgency$ | async)?.name}} hefur engin umboð</td>
                    <td></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, shareReplay, tap } from 'rxjs';
import { CurrentUser } from '../shared/constants';
import { Email, User } from '../shared/models';
import { EmailOperationsRequest, LoginRequest, UserOperationsRequest } from '../shared/request-models';
import { ErrorHandlerService } from './error-handler.service';
import { environment } from 'src/environments/environment';
import { Base64 } from 'js-base64';
import { DataOperation } from '../shared/enums';
import { DataService } from './data.service';


@Injectable({
  providedIn: 'root'
})
export class AuthStoreService {

  private apiUrlLogin: string = environment.apiServer + '/api/Login';


  private _userSubject = new BehaviorSubject<User>(null);
  private _currentUser$: Observable<User> = this._userSubject.asObservable();
  get currentUser$() { return this._currentUser$ }
  get currentUser() { return this._userSubject.getValue() }
  set currentUser(user: User) {
    localStorage.setItem(CurrentUser, btoa(JSON.stringify(user)));
    this._userSubject.next(user);
  }

  constructor(
    private _http: HttpClient, 
    private _router: Router, 
    private _dataService: DataService,
    private _errorHandler: ErrorHandlerService) {
    this.initService();
  }

  initService() {
    if (localStorage.getItem(CurrentUser) != undefined) {
      var currentUser = JSON.parse(Base64.decode(localStorage.getItem(CurrentUser)));
    }

    if (currentUser) {
      this._userSubject.next(currentUser);
    }
  }

  public login<LoginResult>(_email: string, _password: string): Observable<LoginResult> {

    let request: LoginRequest =
    {
      email: _email,
      password: _password
    };

    return this._http.post<LoginResult>(this.apiUrlLogin, request)
      .pipe(
        tap(
          (result: any) => { this.setCurrentUser(result.user as User); },
          (err) => this._errorHandler.handleError(err)
          //catchError((err) => this._errorHandler.handleError(err))
          //this._loadingService.LoadingOff(),
        ),
        shareReplay());
  }

  setCurrentUser(user: User) {
    localStorage.setItem(CurrentUser, Base64.encode(JSON.stringify(user)));
    this._userSubject.next(user);
  }

  getCurrentUser<User>() {
    if (localStorage.getItem(CurrentUser) != undefined) {
      this._userSubject.next(JSON.parse(Base64.decode(localStorage.getItem(CurrentUser))));
    }
    return this.currentUser;
  }

  public logout() {
    this._userSubject.next(null);
    localStorage.clear()
    sessionStorage.clear()
    this._router.navigateByUrl('/Login');
  }

  sendUpdatePasswordEmail(item: Email){
    this.emailOperations(DataOperation.ChangePasswordLoggedOut, item);
  }

  emailOperations(action: DataOperation, item: Email){
    let request = {} as EmailOperationsRequest;
    request.action = action;
    request.item = item;

    this._dataService.emailOperations(request).subscribe({
      next: (result) => {
        console.log(result);
        if(result.message.ok){
          if(action === DataOperation.ChangePasswordLoggedOut){
            console.log("email sent");
          }
          console.log(result.items);
        }else{
          console.log(result.message.name);
          console.log("Email failed to send");
        }

      },
      error: (err) => this.ifError(err)
    }
    );
  }

  changePassword(item: User, accessToken: string){
    // this.userOperations(DataOperation.ChangePasswordLoggedOut, item, accessToken);
  }

  userOperations(action: DataOperation, item: User, accessToken: string = ""){
    let request = {} as UserOperationsRequest;
    request.action = action;
    request.item = item;
    request.accessToken = accessToken;

    this._dataService.userOperations(request).subscribe({
      next: (result) => {
        console.log(result);
        if(result.message.ok){
          if(action === DataOperation.ResetPasswordSave){
            // this.toasterSuccess('Lykilorði breytt', PasswordChangedSuccesfullyPrefixMessage + ' færi þig á innskráningarsíðu');
            setTimeout(() => {
              this._router.navigate(['/auth/login']);
            }, 2000);
            console.log("Password changed successfully");
          }
          console.log(result.items);
        }else{
          if(action === DataOperation.ResetPasswordSave){
            // this.toasterError('Villa', PasswordChangedFailedPrefixMessage + ': ' + result.message.name);
          }
          console.log(result.message.name);
          console.log("Email failed to send");
        }

      },
      error: (err) => this.ifError(err)
    }
    );
  }

  ifError(err: any) {
    //not implemented
    console.log(err);

    return this._errorHandler.handleError(err);
  };
}

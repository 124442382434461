import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthStoreService } from 'src/app/services/auth-store.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { DataOperation, DialogActionEnum } from 'src/app/shared/enums';
import { User } from 'src/app/shared/models';
import { UserOperationsRequest } from 'src/app/shared/request-models';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  currentUser$: Observable<User> = this._auth.currentUser$;

  allUsers: User[];
  items: User[];
  isLoadingItems: boolean = false;
  filterString: string = '';
  displayedColumns: string[] = [];
  showActive: string = "active";
  filteredItems: User[] = [];
  selectedView: string = "all";

  //Dialog
  ref: DynamicDialogRef | undefined;

  insertAction: DialogActionEnum = DialogActionEnum.Insert;
  updateAction: DialogActionEnum = DialogActionEnum.Update;
  deleteAction: DialogActionEnum = DialogActionEnum.Delete;
  viewAction: DialogActionEnum = DialogActionEnum.View;
  setPasswordAction: DialogActionEnum = DialogActionEnum.SetPassword;

  showActiveOptions: any[] = [
    { name: 'Allir', value: "all" },
    { name: 'Aðeins virkir', value: "active" },
    { name: 'Aðeins óvirkir', value: "inactive" }
];

  selectedViewOptions: any[] = [
    { name: 'Allir', value: "all" },
    { name: 'Umsjón', value: "admin" },
    { name: 'Almennir', value: "regular" }
  ];


  constructor(
    private _auth: AuthStoreService,
    private _dataService: DataService,
    private _errorHandler: ErrorHandlerService,
    public dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.getItems();
  }

  getItems(){
    let request: UserOperationsRequest = new UserOperationsRequest();
    request.action = DataOperation.List;
    request.item = {} as User;

    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }

    this._dataService.userOperations(request).subscribe((data) => {

      if (data.message.ok === true) {
        this.allUsers = data.items;
        this.items = this.allUsers;
        this.onFilter();
      }
      else {
        console.error("message", data.message);
      }

      this.isLoadingItems = false;
    }, (err) => this.ifError(err));

  }

  onFilter(){
    let filteredItems = this.allUsers;
    filteredItems = this.activationFilter(filteredItems, this.showActive);
    filteredItems = this.userRoleFilter(filteredItems, this.selectedView);
    this.filteredItems = filteredItems;
  }

  activationFilter(users: User[],showActive: string){
    switch(showActive.toLowerCase()){
      case "all":
        return users;
      case "active":
        return users.filter(item => item.active == true);
      case "inactive":
        return users.filter(item => item.active == false);
      default:
        return users;
    }
  }

  userRoleFilter(users: User[], selectedView: string){
    switch(selectedView.toLowerCase()){
      case "all":
        return users;
      case "admin":
        return users.filter(item => item.admin == true);
      case "regular":
        return users.filter(item => item.admin == false);
      default:
        return users;
    }
  }

  showErrorMessage(control: AbstractControl) {
    if (control) {
      if (control.invalid && (control.dirty || control.touched)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  translateBoolean(isAdmin: boolean){
    if(isAdmin){
      return "Já"
    }else{
      return "Nei"
    }
  }

  actionItem(item: User, action: DialogActionEnum){
    console.log(item, action);
    this.ref = this.dialogService.open(UserDialogComponent, {
      width: '762px',
      header: this.getHeaderText(action, item?.name),
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: { action: action, editRow: item }
    });

    this.ref.onClose.subscribe((user: User) => {
      if (user) {
        console.log("closed");
        this.getItems();
      }
    });
  }

  getHeaderText(action: DialogActionEnum, name: string){
    switch(action){
      case DialogActionEnum.Insert:
        return "Stofna notanda";
      case DialogActionEnum.Update:
        return "Uppfæra " + name;
      case DialogActionEnum.Delete:
        return "Eyða " + name;
      case DialogActionEnum.View:
        return "Skoða " + name;
      case DialogActionEnum.SetPassword:
        return "Stilla lykilorð hjá " + name;
      default:
        return ""
    }
  }

  toggleActive(item: User, action: string){
    console.log(item);
    let request: UserOperationsRequest = new UserOperationsRequest;
    request.item = {} as User;
    switch(action.toLowerCase()){
      case "show":
        request.action = DataOperation.SetActive;
        request.item.id = item.id;
        request.item.active = true;

        if (this._auth.currentUser) {
          request.key = this._auth.currentUser.id;
        }
        this._dataService.userOperations(request).subscribe((data) => {

          if (data.message.ok === true) {
            this.getItems();
          }
          else {
            console.error("message", data.message);
          }
        }, (err) => this.ifError(err));
        break;
      case "hide":
        request.action = DataOperation.SetActive;
        request.item.id = item.id;
        request.item.active = false;

        if (this._auth.currentUser) {
          request.key = this._auth.currentUser.id;
        }
        this._dataService.userOperations(request).subscribe((data) => {

          if (data.message.ok === true) {
            this.getItems();
          }
          else {
            console.error("message", data.message);
          }
        }, (err) => this.ifError(err));
        break;
      default:
        break;
    }
  }

  ifError(err: any) {
    //not implemented
    console.log(err);

    this.isLoadingItems = false;

    return this._errorHandler.handleError(err);
  };

}

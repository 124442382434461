import { Component, computed } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStoreService } from 'src/app/services/auth-store.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { MasterShip, User } from 'src/app/shared/models';
import { AddCustomerToShipComponent } from '../ships/add-customer-to-ship/add-customer-to-ship.component';
import { MasterShipDialogComponent } from './master-ship-dialog/master-ship-dialog.component';
import { AdminService } from '../admin.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogActionEnum } from 'src/app/shared/enums';

@Component({
  selector: 'app-ship-master',
  templateUrl: './ship-master.component.html',
  styleUrls: ['./ship-master.component.css']
})
export class ShipMasterComponent {
  currentUser$: Observable<User> = this._auth.currentUser$;

  allMasterShipsSignal = this._adminService.allMasterShipsSignal;


  showActive: string = "active";

  //Dialog
  ref: DynamicDialogRef | undefined;

  //Action to perform
  actionMode: DialogActionEnum;
  insertAction: DialogActionEnum = DialogActionEnum.Insert;
  updateAction: DialogActionEnum = DialogActionEnum.Update;
  deleteAction: DialogActionEnum = DialogActionEnum.Delete;
  viewAction: DialogActionEnum = DialogActionEnum.View;

  constructor(
    private _auth: AuthStoreService,
    private _errorHandler: ErrorHandlerService,
    private _router: Router,
    private _adminService: AdminService,
    public dialogService: DialogService,

  ) { }

  ngOnInit(): void {
    this._adminService.getAllMasterShips();
  }

  toggleActive(item: MasterShip, action: string){
    let masterShip = {} as MasterShip;
    masterShip.id = item.id;
    masterShip.name = item.name;
    switch(action.toLowerCase()){
      case "show":
        masterShip.isActive = true
        this._adminService.toggleMasterShipIsActive(masterShip);
        break;
      case "hide":
        masterShip.isActive = false;
        this._adminService.toggleMasterShipIsActive(masterShip);
        break;
      default:
        break;
    }
  }

  actionItem(item: MasterShip, action: DialogActionEnum){
    console.log(item, action);
    this.ref = this.dialogService.open(MasterShipDialogComponent, {
      width: '762px',
      header: this.getHeaderText(action, item?.name),
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: { action: action, editRow: item }
    });

    this.ref.onClose.subscribe((masterShip: MasterShip) => {
      if (masterShip) {
        console.log("closed");
        this._adminService.getAllMasterShips();
      }
    });
  }

  updateMasterShip(item: MasterShip){
    this.ref = this.dialogService.open(MasterShipDialogComponent, {
      width: '762px',
      header: this.getHeaderText(DialogActionEnum.Update, item?.name),
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: { action: DialogActionEnum.Update, editRow: item }
    });

    this.ref.onClose.subscribe((masterShip: MasterShip) => {
      if (masterShip) {
        console.log("closed");
        this._adminService.getAllMasterShips();
      }
    });
  }

  updateCustomer(item: MasterShip){
    this.ref = this.dialogService.open(AddCustomerToShipComponent, {
      width: '762px',
      header: this.getHeaderText(DialogActionEnum.Update, item?.name),
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: { editRow: item }
    });

    this.ref.onClose.subscribe((masterShip: MasterShip) => {
      if (masterShip) {
        console.log("closed");
        this._adminService.getAllMasterShips();
      }
    });
  }

  getHeaderText(action: DialogActionEnum, name: string){
    switch(action){
      case DialogActionEnum.Insert:
        return "Stofna viðskiptavin";
      case DialogActionEnum.Update:
        return "Uppfæra " + name;
      case DialogActionEnum.Delete:
        return "Eyða " + name;
      case DialogActionEnum.View:
        return "Skoða " + name;
      case DialogActionEnum.SetPassword:
        return "Stilla lykilorð hjá " + name;
      default:
        return ""
    }
  }

  routeToNewAgency(item: MasterShip){
    this._router.navigate['skipaskraning/nyttumbod/' + item.id];
  }

  ifError(err: any) {
    return this._errorHandler.handleError(err);
  };

}

export function SsnValidator(controlValue: string, emptyAllowed: boolean) {
    let regex: RegExp = new RegExp(/^\d+$/);
    let isValid: boolean = true;
    //let controlValue: string = control.value as string;
    if (!controlValue && emptyAllowed) { return true; }
    if (controlValue.trim().length < 1 && emptyAllowed) { return true; }
    if (controlValue && controlValue.length == 11 && controlValue[6] != "-") { return false; }
    if (controlValue[6] == '-') { controlValue = controlValue.substr(0, 6) + controlValue.substr(7, 4); }

    isValid = regex.test(controlValue);

    // console.log(controlValue);
    if (controlValue.trim().length != 10) {
        return false;
    }

    if (Number(controlValue)) {
        let d1: number = Number(controlValue[0]);
        let d2: number = Number(controlValue[1]);
        let d3: number = Number(controlValue[2]);
        let d4: number = Number(controlValue[3]);
        let d5: number = Number(controlValue[4]);
        let d6: number = Number(controlValue[5]);
        let d7: number = Number(controlValue[6]);
        let d8: number = Number(controlValue[7]);
        let d9: number = Number(controlValue[8]);
        let d10: number = Number(controlValue[9]);

        //Hvernig verða síðustu fjórir tölustafirnir í íslensku kennitölunni til?
        // https://www.visindavefur.is/svar.php?id=183

        let checkSum = (d1 * 3) + (d2 * 2) + (d3 * 7) + (d4 * 6) + (d5 * 5) + (d6 * 4) + (d7 * 3) + (d8 * 2);
        let modulo = checkSum % 11;
        let comparisonValue = 11 - modulo;

        if (modulo == 0) { comparisonValue = 0; }
        if (comparisonValue != d9) { return false }
        if (d10 > 0 && d10 < 9) { return false }
    }

    return isValid ? true : false;
}

export function EmailStringValidator(input: string, onlyAllowOneEmailAddress: boolean, emptyAllowed?: boolean) {
    let isValid: boolean = true;
    input = input?.trim();

    if (!input && emptyAllowed) {
        return false;
    }

    if (!input && !emptyAllowed) {
        return false;
    }

    //input.endsWith(';') ? input = input.substr(0, input.length - 1) : null;
    input.endsWith(';') ? input = input.substring(0, input.length - 2) : null;

    // console.warn(input);
    let emails: string[];
    if (onlyAllowOneEmailAddress) {
        emails = [input];
    } else {
        emails = input.split(';');
        emails = emails.map(str => str.trim());
    }

    if (onlyAllowOneEmailAddress && input.includes(";")) {
        return false;
    }

    let regex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //console.log(emails);

    emails.forEach(singleEmail => {
        if (singleEmail.indexOf(',') > 0) {
            isValid = false;
        } else if (regex.test(singleEmail)) {
            //do nothing
        } else {
            isValid = false;;
        }
    })

    return isValid ? true : false;
}
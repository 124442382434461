import { DataOperation } from "./enums";
import { AgentMasterShip, AgentShip, Customer, Email, FishSpecies, GraphOffset, Landing, LandingGraph, LandingStats, LandingSum, MasterShip, Message, ProcessingRecord, ProcessingRecordStats, Ship, User, VinnsluLota } from "./models";

export class AgentShipOperationsRequest {
  action: DataOperation;
  item: AgentShip;
  key: string;
  // multi: boolean;
  // items: AgentShip[];
}

export class AgentShipOperationsResult {
  items: AgentShip[];
  message: Message;
}

export class AgentMasterShipOperationsRequest {
  action: DataOperation;
  item: AgentMasterShip;
  key: string;
  // multi: boolean;
  // items: AgentShip[];
}

export class AgentMasterShipOperationsResult {
  items: AgentMasterShip[];
  message: Message;
}

export class ShipOperationsRequest {
  action: DataOperation;
  item: Ship;
  key: string;
}

export class ShipOperationsResult {
  items: Ship[];
  message: Message;
}

export class MasterShipOperationsRequest {
  action: DataOperation;
  item: MasterShip;
  key: string;
}

export class MasterShipOperationsResult {
  items: MasterShip[];
  message: Message;
}

export class UserOperationsRequest {
  action: DataOperation;
  item: User;
  key: string;
  accessToken?: string;
}

export class UserOperationsResult {
  items: User[];
  message: Message;
}

export class LoginResult {
  message: Message;
  user: User
}

export class LoginRequest {
  email: string;
  password: string;
}

export class LandingSumOperationsRequest {
  action: DataOperation;
  item: LandingSum;
  key: string;
}

export class LandingSumOperationsResult {
  items: LandingSum[];
  message: Message;
}

export class LandingOperationsRequest {
  action: DataOperation;
  item: Landing;
  key: string;
}

export class LandingOperationsResult {
  items: Landing[];
  heatGraph: LandingGraph[];
  weightGraph: LandingGraph[];
  weightGraph2: LandingGraph[];
  heatGraphOffset: GraphOffset;
  weightGraphOffset: GraphOffset;
  weightGraph2Offset: GraphOffset;
  message: Message;
  stats: LandingStats;
}

export class LandingGraphOperationsRequest {
  action: DataOperation;
  item: LandingGraph;
  key: string;
}

export class LandingGraphOperationsResult {
  items: LandingGraph[];
  message: Message;
}

export class LandingStatsOperationsRequest {
  action: DataOperation;
  item: LandingStats;
  key: string;
}

export class LandingStatsOperationsResult {
  items: LandingStats[];
  message: Message;
}


export class CustomerOperationsRequest {
  action: DataOperation;
  item: Customer;
  key: string;
}

export class CustomerOperationsResult {
  items: Customer[];
  message: Message;
}

export class ProcessingRecordOperationsRequest {
  action: DataOperation;
  item: ProcessingRecord;
  key: string;
}

export class ProcessingRecordOperationsResult {
  items: ProcessingRecord[];
  message: Message;
}

export class ProcessingRecordStatsOperationsRequest {
  action: DataOperation;
  item: ProcessingRecordStats;
  key: string;
}

export class ProcessingRecordStatsOperationsResult {
  items: ProcessingRecordStats[];
  message: Message;
}

export class VinnsluLotaOperationsRequest {
  action: DataOperation;
  item: VinnsluLota;
  key: string;
}

export class VinnsluLotaOperationsResult {
  items: VinnsluLota[];
  message: Message;
}

export interface EmailOperationsRequest{
  action: DataOperation;
  item: Email;
}

export interface EmailOperationsResult{
  items: Email[];
  message: Message;
}

export interface FishSpeciesOperationsRequest{
  action: DataOperation;
  item: FishSpecies;
  key: string;
}

export interface FishSpeciesOperationsResult{
  items: FishSpecies[];
  message: Message;
}

export interface GraphOffsetOperationsRequest{
  action: DataOperation;
  item: GraphOffset;
  key: string;
}

export interface GraphOffsetOperationsResult{
  items: GraphOffset[];
  message: Message;
}
import { Component, OnInit } from '@angular/core';
import { AgentMasterShip, User } from 'src/app/shared/models';
import { AdminService } from '../admin.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.css']
})
export class AgentComponent implements OnInit {
  showFilters: boolean = false;
  filterVisibilityButtonText: string = "Sýna Síur";

  filteredAgentMasterShipsForAgency$: Observable<AgentMasterShip[]> = this._adminService.filteredAgentMasterShipsForAgency$;
  
  filteredUsersForAgency$: Observable<User[]> = this._adminService.filteredUsersForAgency$;
  selectedUserForAgency$: Observable<User> = this._adminService.selectedUserForAgency$;

  filterString: string = "";
  
  constructor(
    private _adminService: AdminService,

  ) { }

  ngOnInit(): void {
    this._adminService.getAllAgentMasterShips();

    this._adminService.allAgentMasterShips$.subscribe(items => {
      if(items){
        this._adminService.getAllUsers();
      }
    });
  }

  onFilterStringChange(){
    this._adminService.usersFilterStringForAgency = this.filterString;
  }

  toggleFilterVisibility(){
    this.showFilters = !this.showFilters;
    if(this.showFilters){
      this.filterVisibilityButtonText = "Fela síur";
    }else{
      this.filterVisibilityButtonText = "Sýna síur";
    }
  }

  deleteAgency(item: AgentMasterShip){   
    this._adminService.deleteAgency(item);
  }


  toggleRow(item: User): void {
    this._adminService.setSelectedUserForAgency(item);
  }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataOperation } from '../shared/enums';
import { AgentShip, User } from '../shared/models';
import { AgentShipOperationsRequest, AgentShipOperationsResult } from '../shared/request-models';
import { AuthStoreService } from './auth-store.service';
import { DataService } from './data.service';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class PropertiesStoreService {
  currentUser: User;

  private _agentShipsSource: BehaviorSubject<AgentShip[]> = new BehaviorSubject<AgentShip[]>(null);
  private _agentShips$: Observable<AgentShip[]> = this._agentShipsSource.asObservable();
  get agentShips() { return this._agentShipsSource.getValue(); }
  get agentShips$() { return this._agentShips$; }
  set agentShips(value: AgentShip[]) { this._agentShipsSource.next(value); }


  constructor(
    private _authStore: AuthStoreService, 
    private _dataService: DataService, 
    private _router: Router, 
    private _errorHandler: ErrorHandlerService) {
    console.log("PropStore Initialized");

    this._authStore.currentUser$.subscribe(value => this.currentUser = value);
  }

  getAgentShipsWithRouting(withMe: boolean){
    let request: AgentShipOperationsRequest = new AgentShipOperationsRequest;
    request.action = DataOperation.ListByAgent;
    request.item = {} as AgentShip;
    request.item.agentId = this._authStore.currentUser.id;
    // request.withMe = withMe;
    if(this._authStore.currentUser){
      request.key = this._authStore.currentUser.id;
    }
    this._dataService.agentShipOperations(request).subscribe((data: AgentShipOperationsResult) => {
      //console.log('data',data);
      if(data.message.ok && data.items?.length>0)
      {
        this.agentShips = data.items;

        if(this.agentShips.length>0)
        {
          // console.log("Has AgentShips")
          // this._router.navigate(['/minarsidur/vorur']);
        }
        else{
          this._router.navigate(['/']);
        }
        console.log('AgentShips', this.agentShips);
      }
      //this.getRelatedItems();
    }, (err) => this.ifError(err));
  }

  ifError(error: any) {
    console.log(error);
    return this._errorHandler.handleError(error);
  };
}

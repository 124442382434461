<div class="row">

    <!-- <div class="row login-form-holder">
          <div class="login-form-container" *ngIf="(shopConfiguration$ | async)?.authorizationModeId != 0">
            <h4 class="login-title">Skrá inn með Íslykli</h4>
            <div (click)="gotoIsland()" class="island-holder island-hover">
              <img src="/images/island-logo.svg" class="island-image img-fluid" />
            </div>
            <div class="form-group login-button-holder island-hover">
              <button (click)="gotoIsland()" class="btn btn-primary mb-2 login-button">Skrá mig inn</button>
            </div>

          </div> -->
    <div class="login-form-container">
        <h4 class="login-title">Innskráning</h4>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="email" class="login-label">Netfang</label>
                <input type="email" class="form-control login" id="email" formControlName="email" autocomplete="off"
                    placeholder="Netfang">
            </div>
            <div class="form-group">
                <label for="password" class="login-label">Lykilorð</label>
                <input type="password" class="form-control login" id="password" formControlName="password"
                    autocomplete="off" placeholder="Lykilorð">
            </div>
            <div class="form-group  error-message-holder">
                <span class="login-error" *ngIf="loginError">{{ loginErrorMessage }}</span>
            </div>
            <div class="form-group login-button-holder">
                <div class="row">
                    <div class="col-md-12">
                        <button type="submit" class="btn btn-primary mb-2 login-button">Skrá mig inn</button>
                    </div>
                    <!-- <div class="col-md-6">
                        <button type="button" class="btn btn-primary mb-2 lost-password-button" (click)="lostPassword()">
                            Týnt lykilorð
                        </button>
                    </div> -->
                </div>
                <!-- <button type="button" class="btn btn-primary mb-2 lost-password-button" (click)="lostPassword()">
                    Týnt lykilorð
                </button> -->
            </div>

        </form>
    </div>


</div>
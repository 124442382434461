<div>
    <div class="top-section">
      <h2 class="page-title">Skipaskráning</h2>
      <button type="button" class="btn btn-primary" (click)="actionItem(null, insertAction)">Bæta við skipaskrá</button>

    </div>
  
  <div *ngIf="allMasterShipsSignal()?.length > 0">
    <table class="table">
        <thead>
          <th>Nafn</th>
          <th>Skipanúmer</th>
          <th>Viðskiptavinur</th>
          <th>Virkt</th>
          <th *ngIf="(currentUser$ | async)?.admin">Aðgerðir</th>
        </thead>
        <tbody>
          <tr *ngFor="let item of allMasterShipsSignal()">
            <td>{{item?.name}}</td>
            <td>{{item?.shipNumber}}</td>
            <td>{{item?.customerName}}</td>
            <td>
            <i *ngIf="!item?.isActive && (currentUser$ | async)?.admin" class="fas fa-eye-slash fa-red-action-button"
            (click)="toggleActive(item,'Show')" pTooltip="Virkja {{item?.name}}"></i>
            <i *ngIf="item?.isActive && (currentUser$ | async)?.admin" class="fas fa-eye fa-green-action-button"
            (click)="toggleActive(item,'Hide')" pTooltip="Afvirkja {{item?.name}}"></i>
            <i *ngIf="!item?.isActive && !(currentUser$ | async)?.admin" class="fas fa-eye-slash fa-red-action-button"
            pTooltip="{{item?.name}} er óvirkt"></i>
            <i *ngIf="item?.isActive && !(currentUser$ | async)?.admin" class="fas fa-eye fa-green-action-button"
            pTooltip="{{item?.name}} er virkt"></i>
            </td>
            <td *ngIf="(currentUser$ | async)?.admin">
                <i class="fas fa-fish fa-action-button" pTooltip="Skoða löndunartölur frá {{item?.name}}"
                [routerLinkActive]="['link-active']" [routerLink]="['summutolur/' + item?.id]"></i>
                <i  class="fas fa-user-plus fa-action-button"
                pTooltip="Bæta notanda við {{item?.name}}"
                [routerLinkActive]="['link-active']" [routerLink]="['nyttumbod/' + item?.id]"   ></i>
                <i class="fas fa-link fa-action-button" pTooltip="Tengja skip við {{item?.name}}"
                [routerLinkActive]="['link-active']" [routerLink]="[item?.id + '/tengja']"></i>
                <i class="fas fa-user-tie fa-action-button" pTooltip="Uppfæra viðskiptavin fyrir {{item?.name}}"
                (click)="updateCustomer(item)"></i>
                <i class="fas fa-pen fa-action-button" pTooltip="Uppfæra grunnupplýsingar fyrir {{item?.name}}"
                (click)="updateMasterShip(item)"></i>
                <!-- <i class="fas fa-trash-alt fa-action-button" pTooltip="Eyða {{item?.name}}"
                (click)="deleteMasterShip(item)"></i> -->
            </td>
          </tr>
        </tbody>
    </table>
  </div>
  
  <h4 *ngIf="allMasterShipsSignal()?.length === 0">
    Engar heimildir fundust
  </h4>
  
  </div>
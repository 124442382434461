<div>
  <div class="container">
    <div class="top-section">
      <h2 class="page-title">Bæta skipum við "{{currentMasterShip?.name}}"</h2>
      <button
      *ngIf="selectedShips.length > 0"
      type="button"
      class="btn btn-primary"
      (click)="actionItem(selectedShips, 'insert')"
    >
      {{ getCreateAgencyText(selectedShips.length) }}
    </button>
    </div>
    
    <div class="row">
      <div class="col">
        <div class="user-table">
          <table class="table">
            <thead>
              <th>Nafn</th>
              <th>Aðgerðir</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of filteredShips">
                <td>{{ item.name }}</td>
                <td>
                  <i
                    *ngIf="!shipSelected(item)"
                    class="fas fa-plus-circle fa-action-button"
                    pTooltip="Bæta {{ item.name }} við á {{ currentMasterShip.name }}"
                    (click)="shipAction(item, 'add')"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col">
        <div class="picked-user-table">
          <h2>Valin skip</h2>
          <table class="table">
            <thead>
              <th>Nafn</th>
              <th>Aðgerðir</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of selectedShips">
                <td>{{ item.name }}</td>
                <td>
                  <i
                    *ngIf="shipSelected(item)"
                    class="fas fa-minus-circle fa-action-button"
                    pTooltip="Hætta við að bæta {{ item.name }} við á {{
                      currentMasterShip.name
                    }}"
                    (click)="shipAction(item, 'remove')"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      
        <div class="current-user-table">
          <h2>Núverandi skip</h2>
          <table class="table">
            <thead>
              <th>Nafn</th>
              <th>Aðgerðir</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of currentShips">
                <td>{{ item.name }}</td>
                <td>
                  <i
                    class="fas fa-trash-alt fa-action-button"
                    pTooltip="Fjarlægja {{ item.name }} af {{ currentMasterShip.name }}"
                    (click)="removeShipFromMasterShip(item)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  

  

</div>




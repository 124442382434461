import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthStoreService } from 'src/app/services/auth-store.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { DataOperation } from 'src/app/shared/enums';
import { AgentShip, Ship, User } from 'src/app/shared/models';
import { AgentShipOperationsRequest, AgentShipOperationsResult, ShipOperationsRequest, ShipOperationsResult, UserOperationsRequest } from 'src/app/shared/request-models';

@Component({
  selector: 'app-add-users-to-ship',
  templateUrl: './add-users-to-ship.component.html',
  styleUrls: ['./add-users-to-ship.component.css']
})
export class AddUsersToShipComponent implements OnInit {

  slug: string = "";
  currentShip: Ship = {} as Ship;

  showActive: string = "active";
  filteredItems: User[] = [];
  allUsers: User[] = [];

  usersFetched: boolean = false;

  selectedUsers: User[] = [];

  currentAgents: AgentShip[] = [];
  currentAgentUsers: User[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _auth: AuthStoreService,
    private _dataService: DataService,
    private _errorHandler: ErrorHandlerService,
    private _toastr: ToastrService

  ) { }

  ngOnInit(): void {
    try {
      this.slug = this._route.snapshot.paramMap.get('slug');
      this.getItem();
    }
    catch (e) {
      console.error(e);
      this._router.navigate(["/"]);
    }
    console.log(this._auth.currentUser);
  }

  getItem(){
    let request: ShipOperationsRequest = new ShipOperationsRequest();
    request.item = {} as Ship;
    request.item.id = this.slug;
    request.action = DataOperation.Read;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.shipOperations(request).subscribe((data: ShipOperationsResult) => {

      if (data.message.ok === true && data.items?.length > 0) {
        this.currentShip = data.items[0];
        this.getUsers();
      }
      else {
        console.error("message", data.message);
      }
    }, (err) => this.ifError(err));
  }

  getAgencies(){
    let request: AgentShipOperationsRequest = new AgentShipOperationsRequest;
    request.item = {} as AgentShip;
    request.item.shipId = this.currentShip.id;
    request.item.id = "";
    request.action = DataOperation.ListByShip;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }

    this._dataService.agentShipOperations(request).subscribe((data:AgentShipOperationsResult) => {
      if (data.message.ok === true) {
        if(data.items?.length > 0){
          this.currentAgents = data.items;
          this.getCurrentAgentUsers(this.allUsers, this.currentAgents);
        }else{
          this.currentAgents = [];
          this.currentAgentUsers = [];
        }
      }
      else {
        console.error("message", data.message);
        this.currentAgents = [];
      }
    }, (err) => this.ifError(err));
  }

  getUsers(){
    let request: UserOperationsRequest = new UserOperationsRequest();
    request.action = DataOperation.List;
    request.item = {} as User;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.userOperations(request).subscribe((data) => {
      if (data.message.ok === true) {
        this.allUsers = data.items;
        this.usersFetched = true;
        this.onFilterStringChange();
        this.getAgencies();
      }
      else {
        console.error("message", data.message);
      }
    }, (err) => this.ifError(err));

  }

  getCreateAgencyText(size: number){
    if(size == 1){
      return "Stofna heimild fyrir " + this.selectedUsers[0]?.name + " á " + this.currentShip.name;
    }else if(size > 1){
      return "Stofna heimild fyrir " +  size.toString() + " notendur á " + this.currentShip.name;
    }
  }

  actionItem(items: User[], action: string){
    switch(action.toLowerCase()){
      case "insert":
        let multiRequest: AgentShipOperationsRequest[] = [];
        items.forEach(item => {
          let request: AgentShipOperationsRequest = new AgentShipOperationsRequest();
          request.action = DataOperation.Insert;
          if (this._auth.currentUser) {
            request.key = this._auth.currentUser.id;
          }
          request.item = {} as AgentShip;
          request.item.agentId = item.id;
          request.item.shipId = this.currentShip.id;
          request.item.id = "";
          multiRequest.push(request);
          console.log(multiRequest);
          this.multiAction(multiRequest);
        });
        break;
      default:
        break;
    }
  }

  multiAction(requests: AgentShipOperationsRequest[]){
    let index: number = 0;
    requests.forEach(request => {
      this._dataService.agentShipOperations(request).subscribe((data: AgentShipOperationsResult) => {
        index += 1;
        if (data.message.ok === true) {
          if (index === requests.length) {
            this.selectedUsers = [];
            this.getUsers();
            if(requests.length > 1){
              this._toastr.success("Bætti " + requests.length.toString() + " notendum við á " + this.currentShip.name);
            }else{
              this._toastr.success("Bætti " + data.items[0]?.agentName + " á " + this.currentShip.name);
            }
          }
        }
        else {
          console.error("message", data.message);
        }
      }, (err) => this.ifError(err));
    })
  }

  userSelected(item: User){
    return this.selectedUsers.includes(item);
  }

  userAction(item: User, action: string){
    switch(action.toLowerCase()){
      case "add":
        this.selectedUsers.push(item);
        this.onFilterStringChange();
        break;
      case "remove":
        const index = this.selectedUsers.indexOf(item, 0);
        if (index > -1) {
          this.selectedUsers.splice(index, 1);
        }
        this.onFilterStringChange();
        break;
      default:
        break;
    }
  }

  onFilterStringChange(){
    let filteredItems = this.allUsers;
    switch(this.showActive.toLowerCase()){
      case "all":
        break;
      case "active":
        filteredItems = filteredItems.filter(item => item.active == true);
        break;
      case "inactive":
        filteredItems = filteredItems.filter(item => item.active == false);
        break;
    }
    filteredItems = filteredItems.filter(item => !this.selectedUsers.includes(item));
    filteredItems = filteredItems.filter(item => !this.currentAgentUsers.includes(item));
    this.filteredItems = filteredItems;
  }

  getCurrentAgentUsers(users: User[], agentShips: AgentShip[]){
    let agentShipKeys: string[] = [];
    this.currentAgentUsers = [];
    agentShips.forEach(item => agentShipKeys.push(item.agentId))
    users.forEach(item => {
      if(agentShipKeys.includes(item.id)){
        this.currentAgentUsers.push(item);
      }
    });
    this.onFilterStringChange();
  }

  deleteAgency(agent: User){
    let agentShip = {} as AgentShip;
    agentShip = this.currentAgents.filter(item => item.agentId === agent.id)[0];
    
    let request: AgentShipOperationsRequest = new AgentShipOperationsRequest();
    request.action = DataOperation.Delete;
    request.item = agentShip;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }

    this._dataService.agentShipOperations(request).subscribe((data) => {
      if (data.message.ok === true) {
        this._toastr.success("Fjarlægði " + agent.name + " af " + agentShip.shipName);
        this.getUsers();
      }
      else {
        this._toastr.error("Ekki tókst að fjarlægja " + agent.name + " af " + this.currentShip.name);
        console.error("message", data.message);
      }
    }, (err) => this.ifError(err));
  }

  ifError(err: any) {
    //not implemented
    console.log(err);

    return this._errorHandler.handleError(err);
  };


}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AgentMasterShipOperationsRequest, AgentShipOperationsRequest, CustomerOperationsRequest, FishSpeciesOperationsRequest, GraphOffsetOperationsRequest, LandingSumOperationsRequest, MasterShipOperationsRequest, ShipOperationsRequest, UserOperationsRequest } from '../shared/request-models';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private apiUrlAgentShip: string = environment.apiServer + '/api/AgentShip/';
  private apiUrlAgentMasterShip: string = environment.apiServer + '/api/AgentMasterShip/';
  private apiUrlShip: string = environment.apiServer + '/api/Ship/';
  private apiUrlMasterShip: string = environment.apiServer + '/api/MasterShip/';
  private apiUrlUser: string = environment.apiServer + '/api/User/';
  private apiUrlCustomer: string = environment.apiServer + '/api/Customer/';
  private apiUrlFishSpecies: string = environment.apiServer + '/api/FishSpecies/';
  private apiUrlGraphOffset: string = environment.apiServer + '/api/GraphOffset/';
  private apiUrlLandingSum: string = environment.apiServer + '/api/LandingSum/';
  private apiUrlLanding: string = environment.apiServer + '/api/Landing/';
  private apiUrlLandingGraph: string = environment.apiServer + '/api/LandingGraph/';
  private apiUrlLandingStats: string = environment.apiServer + '/api/LandingStats/';
  private apiUrlProcessingRecord: string = environment.apiServer + '/api/ProcessingRecord/';
  private apiUrlProcessingRecordStats: string = environment.apiServer + '/api/ProcessingRecordStats/';
  private apiUrlVinnsluLota: string = environment.apiServer + '/api/VinnsluLota/';
  private apiUrlEmail: string = environment.apiServer + '/api/Email/';



  constructor(private _http: HttpClient, private _errorHandler: ErrorHandlerService) {}

  agentShipOperations<AgentShipOperationsResult>(request: AgentShipOperationsRequest) {
    //console.log('applicationOperations', request);
    return this._http.post<AgentShipOperationsResult>(this.apiUrlAgentShip, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }

  agentMasterShipOperations<AgentMasterShipOperationsResult>(request: AgentMasterShipOperationsRequest) {
    //console.log('applicationOperations', request);
    return this._http.post<AgentMasterShipOperationsResult>(this.apiUrlAgentMasterShip, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }

  shipOperations<ShipOperationsResult>(request: ShipOperationsRequest) {
    //console.log('applicationOperations', request);
    return this._http.post<ShipOperationsResult>(this.apiUrlShip, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }

  masterShipOperations<MasterShipOperationsResult>(request: MasterShipOperationsRequest) {
    //console.log('applicationOperations', request);
    return this._http.post<MasterShipOperationsResult>(this.apiUrlMasterShip, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }

  userOperations<UserOperationsResult>(request: UserOperationsRequest) {
    //console.log('applicationOperations', request);
    return this._http.post<UserOperationsResult>(this.apiUrlUser, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }
  customerOperations<CustomerOperationsResult>(request: CustomerOperationsRequest) {
    //console.log('applicationOperations', request);
    return this._http.post<CustomerOperationsResult>(this.apiUrlCustomer, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }
  fishSpeciesOperations<FishSpeciesOperationsResult>(request: FishSpeciesOperationsRequest) {
    //console.log('applicationOperations', request);
    return this._http.post<FishSpeciesOperationsResult>(this.apiUrlFishSpecies, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }
  graphOffsetOperations<GraphOffsetOperationsResult>(request: GraphOffsetOperationsRequest) {
    //console.log('applicationOperations', request);
    return this._http.post<GraphOffsetOperationsResult>(this.apiUrlGraphOffset, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }
  landingSumOperations<LandingSumOperationsResult>(request: LandingSumOperationsRequest) {
    //console.log('applicationOperations', request);
    return this._http.post<LandingSumOperationsResult>(this.apiUrlLandingSum, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }
  landingOperations<LandingOperationsResult>(request: LandingOperationsResult) {
    //console.log('applicationOperations', request);
    return this._http.post<LandingOperationsResult>(this.apiUrlLanding, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }

  landingGraphOperations<LandingGraphOperationsResult>(request: LandingGraphOperationsResult) {
    //console.log('applicationOperations', request);
    return this._http.post<LandingGraphOperationsResult>(this.apiUrlLandingGraph, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }

  landingStatsOperations<LandingStatsOperationsResult>(request: LandingStatsOperationsResult) {
    //console.log('applicationOperations', request);
    return this._http.post<LandingStatsOperationsResult>(this.apiUrlLandingStats, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }

  processingRecordOperations<ProcessingRecordOperationsResult>(request: ProcessingRecordOperationsResult) {
    //console.log('applicationOperations', request);
    return this._http.post<ProcessingRecordOperationsResult>(this.apiUrlProcessingRecord, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }

  processingRecordStatsOperations<ProcessingRecordStatsOperationsResult>(request: ProcessingRecordStatsOperationsResult) {
    //console.log('applicationOperations', request);
    return this._http.post<ProcessingRecordStatsOperationsResult>(this.apiUrlProcessingRecordStats, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }

  vinnsluLotaOperations<VinnsluLotaOperationsResult>(request: VinnsluLotaOperationsResult) {
    //console.log('applicationOperations', request);
    return this._http.post<VinnsluLotaOperationsResult>(this.apiUrlVinnsluLota, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }

  emailOperations<EmailOperationsResult>(request: EmailOperationsResult) {
    //console.log('applicationOperations', request);
    return this._http.post<EmailOperationsResult>(this.apiUrlEmail, request)
      .pipe(catchError((error) => this._errorHandler.handleError(error)));
  }
}

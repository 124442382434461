<div class="p-dialog-content">
    <div class="grid p-fluid mt-3">
        <div class="field col-12 md:col-4 dialog-form-field">
            <span class="p-float-label">
                <input pInputText id="name" [(ngModel)]="editRow.name" />
                <label htmlFor="name">Nafn</label>
            </span>
        </div>
    </div>
</div>


<div class="p-dialog-footer">
    <div class="row">
        <div class="col-md-9">
            <p-message *ngIf="validationErrorMessage.length > 0" severity="error" [text]="validationErrorMessage" styleClass="float-left"></p-message>
        </div>
        <div class="col-md-3">
            <button *ngIf="actionButtonLabel?.length > 0" pButton class="p-button-sm" (click)="action(editRow, actionMode)" [label]="actionButtonLabel" style="float: right;"></button>
        </div>
    </div>
</div>
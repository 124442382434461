import { Component, OnInit } from '@angular/core';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { AdminService } from '../../admin.service';
import { Customer } from 'src/app/shared/models';
import { DialogActionEnum } from 'src/app/shared/enums';
import { SsnValidator } from 'src/app/shared/helpers';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-customer-dialog',
  templateUrl: './customer-dialog.component.html',
  styleUrls: ['./customer-dialog.component.css']
})
export class CustomerDialogComponent implements OnInit {
  //Item to edit
  editRow: Customer;
  
  //Action to perform
  actionMode: DialogActionEnum;
  insertAction: DialogActionEnum = DialogActionEnum.Insert;
  updateAction: DialogActionEnum = DialogActionEnum.Update;
  deleteAction: DialogActionEnum = DialogActionEnum.Delete;
  viewAction: DialogActionEnum = DialogActionEnum.View;
  
  actionButtonLabel: string = "";

  validationErrorMessage: string = "";

  ssnValidator = SsnValidator;

  constructor(
    private _errorHandler: ErrorHandlerService,
    private _adminService: AdminService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
    this.actionMode = this.config.data.action;
    this.getActionLabel(this.actionMode);
    if(this.actionMode == DialogActionEnum.Insert){
      this.editRow = {} as Customer;
    }else{
      this.editRow = this.config.data.editRow;
    }
  }

  getActionLabel(action: DialogActionEnum){
    switch(action){
      case DialogActionEnum.Insert:
        this.actionButtonLabel = "Stofna";
        break;
      case DialogActionEnum.Update:
        this.actionButtonLabel = "Uppfæra";
        break;
      case DialogActionEnum.Delete:
        this.actionButtonLabel = "Eyða";
        break;
      case DialogActionEnum.SetPassword:
        this.actionButtonLabel = "Stilla lykilorð";
        break;
      default:
        this.actionButtonLabel = "";
        break;
    }
  }

  action(item: Customer, action: DialogActionEnum) {
    if(this.validateForm(item)){
      this.performAction(item, action);
    }
  }

  validateForm(item: Customer){
    if(!this.validateHasSsn(item)){
      this.validationErrorMessage = "Viðskiptavinur verður að hafa kennitölu";
      return false;
    }
    if(!this.validateSsn(item)){
      this.validationErrorMessage = "Kennitala verður að vera á réttu formi";
      return false;
    }
    if(!this.validateName(item)){
      this.validationErrorMessage = "Viðskiptavinur verður að hafa nafn";
      return false;
    }
    if(!this.validateHasCity(item)){
      this.validationErrorMessage = "Viðskiptavinur verður að hafa bæjarfélag";
      return false;
    }
    if(!this.validateHasAddress(item)){
      this.validationErrorMessage = "Viðskiptavinur verður að hafa heimilisfang";
      return false;
    }
    return true;    
  }

  validateHasSsn(item: Customer){
    return item?.ssn?.length > 0 ? true : false;
  }

  validateSsn(item: Customer){
    return this.ssnValidator(item.ssn, true);
  }

  validateName(item: Customer){
    return item?.name?.length > 0 ? true : false;
  }

  validateHasCity(item: Customer){
    return item?.city?.length > 0 ? true : false;
  }

  validateHasAddress(item: Customer){
    return item?.address?.length > 0 ? true : false;
  }

  performAction(item: Customer, action: DialogActionEnum){
    switch(action){
      case DialogActionEnum.Insert:
        this.insertCustomer(item);
        break;
      case DialogActionEnum.Update:
        this.updateCustomer(item);
        break;
      case DialogActionEnum.Delete:
        this.deleteCustomer(item);
        break;
      default:
        break;
    }
  }

  insertCustomer(item: Customer){
    this._adminService.addCustomer(item);
    this.ref.close(item);
  }

  updateCustomer(item: Customer){
    this._adminService.updateCustomer(item);
    this.ref.close(item);
  }

  deleteCustomer(item: Customer){
    this._adminService.deleteCustomer(item);
    this.ref.close(item);
  }

  ifError(err: any) {
    console.log(err);

    return this._errorHandler.handleError(err);
  };


}

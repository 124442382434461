import { Component} from '@angular/core';
import { ProcessingRecord, ProcessingRecordMonth, VinnsluLota } from 'src/app/shared/models';
import { AdminService } from '../admin.service';
import { DataOperation, ProcessingRecordViewModeEnum } from 'src/app/shared/enums';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessingRecordOperationsRequest } from 'src/app/shared/request-models';

@Component({
  selector: 'app-process-records',
  templateUrl: './process-records.component.html',
  styleUrls: ['./process-records.component.css']
})
export class ProcessRecordsComponent {
  isMobileSignal = this._adminService.isMobile;

  allProcessingRecordsSignal = this._adminService.allProcessingRecordsSignal;

  selectedView: ProcessingRecordViewModeEnum;
  monthViewEnum = ProcessingRecordViewModeEnum.MonthView;
  vinnsluLotaEnum = ProcessingRecordViewModeEnum.VinnsluLota;

  today: Date = new Date();

  currentMonth: Date;

  // processingRecordMonth: ProcessingRecordMonth[] = [];
  allProcessingDatesByMonthSignal = this._adminService.allProcessingDatesByMonthSignal;

  vinnsluLotaSignal = this._adminService.vinnsluLotaSignal;

  viewOptions: any[] = [{ label: 'Mánuðir', value: ProcessingRecordViewModeEnum.MonthView }, { label: 'Vinnslulotur', value: ProcessingRecordViewModeEnum.VinnsluLota}];


  constructor(
    private _adminService: AdminService,
    private _router: Router,
    private _route: ActivatedRoute
  ) { 
    const selectedView = this._route.snapshot.paramMap.get('selectedview')
    if(selectedView?.length > 0){
      this.selectedView = selectedView === 'manudir' 
        ? ProcessingRecordViewModeEnum.MonthView 
        : selectedView === 'lotur'
          ? ProcessingRecordViewModeEnum.VinnsluLota
          : ProcessingRecordViewModeEnum.NotUsed;
    }else{
      this.selectedView = ProcessingRecordViewModeEnum.MonthView;
    }
    this.currentMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1 );
    if(this.selectedView === this.monthViewEnum){
      this.getRecordsForMonth(this.getMonth(this.currentMonth), this.getYear(this.currentMonth));
    }else if(this.selectedView === this.vinnsluLotaEnum){
      this.getVinnsluLotur({} as VinnsluLota);
    }

  }

  getVinnsluLotur(item: VinnsluLota){
    this._adminService.getAllVinnsluLota(item);
  }

  dateChanged(date: Date){
    this.currentMonth = new Date(date);
    this.getRecordsForMonth(this.getMonth(this.currentMonth), this.getYear(this.currentMonth));
    // this.getProcessingRecords(
    //   {dagur_InT: this.currentDate } as ProcessingRecord
    // );
  }

  getProcessingRecords(item: ProcessingRecord){
    this._adminService.getProcessingRecordsForDay(item);
  }

  showDateReport(stringDate: string){
    const dateFromStringDate = new Date(stringDate);
    const dateString: string = dateFromStringDate.getFullYear() + '-' + (dateFromStringDate.getMonth() + 1) + '-' + dateFromStringDate.getDate();
    this._router.navigate(['/vinnslutolur/dagsskyrsla', dateString]);
  }

  getRecordsForMonth(month: number, year: number) {
    const item: ProcessingRecord = {} as ProcessingRecord;
    item.dagur_InT = new Date(year, month, 1);
    this._adminService.getProcessingRecordsForMonth(item);
  }

  viewChanged(event: ProcessingRecordViewModeEnum){
    this.selectedView = event;
    if(this.selectedView === this.monthViewEnum){
      this.getRecordsForMonth(this.getMonth(this.currentMonth), this.getYear(this.currentMonth));
      this._router.navigate(['vinnslutolur/manudir']);
    }else if(this.selectedView === this.vinnsluLotaEnum){
      this.getVinnsluLotur({} as VinnsluLota);
      this._router.navigate(['vinnslutolur/lotur']);
    }
  }

  compareDates(date1: Date, date2: Date, comparisonYear: number | null = null): boolean{
    if(comparisonYear){
      return this.generateNewDate(date1, comparisonYear) < this.generateNewDate(date2, comparisonYear);
    }else{
      return this.generateNewDate(date1) < this.generateNewDate(date2);
    }
  }

  datesAreEqual(date1: Date, date2: Date): boolean{
    return this.generateNewDate(date1).getTime() === this.generateNewDate(date2).getTime();
  }

  addYearToDate(date: Date, increment: number): Date{
    return this.generateNewDate(date, this.getYear(date) + increment);
  }

  addMonthToDate(date: Date, increment: number): Date{
    return this.generateNewDate(date, null, this.getMonth(date) + increment);
  }
  addDaysToDate(date: Date, increment: number): Date{
    return this.generateNewDate(date, null, null, this.getDay(date) + increment);
  }

  generateNewDate(date: Date, customYear: number | null = null, customMonth: number | null = null, customDay: number | null = null): Date{
    const year: number = customYear ? customYear : this.getYear(date);
    const month: number = customMonth ? customMonth : this.getMonth(date);
    const day: number = customDay ? customDay : this.getDay(date);

    return new Date(year, month, day);
  }

  getYear(date: Date): number{
    return new Date(date).getFullYear();
  }

  getMonth(date: Date): number{
    return new Date(date).getMonth();
  }

  getDay(date: Date): number{
    return new Date(date).getDate();
  }


}

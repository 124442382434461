<div>
  <div class="top-section">
    <h2 class="page-title">Bæta {{currentUser.name}} við á skip</h2>
    <button *ngIf="selectedShips.length > 0" type="button" class="btn btn-primary" (click)="actionItem(selectedShips, 'insert')"> {{getCreateAgencyText(selectedShips.length)}}</button>
  </div>
<div>
  <div class="filters-section btn-group btn-group-toggle" data-toggle="buttons">
    <label class="btn btn-secondary" [ngClass]="{'active':showActive=='all'}">
      <input type="radio" name="options" id="option1" autocomplete="off" [(ngModel)]="showActive" (ngModelChange)="onFilterStringChange()" [value]="'all'"> Með og án
    </label>
    <label class="btn btn-secondary" [ngClass]="{'active':showActive=='active'}">
      <input type="radio" name="options" id="option2" autocomplete="off" [(ngModel)]="showActive" (ngModelChange)="onFilterStringChange()" [value]="'active'"> Aðeins virkir
    </label>
    <label class="btn btn-secondary" [ngClass]="{'active':showActive=='inactive'}">
      <input type="radio" name="options" id="option3" autocomplete="off" [(ngModel)]="showActive" (ngModelChange)="onFilterStringChange()" [value]="'inactive'"> Aðeins óvirkir
    </label>
  </div>
</div>

<table class="table">
<thead>
  <th>Nafn</th>
  <th>Netfang</th>
  <th>Kennitala</th>
  <th>Sími</th>
  <th>Aðgerðir</th>
</thead>
<tbody>
  <tr *ngFor="let item of filteredItems">
    <td>{{item.name}}</td>
    <td>{{item.email}}</td>
    <td>{{item.ssn}}</td>
    <td>{{item.phone}}</td>
    <td>
        <i *ngIf="!shipSelected(item)" class="fas fa-plus-circle fa-action-button" pTooltip="Bæta {{item.name}} við á {{currentUser.name}}" (click)="shipAction(item, 'add')"></i>
    </td>
  </tr>
</tbody>
</table>

<h2>
    Valdir notendur
</h2>
<table class="table">
    <thead>
      <th>Nafn</th>
      <th>Netfang</th>
      <th>Kennitala</th>
      <th>Sími</th>
      <th>Aðgerðir</th>
    </thead>
    <tbody>
      <tr *ngFor="let item of selectedShips">
        <td>{{item.name}}</td>
        <td>{{item.email}}</td>
        <td>{{item.ssn}}</td>
        <td>{{item.phone}}</td>
        <td>
            <i *ngIf="shipSelected(item)" class="fas fa-minus-circle fa-action-button" pTooltip="Hætta við að bæta {{item.name}} við á {{currentUser.name}}" (click)="shipAction(item, 'remove')"></i>
        </td>
      </tr>
    </tbody>
    </table>

    <h2>
        Núverandi notendur
    </h2>
    <table class="table">
        <thead>
          <th>Nafn</th>
          <th>Aðgerðir</th>
        </thead>
        <tbody>
          <tr *ngFor="let item of currentShipAgencies">
            <td>{{item.name}}</td>
            <td>
                <i class="fas fa-trash-alt fa-action-button" pTooltip="Fjarlægja {{item.name}} af {{currentUser.name}}" (click)="deleteAgency(item)"></i>
            </td>
          </tr>
        </tbody>
        </table>

</div>


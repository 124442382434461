import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { MessageModule } from 'primeng/message';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TooltipModule } from 'primeng/tooltip';
import { ChartModule } from 'primeng/chart';
import { SliderModule } from 'primeng/slider';
import { DividerModule } from 'primeng/divider';
import { CarouselModule } from 'primeng/carousel';
import { CardModule } from 'primeng/card';

@NgModule({
  providers: [
    DialogService
  ],
  declarations: [],
  imports: [
    CommonModule,
    CalendarModule,
    ButtonModule,
    InputTextModule,
    InputNumberModule,
    DropdownModule,
    CheckboxModule,
    TableModule,
    DynamicDialogModule,
    MessageModule,
    SelectButtonModule,
    TooltipModule,
    ChartModule,
    SliderModule,
    DividerModule,
    CarouselModule,
    CardModule,
  ],
  exports: [
    CommonModule,
    CalendarModule,
    ButtonModule,
    InputTextModule,
    InputNumberModule,
    DropdownModule,
    CheckboxModule,
    TableModule,
    DynamicDialogModule,
    MessageModule,
    SelectButtonModule,
    TooltipModule,
    ChartModule,
    SliderModule,
    DividerModule,
    CarouselModule,
    CardModule,
  ]
})
export class PrimeNgModule { }

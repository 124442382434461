import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStoreService } from 'src/app/services/auth-store.service';
import { DataService } from 'src/app/services/data.service';
import { DataOperation } from 'src/app/shared/enums';
import { EmailStringValidator } from 'src/app/shared/helpers';
import { Email, User } from 'src/app/shared/models';
import { UserOperationsRequest } from 'src/app/shared/request-models';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.css']
})
export class LostPasswordComponent {
  accessToken: string = '';
  hasAccessToken: boolean = false;

  password: string = '';
  confirmPassword: string = '';

  newPasswordEmail: Email = {} as Email;
  newPasswordUser: User = {} as User;

  validationErrorMessage: string = '';

  emailValidator = EmailStringValidator;

  constructor(
    private _route: ActivatedRoute,
    private _auth: AuthStoreService,
    private _dataService: DataService,
    private _router: Router
  ){
  }

  ngOnInit(): void {
    this.accessToken = this._route.snapshot.paramMap.get('accessToken') ?? '';
    this.hasAccessToken = this.accessToken.length > 0;
  }

  //noAccessToken

  sendUpdatePasswordEmail(email: Email){
    if(!this.validateHasEmail(email.recipientEmail)){
      this.validationErrorMessage = 'Vantar netfang';
      return
    }
    if(!this.validateEmail(email.recipientEmail)){
      this.validationErrorMessage = 'Netfang ekki löglegt';
      return
    }
    this._auth.sendUpdatePasswordEmail(email);
  }

  validateHasEmail(email: string){
    return email?.length > 0 ? true : false;
  }

  validateEmail(email: string){
    return this.emailValidator(email, true, false);
  }

  //HasAccessToken

  validatePassword(password: string, confirmPassword: string, email: string){
    if(!this.validateHasEmail(email)){
      this.validationErrorMessage = 'Vantar netfang';
      return
    }
    if(!this.validateEmail(email)){
      this.validationErrorMessage = 'Netfang ekki löglegt';
      return
    }
    if(!this.passwordsMatch(password, confirmPassword)){
      console.warn('Lykilorð stemma ekki');
      this.validationErrorMessage = 'Lykilorð stemma ekki';
      return
    }
    if(!this.passwordLengthValid(password)){
      console.warn('Lykilorð verður að vera að minnsta kosti 8 stafir að lengd')
      this.validationErrorMessage = 'Lykilorð verður að vera að minnsta kosti 8 stafir að lengd';
      return
    }
    if(!this.passwordHasNumber(password)){
      console.warn('Lykilorð verður að innihalda tölustafi')
      this.validationErrorMessage = 'Lykilorð verður að innihalda tölustafi';
      return
    }
    this.validationErrorMessage = '';
    
    this.changePassword(password);
  }

  passwordsMatch(password: string, confirmPassword: string){
    return password === confirmPassword;
  }

  passwordLengthValid(password: string){
    return password.length >= 8;
  }

  passwordHasNumber(password: string){
    return /\d/.test(password);
  }

  changePassword(password: string){
    this.newPasswordUser.password = password;
    this._auth.changePassword(this.newPasswordUser, this.accessToken);
  }
}

<!-- PRIMENG BUTTONTOGGLE -->

<div>
  <div class="top-section">
    <h2 class="page-title">Notendur</h2>
    <button type="button" class="btn btn-primary" (click)="actionItem(null, insertAction)">Stofna Notanda</button>
  </div>
  <div class="row">
    <div class="col-4">
      <p-selectButton class="view-toggle" [options]="showActiveOptions" [(ngModel)]="showActive" (ngModelChange)="onFilter()" optionLabel="name" optionValue="value"></p-selectButton>
    </div>
    <div class="col-4">
      <p-selectButton class="view-toggle" [options]="selectedViewOptions" [(ngModel)]="selectedView" (ngModelChange)="onFilter()" optionLabel="name" optionValue="value"></p-selectButton>
    </div>
    <div class="col-4"></div>
  </div>
    <div>
      <div class="table-section">
        <table class="table" width="100%" cellspacing="0" cellpadding="6">
          <thead>
            <th class="table-header">Nafn</th>
            <th class="table-header">Netfang</th>
            <th class="table-header">Kennitala</th>
            <th class="table-header">Sími</th>
            <th class="table-header">Umsjón</th>
            <th class="table-header">Virk/ur</th>
            <th class="big-table-header">Aðgerðir</th>
          </thead>
          <tbody>
            <tr *ngFor="let item of filteredItems">
              <td>{{item.name}}</td>
              <td>{{item.email}}</td>
              <td>{{item.ssn}}</td>
              <td>{{item.phone}}</td>
              <td>{{translateBoolean(item.admin)}}</td>
              <td>
                <i *ngIf="!item.active" class="fas fa-eye-slash fa-red-action-button"
                  (click)="toggleActive(item,'Show')" data-toggle="tooltip" data-placement="top"
                  title="Virkja {{item.name}}"></i>
                <i *ngIf="item.active" class="fas fa-eye fa-green-action-button" (click)="toggleActive(item,'Hide')"
                  data-toggle="tooltip" data-placement="top" title="Afvirkja {{item.name}}"></i>
              </td>
              <td>
                <i *ngIf="(currentUser$ | async)?.id === item.id || (currentUser$ | async)?.ssn === '2012943639' || (currentUser$ | async)?.ssn === '0211614469'" class="fal fa-key fa-action-button" pTooltip="Uppfæra lykilorð hjá {{item.name}}"
                  (click)="actionItem(item, setPasswordAction)"></i>
                <i class="fas fa-user-plus fa-action-button" pTooltip="Bæta skipi við {{item.name}}"
                  [routerLinkActive]="['link-active']" [routerLink]="['nyttumbod/' + item.id]"></i>
                <i class="fal fa-hand-pointer fa-action-button" pTooltip="Skoða {{item.name}}"
                  (click)="actionItem(item, viewAction)"></i>
                <i class="far fa-edit fa-action-button" pTooltip="Uppfæra {{item.name}}"
                  (click)="actionItem(item, updateAction)"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


  </div>
<div>
    <div class="top-section">
        <h2 class="page-title">Fisktegundir</h2>
        <button type="button" class="btn btn-primary" (click)="actionItem(null, insertAction)">Stofna Fisktegund</button>
    </div>
    <div>
        <ejs-grid #grid [dataSource]='allFishSpeciesSignal()' [allowPaging]='true' [pageSettings]='pageSettings'
            [allowSorting]='true' [allowResizing]='true' (dataBound)="dataBound()">
            <e-columns>
                <e-column field='name' headerText='Nafn' width=120></e-column>
                <e-column field='isActive' headerText='Virk?' width=80>
                    <ng-template #template let-data>
                        <i *ngIf="data.isActive" class="fas fa-eye fa-green-action-button" (click)="setActive(data, false)"></i>
                        <i *ngIf="!data.isActive" class="fas fa-eye-slash fa-red-action-button" (click)="setActive(data,true)"></i>
                    </ng-template>
                </e-column>
                <e-column headerText="Aðgerðir">
                    <ng-template #template let-landingSum>
                        <!-- <i class="fal fa-hand-pointer fa-action-button" pTooltip="Skoða {{landingSum.name}}"
                            (click)="actionItem(landingSum, viewAction)"></i> -->
                        <i class="far fa-edit fa-action-button" pTooltip="Uppfæra {{landingSum.name}}"
                            (click)="actionItem(landingSum, updateAction)"></i>
                        <!-- <i class="fas fa-trash fa-action-button" pTooltip="Eyða {{landingSum.name}}"
                            (click)="actionItem(landingSum, deleteAction)"></i> -->
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>
    </div>
</div>

import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { User } from '../shared/models';

import { AuthStoreService } from "../services/auth-store.service";
import { Observable } from "rxjs";


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  currentUser$: Observable<User> = this._auth.currentUser$;


  constructor(private _router: Router, private _auth: AuthStoreService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {





    let states = state.url.split('/');
    //console.log('states',states);

    let rootRoute = states[1];

    if(rootRoute == "innskraning"){
      return true;
    }
    else if(rootRoute == "island"){
      return true;
    }
    else if(rootRoute == 'nyttlykilord'){
      return true;
    }
    else if(rootRoute == 'vefumsjon'){
      return true;
    }
    else if(rootRoute == "umsjon"){
      console.log('/umsjon');
      if (this._auth.currentUser) {
        // if(!this._auth.currentUser.acceptedCurrentDisclaimer){
        //   console.log('no dice');
        //   this._router.navigate(['/skilmalar']);
        //   return false;
        // }
        if (this._auth.currentUser.admin === true) {
          return true;
        }
        else {
          this._router.navigate(['/'], { queryParams: { returnUrl: state.url } });
          return false;
        }
      }
      else {
        this._router.navigate(['/innskraning'], { queryParams: { returnUrl: state.url } });
        return false;
      }
    }
    else {
      if(this._auth.currentUser){
        return true;
      }
      else{
        this._router.navigate(['/innskraning']);
        return false;
      }
    }

    console.log('state.url',state.url)
    switch (state.url) {

      case '/innskraning':
        console.log('/innskraning');
        return true;
        break;

        case '/vefumsjon':
          console.log('/vefumsjon');
          return true;
          break;

        case '/skilmalar':
          console.log('/skilmalar');
          if (this._auth.currentUser) {
            return true;
          }
          else{
            return false;
          }
          break;

      default:
        if (this._auth.currentUser) {
          // if(!this._auth.currentUser.acceptedCurrentDisclaimer){
          //   console.log('no dice');
          //   this._router.navigate(['/skilmalar']);
          //   return false;
          // }

          return true;
        }
        else {
          this._router.navigate(['/inskraning'], { queryParams: { returnUrl: state.url } });
          return false;
        }
        break;
    }

    return true;
  }
}

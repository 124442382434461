import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgentComponent } from '../admin/agent/agent.component';
import { HomeComponent } from '../home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { UsersComponent } from '../admin/users/users.component';
import { LogInComponent } from '../auth/log-in/log-in.component';
import { LogOutComponent } from '../auth/log-out/log-out.component';
import { ShipsComponent } from '../admin/ships/ships.component';
import { AddUsersToShipComponent } from '../admin/ships/add-users-to-ship/add-users-to-ship.component';
import { AddShipToUserComponent } from '../admin/agent/add-ship-to-user/add-ship-to-user.component';
import { ShipSumsComponent } from '../admin/ships/ship-sums/ship-sums.component';
import { SumsComponent } from '../admin/sums/sums.component';
import { LandingSumReportsComponent } from '../admin/reports/landing-sum-reports/landing-sum-reports.component';
import { AuthGuard } from '../helpers/auth-guard';
import { CustomersComponent } from '../admin/customers/customers.component';
import { ShipMasterComponent } from '../admin/ship-master/ship-master.component';
import { AddShipToMasterShipComponent } from '../admin/ship-master/add-ship-to-master-ship/add-ship-to-master-ship.component';
import { AddUsersToMasterShipComponent } from '../admin/ship-master/add-users-to-master-ship/add-users-to-master-ship.component';
import { ProcessRecordsComponent } from '../admin/process-records/process-records.component';
import { ProcessRecordsVinnslaComponent } from '../admin/process-records/process-records-vinnsla/process-records-vinnsla.component';
import { FishSpeciesComponent } from '../admin/fish-species/fish-species.component';



const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'agents', component: AgentComponent, pathMatch: 'full', canActivate: [AuthGuard], title: 'Heimildir'},
  { path: 'users', component: UsersComponent, pathMatch: 'full', canActivate: [AuthGuard], title: 'Notendur' },
  { path: 'vidskiptavinir', component: CustomersComponent, pathMatch: 'full', canActivate: [AuthGuard], title: 'Viðskiptavinir' },
  { path: 'users/nyttumbod/:slug', component: AddShipToUserComponent, canActivate: [AuthGuard]},
  { path: 'ships', component: ShipsComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  { path: 'skipaskraning', component: ShipMasterComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  { path: 'skipaskraning/:mastershipid/tengja', component: AddShipToMasterShipComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  { path: 'skipaskraning/nyttumbod/:mastershipid', component: AddUsersToMasterShipComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  { path: 'skipaskraning/summutolur/:slug', component: ShipSumsComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  { path: 'ships/nyttumbod/:slug', component: AddUsersToShipComponent, canActivate: [AuthGuard]},
  { path: 'summutolur', component: SumsComponent, canActivate: [AuthGuard]},
  { path: 'vinnslutolur', component: ProcessRecordsComponent, canActivate: [AuthGuard]},
  { path: 'fisktegundir', component: FishSpeciesComponent, canActivate: [AuthGuard]},
  { path: 'vinnslutolur/:selectedview', component: ProcessRecordsComponent, canActivate: [AuthGuard]},
  { path: 'vinnslutolur/vinnsla/:vinnslaid', component: ProcessRecordsVinnslaComponent, canActivate: [AuthGuard], title: 'Vinnsla'},
  { path: 'vinnslutolur/dagsskyrsla/:dagur', component: ProcessRecordsVinnslaComponent, canActivate: [AuthGuard], title: 'Dagsskýrsla'},
  { path: 'londunarskyrslur/:slug', component: LandingSumReportsComponent, canActivate: [AuthGuard],title: 'Löndunarskýrslur'},
  { path: 'innskraning', component: LogInComponent, pathMatch: 'full' },
  { path: 'utskraning', component: LogOutComponent, canActivate: [AuthGuard]},
];
@NgModule({
  declarations: [

  ],
  imports: [RouterModule.forRoot(routes,
    {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled'
}),
    CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthStoreService } from 'src/app/services/auth-store.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { PropertiesStoreService } from 'src/app/services/properties-store.service';
import { User } from 'src/app/shared/models';

@Component({
  selector: 'app-log-out',
  templateUrl: './log-out.component.html',
  styleUrls: ['./log-out.component.css']
})
export class LogOutComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();

  currentUser$: Observable<User> = this._auth.currentUser$;

  constructor(
    private _auth: AuthStoreService, 
    private _router: Router, 
    private _errorHandler: ErrorHandlerService,
    private _propertiesStoreService: PropertiesStoreService, 
    private _dataService: DataService) {
  }

  ngOnInit() {
    this.subscriptions.add(this.currentUser$.subscribe(value => {      
      if(value?.authenticated){
        this._router.navigate(['/']);
      }else{
        console.log('user is not authenticated...');
        this._router.navigate(['/innskraning']);
      }
    }
    ));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }


  logout() {
    console.log("logging out...");
    this._auth.logout();
    this._router.navigate(['/']);
  }

  cancel() {
    console.log('cancelling...');
    this._router.navigate(['/']);
  }


  ifError(err: any){
    //not implemented
    console.log(err);
    return this._errorHandler.handleError(err);
  };


  moduleConsole(message: string, object: any){
    console.log('logout.component', message, object);
  }

}

import { Component, OnInit } from '@angular/core';
// import { AdminService } from './admin.service';
// import { AuthStoreService } from '../services/auth-store.service';
// import { LandingSum } from '../shared/models';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor(
    // private _adminService: AdminService,
    // private _auth: AuthStoreService

  ) { }

  ngOnInit(): void {
  //   this._auth.currentUser$.subscribe(item => {
  //     if(item){
  //       if(item.admin){
  //         this._adminService.getAllCustomers();
  //         this._adminService.getAllMasterShips();
  //         this._adminService.getAllUsers();
  //         this._adminService.getAllAgentMasterShips();
  //         this._adminService.getLandingSumsByAgencies(new LandingSum());
  //       }
  //     }
  //   });
  }

}

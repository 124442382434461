/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { registerLicense } from '@syncfusion/ej2-base';


export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

registerLicense('Mgo+DSMBaFt+QHJqVk1hXk5Hd0BLVGpAblJ3T2ZQdVt5ZDU7a15RRnVfR11mSH1Qd0BqWn5Ycg==;Mgo+DSMBPh8sVXJ1S0R+X1pFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jTH5Wd0ZgWHpXc3ddRA==;ORg4AjUWIQA/Gnt2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXtTc0RgWn5beXdQR2I=;MTkzODE4M0AzMjMxMmUzMjJlMzNiaGtnb1QvREx1UE82S0d1andwMnVOL1ppWnU0VTlPUlZleDI2SVREeVgwPQ==;MTkzODE4NEAzMjMxMmUzMjJlMzNub3lPbTdxTkRUcGh1REE2ZFZubE5zaHdBNWhZN0ZUelRyK21lVzdCMmRzPQ==;NRAiBiAaIQQuGjN/V0d+Xk9HfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkBiWnxfdX1RTmRU;MTkzODE4NkAzMjMxMmUzMjJlMzNSVEplam9keG5FTkFUNXpUSWNkRXp5NXhtYStvTWlOOGJIelA0dHVrN3FZPQ==;MTkzODE4N0AzMjMxMmUzMjJlMzNuWXVjUHU0clVCVC9LOWJ2RTZoVUtBTlBHYkJma3ZSZjJZUkV1aTdOL3A0PQ==;Mgo+DSMBMAY9C3t2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXtTc0RgWn5beXFQQmg=;MTkzODE4OUAzMjMxMmUzMjJlMzNodzVOek9MbXlYYXgzU05UNklxc3RteUlTZ2U0REVmNm1LYzh4S1JPRHV3PQ==;MTkzODE5MEAzMjMxMmUzMjJlMzNsR2I4K1NsSTZKdExDOXV4bHZOOHN6L2pxb0ZpZkNZMU9rT0NzL3RVbTNVPQ==;MTkzODE5MUAzMjMxMmUzMjJlMzNSVEplam9keG5FTkFUNXpUSWNkRXp5NXhtYStvTWlOOGJIelA0dHVrN3FZPQ==');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));


<div>
    <div class="top-section">
        <h2 class="page-title">Bæta notendum við {{ currentMasterShip.name }}</h2>
        <button *ngIf="selectedUsers?.length > 0" type="button" class="btn btn-primary"
            (click)="actionItem(selectedUsers, 'insert')">
            {{ getCreateAgencyText(selectedUsers.length) }}
        </button>
    </div>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="user-table">
                    <h2>Notendur</h2>
                    <table class="table">
                        <thead>
                            <th>Nafn</th>
                            <th>Netfang</th>
                            <th>Kennitala</th>
                            <th>Sími</th>
                            <th>Aðgerðir</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of filteredItems">
                                <td>{{ item.name }}</td>
                                <td>{{ item.email }}</td>
                                <td>{{ item.ssn }}</td>
                                <td>{{ item.phone }}</td>
                                <td>
                                    <i *ngIf="!userSelected(item)" class="fas fa-plus-circle fa-action-button"
                                        pTooltip="Bæta {{ item.name }} við á {{ currentMasterShip.name }}"
                                        (click)="userAction(item, 'add')"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col">
                <div class="picked-user-table" >
                    <h2>Valdir notendur</h2>
                    <table class="table" *ngIf="selectedUsers?.length > 0">
                        <thead>
                            <th>Nafn</th>
                            <th>Netfang</th>
                            <th>Kennitala</th>
                            <th>Sími</th>
                            <th>Aðgerðir</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of selectedUsers">
                                <td>{{ item.name }}</td>
                                <td>{{ item.email }}</td>
                                <td>{{ item.ssn }}</td>
                                <td>{{ item.phone }}</td>
                                <td>
                                    <i *ngIf="userSelected(item)" class="fas fa-minus-circle fa-action-button"
                                        pTooltip="Hætta við að bæta {{ item.name }} við á {{
                                currentMasterShip.name
                              }}" (click)="userAction(item, 'remove')"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p *ngIf="selectedUsers?.length == 0" style="color: grey">Enginn notandi valinn</p>
                </div>

                <div class="current-user-table">
                    <h2>Núverandi notendur</h2>
                    <table class="table">
                        <thead>
                            <th>Nafn</th>
                            <th>Netfang</th>
                            <th>Kennitala</th>
                            <th>Sími</th>
                            <th>Aðgerðir</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of currentAgentUsers">
                                <td>{{ item.name }}</td>
                                <td>{{ item.email }}</td>
                                <td>{{ item.ssn }}</td>
                                <td>{{ item.phone }}</td>
                                <td>
                                    <!-- <i class="fas fa-minus-circle fa-action-button" ></i> -->
                                    <i class="fas fa-trash-alt fa-action-button"
                                        pTooltip="Fjarlægja {{ item.name }} af {{ currentMasterShip.name }}"
                                        (click)="deleteAgency(item)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
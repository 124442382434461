export enum DataOperation
{
    NotUsed = 0, //
    Read = 1,  //always 1 result
    Update = 2,
    Delete = 3,
    List = 4,
    Search = 5,
    Insert = 6,
    Salt = 7,
    ReadHeader = 8,
    InsertOrUpdate = 9,
    Verify = 10,
    FindBySsn = 11,
    ResetPasswordSave = 12,
    SetPassword = 13,
    SetActive = 14,
    ListByAgent = 15,
    ListByShip = 16,
    ListByCargoNumber = 17,
    ListByAgencies = 18,
    SetCustomerId = 19,
    FindByCustomerNumber = 20,
    GetMaxId = 21,
    RefreshFromSync = 22,
    ListByMasterShip = 23,
    SetMasterShipId = 24,
    ListByMasterShipAndAgent = 25,
    UpdateTVNNumber = 26,
    ListByDate = 27,
    ListByLota = 28,
    ListByCargoKey = 29,
    FindByCargoKey = 30,
    ListByCargoKeyWithOffset = 31,
    GetBufferValue = 41,
    SetBufferValue = 42,
    GetLandingStats = 43,
    GetProcessingDatesByMonth = 44,
    ChangePasswordLoggedIn = 45,
    ChangePasswordLoggedOut = 46,
    ChangePassword = 47,
    FindByEmail = 48,
    UpdateNavCode = 49,
    UpdateVeidarfaeri = 50,
    UpdateFisktegund = 51,
    ListActive = 52,

    //LandingGraphOperations
    GetHeatGraphDataVigt1 = 100,
    GetHeatGraphDataVigt2 = 101,
    GetHourlyWeightGraphDataVigt1 = 102,
    GetHourlyWeightGraphDataVigt2 = 103,
    GetHeatGraphDataVigt1Offset = 104,
    GetHeatGraphDataVigt2Offset = 105,
    GetHourlyWeightGraphDataVigt1Offset = 106,
    GetHourlyWeightGraphDataVigt2Offset = 107,

}

export enum DataResult{
    NotUsed = 0,
    Success = 1,
    NotFound = 2,
    TooManyResults = 3,
    TooFewResults = 4,
    DataMissing = 5,
    UnexpectedZero = 6,
    UnexpectedNull = 7,
    UnexpectedEmptyString = 8,  //Expected string but got null or empty string
    Error = 10,
    CouldNotInsert = 20,
    CouldNotInsertNameExists = 21,  //Could not insert because of constraint violation of the name column
    CouldNotDelete = 30,
    CouldNotDeleteDependingRows = 31, //Could not delete because of constraint violation of depending rows
    CouldNotSelect = 40,
    CouldNotUpdate = 50
}

export enum DialogActionEnum{
    NotUsed = 0,
    Insert = 1,
    Update = 2,
    Delete = 3,
    View = 4,
    SetPassword = 5,
}

export enum ProcessingRecordViewModeEnum{
    NotUsed = 0,
    MonthView = 1,
    VinnsluLota = 2,
    DayView = 3,
    HeatView = 4,
    NiRView = 5,
    WaterUsageView = 6,
    EnergyView = 7,
}

export enum CurrentGraphEnum{
    NotUsed = 0,
    Heat1 = 1,
    Heat2 = 2,
    HourlyWeight1 = 3,
    HourlyWeight2 = 4,
}

export enum GraphOffsetEnum{
    NotUsed = 0,
    Heat1 = 1,
    Heat2 = 2,
    HourlyWeight1 = 3,
    HourlyWeight2 = 4
}
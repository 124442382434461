import { Component, ElementRef, OnInit, ViewChild, computed, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { FishSpecies, GraphOffset, HourlyData, HourlySum, Landing, LandingGraph, LandingStats, LandingSum, MasterShip, Ship } from 'src/app/shared/models';
import { AdminService } from '../../admin.service';
import { Observable } from 'rxjs';
import { AltGreen, Black, BrimBlue, DefaultGraphCrosshair, DefaultGraphMarker, DefaultGraphTooltip, DefaultLandingSumXAxis, DefaultLandingSumXAxisHourly, DefaultLandingSumYAxis, DefaultLandingSumYAxisHourly, DefaultLandingSumYAxisWeight, Red, StdBlue } from 'src/app/shared/constants';
import * as html2pdf from 'html2pdf.js';
import { GraphService } from 'src/app/services/graph.service';
import { CurrentGraphEnum, GraphOffsetEnum } from 'src/app/shared/enums';

@Component({
  selector: 'app-landing-sum-reports',
  templateUrl: './landing-sum-reports.component.html',
  styleUrls: ['./landing-sum-reports.component.css']
})
export class LandingSumReportsComponent implements OnInit {
  @ViewChild('grid') public grid: GridComponent;
  @ViewChild('pdfContent') pdfContent: ElementRef;

  heatGraphOffset = this._adminService.heat1GraphOffset;
  weightGraphOffset = this._adminService.weightGraphOffset;
  weightGraph2Offset = this._adminService.weightGraph2Offset;
  heatGraphOffsetSignal = this._adminService.heatGraphOffsetSignal;
  weightGraphOffsetSignal = this._adminService.weightGraphOffsetSignal;
  weightGraph2OffsetSignal = this._adminService.weightGraph2OffsetSignal;

  heatGraphEditOnSignal = signal<boolean>(false);
  weightGraphEditOnSignal = signal<boolean>(false);
  weightGraph2EditOnSignal = signal<boolean>(false);

  veidarfaeriEditOn: boolean = false;
  navCodeEditOn: boolean = false;
  fisktegundEditOn: boolean = false;

  heatGraphOffsetEnum: GraphOffsetEnum = GraphOffsetEnum.Heat1;
  weight1GraphOffsetEnum: GraphOffsetEnum = GraphOffsetEnum.HourlyWeight1;
  weight2GraphOffsetEnum: GraphOffsetEnum = GraphOffsetEnum.HourlyWeight2;

  slug: string = "";

  displayPDFContent:boolean = false;
  pdfGenerationReady: boolean = false;

  heatChartTitle1: string = "Hitastig";
  weightChartTitle1: string = "Þyngd";
  hourlyChartTitle: string = "Þyngd per klst";

  currentMasterShip$: Observable<MasterShip> = this._adminService.currentMasterShipForLandingSum$;
  currentLandingSum$: Observable<LandingSum> = this._adminService.currentLandingSum$;
  currentLandingStats$: Observable<LandingStats> = this._adminService.currentLandingStats$;

  heat1Enum: CurrentGraphEnum = CurrentGraphEnum.Heat1;
  heat2Enum: CurrentGraphEnum = CurrentGraphEnum.Heat2;
  hourlyWeight1Enum: CurrentGraphEnum = CurrentGraphEnum.HourlyWeight1;
  hourlyWeight2Enum: CurrentGraphEnum = CurrentGraphEnum.HourlyWeight2;

  graphsFullWidth: boolean = false;
  graphsFullWidthSignal = signal<boolean>(false);

  heat1GraphSignal = this._adminService.heat1GraphSignal;
  weight1GraphSignal = this._adminService.weight1GraphSignal;
  weight2GraphSignal = this._adminService.weight2GraphSignal;

  veidarfaeriOptions: string[] = ["", "Troll", "Nót"];
  fisktegundOptions: FishSpecies[] = [] as FishSpecies[];
  
  vigt1MinValues = computed(() => {
    return this.getMinValues(this.heat1GraphSignal());
  });
  
  vigt1MaxValues = computed(() => {
    return this.getMaxValues(this.heat1GraphSignal());
  });
  
  vigt1AvgValues = computed(() => {
    return this.getAvgValues(this.heat1GraphSignal());
  });

  vigt1XLabels = computed(() => {
    return this.getKlstLabels(this.heat1GraphSignal());
  });

  heat1GraphData = computed(() => {
    if(this.heat1GraphSignal()?.length > 0){
      return this.generateGraph(
        [this.vigt1MinValues(), this.vigt1MaxValues(), this.vigt1AvgValues()], 
        this.vigt1XLabels(), 
        ["Bein Löndun: Min", "Bein Löndun: Max", "Bein Löndun: Með"], 
        [StdBlue, Red, AltGreen],
        "line",
        "°C"
      );
    }else{
      return {};
    }
  });

  vigt2HeatDataSignal = this._adminService.vigt2HeatDataSignal;

  vigt2MinValues = computed(() => {
    return this.getMinValues(this.vigt2HeatDataSignal());
  });

  vigt2MaxValues = computed(() => {
    return this.getMaxValues(this.vigt2HeatDataSignal());
  });

  vigt2AvgValues = computed(() => {
    return this.getAvgValues(this.vigt2HeatDataSignal());
  });

  vigt2XLabels = computed(() => {
    return this.getKlstLabels(this.vigt2HeatDataSignal());
  });

  heat2GraphData = computed(() => {
    if(this.vigt2HeatDataSignal()?.length > 0){
      return this.generateGraph(
        [this.vigt2MinValues(), this.vigt2MaxValues(), this.vigt2AvgValues()], 
        this.vigt2XLabels(), 
        ["Vigt 2: Min", "Vigt 2: Max", "Vigt 2: Með"], 
        [StdBlue, Red, AltGreen],
        "line",
        "°C"
      );
    }else{
      return {};
    }
  });


  vigt1HourlyWeightValues = computed(() => {
    return this.getMagnValues(this.weight1GraphSignal());
  });

  vigt1HourlyWeightXLabels = computed(() => {
    return this.getKlstLabels(this.weight1GraphSignal());
  });


  vigt2HourlyWeightValues = computed(() => {
    return this.getMagnValues(this.weight2GraphSignal());
  });

  vigt2HourlyWeightXLabels = computed(() => {
    return this.getKlstLabels(this.weight2GraphSignal());
  });

  vigt1HourlyWeightGraphData = computed(() => {
    if(this.vigt1HourlyWeightValues()?.length > 0){
      return this.generateGraph(
        [this.vigt1HourlyWeightValues()],
        this.vigt1HourlyWeightXLabels(),
        ["Magn"],
        [BrimBlue],
        "line"
      );
    }else{
      return {};
    }
  });

  vigt2HourlyWeightGraphData = computed(() => {
    if(this.vigt2HourlyWeightValues()?.length > 0){
      return this.generateGraph(
        [this.vigt2HourlyWeightValues()],
        this.vigt2HourlyWeightXLabels(),
        ["Magn"],
        [StdBlue],
        "line"
      );
    }else{
      return {};
    }
  });

  options: any;
  heatOptions: any;


  tvnNumber: number = null;

  landingStats = this._adminService.landingStatsSignal;

  navCode: string = "";
  veidarfaeri: string = "";
  fisktegund: string = "";

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _errorHandler: ErrorHandlerService,
    private _adminService: AdminService,
    private _graphService: GraphService
  ) { }

  ngOnInit(): void {
    this.heatOptions = this.generateOptions(false, "°C", -2, 8);
    this.options = this.generateOptions(false, "kg", 0, 8);

    try {
      this.slug = this._route.snapshot.paramMap.get('slug');
      // this.getItem();
      const landingSum: LandingSum = {} as LandingSum;
      landingSum.cargoKey = this.slug;
      this._adminService.findLandingSumByCargoKey(landingSum);
      // this.getLandingStats(this.slug);
      this.getLandingsWithOffset(this.slug);

      this._adminService.currentLandingSum$.subscribe(item => {
        if(item){
          console.log(item);
          this.navCode = item.navCode;
          if(!item.veidarfaeri){
            this.veidarfaeri = "";
          }else{
            this.veidarfaeri = item.veidarfaeri;
          }
          this.fisktegundOptions = item.activeFishSpecies;
          if(!item.fisktegund){
            this.fisktegund = "";
          }else{
            this.fisktegund = item.fishSpeciesId;
          }
          
          const masterShip: MasterShip = {} as MasterShip;
          masterShip.id = item.masterShipId;
          this._adminService.getMasterShip(masterShip, "currentmastershipforlandingsum");
          this.tvnNumber = item.tvn_Number;
          setTimeout(() => {
            this.pdfGenerationReady = true;
          }, 3000);
        }
      });
    }
    catch (e) {
      console.error(e);
      this._router.navigate(["/"]);
    }
  }

  getLandingsWithOffset(cargoKey: string){
    const landing: Landing = {} as Landing;
    landing.cargoKey = cargoKey;
    this._adminService.getLandingsFromCargoKeyWithOffset(landing);
  }

  graphsWidthChange(event: boolean){
    console.log("graphsWidthChange", event);
    this.graphsFullWidthSignal.set(event);
  }

  getLandingStats(cargoKey: string){
    const landingStats: LandingStats = {} as LandingStats;
    landingStats.cargoKey = cargoKey;
    this._adminService.getLandingStatsByCargoKey(landingStats);
  }

  getHeatGraphData(cargoKey: string, scale: 1 | 2){
    const landing: LandingGraph = {} as LandingGraph;
    landing.cargoKey = cargoKey;
    if(scale === 1){
      this._adminService.getVigt1HeatDataWithOffset(landing);
    }else{
      this._adminService.getVigt2HeatData(landing);
    }
  }

  getHourlyWeightGraphData(cargoKey: string, scale: 1 | 2){
    const landing: LandingGraph = {} as LandingGraph;
    landing.cargoKey = cargoKey;
    if(scale === 1){
      this._adminService.getVigt1HourlyWeightDataWithOffset(landing);
    }else{
      this._adminService.getVigt2HourlyWeightDataWithOffset(landing);
    }
  }

  updateTVNNumber(){
    const landingSum: LandingSum = this._adminService.currentLandingSum;
    landingSum.tvn_Number = this.tvnNumber;
    this._adminService.updateTVNNumber(landingSum);
  }

  generatePDF(): void {
    this.displayPDFContent = true;
    setTimeout(() => {
      const content = this.pdfContent.nativeElement;
      html2pdf()
        .from(content)
        .save('charts.pdf')
        .then(
        );

    }, 500);

    setTimeout(() => {
      this.displayPDFContent = false;
    }, 1000);
  }

  filterValues(values: number[], rangeValues: number[]): number[]{
    return values.slice(rangeValues[0], rangeValues[1]);
  }

  filterLabels(labels: string[], rangeValues: number[], currentGraph: CurrentGraphEnum): string[]{
    return labels.slice(rangeValues[0], rangeValues[1]);
  }

  getIndexOfOption(index: number, option: string): number {
    return index;
  }

  generateGraph(dataSetData: number[][], labels: string[], dataSetLabels: string[], colors: string[], graphType: string, postFix: string = ""){
    return this._graphService.generateGraphData(dataSetData, labels, dataSetLabels, colors, graphType, postFix);
  }

  getXLabelCount(numberLists: number[]): number{
    //Takes in a list of numbers and returns the highest number in the list
    let highestNumber = 0;
    numberLists.forEach(numberList => {
      if(numberList > highestNumber){
        highestNumber = numberList;
      }
    });
    return highestNumber;
  }

  generateHeatGraph(
    scale1Heat: number[], 
    scale2Heat: number[], 
    documentStyle = getComputedStyle(document.documentElement)
  ){
    return {
      labels: this.generateNumberList(scale1Heat.length > scale2Heat.length ? scale1Heat.length : scale2Heat.length),
      datasets: [
        {
          label: 'Vigt 1',
          data: scale1Heat,
          fill: false,
          borderColor: documentStyle.getPropertyValue('--black-500'),
          tension: 0.1
        },
        {
          label: 'Vigt 2',
          data: scale2Heat,
          fill: false,
          borderColor: documentStyle.getPropertyValue('--red-500'),
          tension: 0.1
        }
      ]
    }
  }

  generateWeightGraph(
    scale1: number[], 
    scale2: number[], 
    documentStyle = getComputedStyle(document.documentElement)
  ){
    return {
      labels: this.generateNumberList(scale1.length > scale2.length ? scale1.length : scale2.length),
      datasets: [
        {
          label: 'Vigt 1',
          data: scale1,
          fill: false,
          borderColor: documentStyle.getPropertyValue('--black-500'),
          tension: 0.1
        },
        {
          label: 'Vigt 2',
          data: scale2,
          fill: false,
          borderColor: documentStyle.getPropertyValue('--red-500'),
          tension: 0.1
        }
      ]
    }
  }

  generateHourlyWeightGraph(
    data: number[], 
    label: string, 
    color: string,
    documentStyle = getComputedStyle(document.documentElement)
  ){
    return {
      labels: this.generateNumberList(data.length),
      datasets: [
        {
          label: label,
          data: data,
          fill: false,
          borderColor: documentStyle.getPropertyValue(color),
          tension: 0.1
        },
      ]
    }
  }

  generateNumberList(inputNumber: number): string[] {
    if (inputNumber <= 0) {
      // Handle invalid input (less than or equal to 0)
      return [];
    }

    // Generate an array of numbers as strings from 1 to the inputNumber
    const numberList: string[] = [];
    for (let i = 1; i <= inputNumber; i++) {
      numberList.push(i.toString());
    }

    return numberList;
  }

  generateOptions(stacked: boolean, postFix: string, suggestedMin: number = null, suggestedMax: number = null){
    return this._graphService.generateOptions(stacked, postFix, suggestedMin, suggestedMax);
  }

  generateResponsiveOptions(stacked: boolean, postFix: string, suggestedMin: number = null, suggestedMax: number = null){
    return this._graphService.generateResponsiveOptions(stacked, postFix, suggestedMin, suggestedMax);
  }

  getMinValues(landings: LandingGraph[]): number[] {
    const values: number[] = landings?.map(landing => landing.min).filter(value => value !== undefined) as number[];
    console.log("getMinValues",values);
    return values;
  }

  getMaxValues(landings: LandingGraph[]): number[] {
    const values: number[] = landings?.map(landing => landing.max).filter(value => value !== undefined) as number[];
    console.log("getMaxValues",values);
    return values;
  }

  getAvgValues(landings: LandingGraph[]): number[] {
    const values: number[] = landings?.map(landing => landing.avg).filter(value => value !== undefined) as number[];
    console.log("getAvgValues",values);
    return values;
  }

  getMagnValues(landings: LandingGraph[]): number[] {
    const values: number[] = landings?.map(landing => landing.magn).filter(value => value !== undefined) as number[];
    console.log("getMagnValues",values);
    return values;
  }

  getKlstLabels(landings: LandingGraph[]): string[] {
    const values: string[] = landings?.map(landing => landing.klst).filter(value => value !== undefined) as string[];
    console.log("getKlstLabels",values);
    return values;
  }

  updateGraphOffset(graphOffset: GraphOffsetEnum){
    console.log("updateGraphOffset", graphOffset)
    switch(graphOffset){
      case GraphOffsetEnum.Heat1:
        console.log("updateHeat1GraphOffset", this.heatGraphOffset);
        this._adminService.insertOrUpdateGraphOffset(this.heatGraphOffset, this.slug);
        break;
      case GraphOffsetEnum.Heat2:
        console.log("updateHeat2GraphOffset", this.heatGraphOffset);
        // this._adminService.updateHeat2GraphOffset(this.heatGraphOffset);
        break;
      case GraphOffsetEnum.HourlyWeight1:
        console.log("updateWeightGraphOffset", this.weightGraphOffset);
        this._adminService.insertOrUpdateGraphOffset(this.weightGraphOffset, this.slug);
        break;
      case GraphOffsetEnum.HourlyWeight2:
        console.log("updateWeightGraph2Offset", this.weightGraph2Offset);
        this._adminService.insertOrUpdateGraphOffset(this.weightGraph2Offset, this.slug);
        break;
      default:
        break;
    }
  }

  graphEditToggle(graphType: GraphOffsetEnum){
    console.log("graphEditToggle", graphType);
    switch(graphType){
      case GraphOffsetEnum.Heat1:
        this.heatGraphOffset = this._adminService.heat1GraphOffset;
        const editOn: boolean = this.heatGraphEditOnSignal();
        this.heatGraphEditOnSignal.set(!editOn);
        break;
      case GraphOffsetEnum.HourlyWeight1:
        this.weightGraphOffset = this._adminService.weightGraphOffset;
        const editOn1: boolean = this.weightGraphEditOnSignal();
        this.weightGraphEditOnSignal.set(!editOn1);
        break;
      case GraphOffsetEnum.HourlyWeight2:
        this.weightGraph2Offset = this._adminService.weightGraph2Offset;
        const editOn2: boolean = this.weightGraph2EditOnSignal();
        this.weightGraph2EditOnSignal.set(!editOn2);
        break;
      default:
        break;
    }
  }

  navCodeEditOnToggle(){
    this.navCodeEditOn = !this.navCodeEditOn;
  }

  updateNavCode(){
    const landingSum: LandingSum = this._adminService.currentLandingSum;
    landingSum.navCode = this.navCode;
    this._adminService.updateNavCode(landingSum);
    this.navCodeEditOn = false;
  }

  veidarfaeriEditOnToggle(){
    this.veidarfaeriEditOn = !this.veidarfaeriEditOn;
  }

  fisktegundEditOnToggle(){
    this.fisktegundEditOn = !this.fisktegundEditOn;
  }

  updateVeidarfaeri(){
    const landingSum: LandingSum = this._adminService.currentLandingSum;
    landingSum.veidarfaeri = this.veidarfaeri;
    this._adminService.updateVeidarfaeri(landingSum);
    this.veidarfaeriEditOn = false;
  }

  updateFisktegund(){
    const landingSum: LandingSum = this._adminService.currentLandingSum;
    landingSum.fishSpeciesId = this.fisktegund;
    this._adminService.updateFisktegund(landingSum);
    this.fisktegundEditOn = false;
  }

  ifError(err: any) {
    //not implemented
    console.log(err);

    return this._errorHandler.handleError(err);
  };

}

import { Component } from '@angular/core';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { DialogActionEnum } from 'src/app/shared/enums';
import { SsnValidator } from 'src/app/shared/helpers';
import { FishSpecies } from 'src/app/shared/models';
import { AdminService } from '../../admin.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-fish-species-dialog',
  templateUrl: './fish-species-dialog.component.html',
  styleUrls: ['./fish-species-dialog.component.css']
})
export class FishSpeciesDialogComponent {
 //Item to edit
 editRow: FishSpecies;
  
 //Action to perform
 actionMode: DialogActionEnum;
 insertAction: DialogActionEnum = DialogActionEnum.Insert;
 updateAction: DialogActionEnum = DialogActionEnum.Update;
 deleteAction: DialogActionEnum = DialogActionEnum.Delete;
 viewAction: DialogActionEnum = DialogActionEnum.View;
 
 actionButtonLabel: string = "";

 validationErrorMessage: string = "";

 ssnValidator = SsnValidator;

 constructor(
   private _errorHandler: ErrorHandlerService,
   private _adminService: AdminService,
   public ref: DynamicDialogRef,
   public config: DynamicDialogConfig,
 ) { }

 ngOnInit(): void {
   this.actionMode = this.config.data.action;
   this.getActionLabel(this.actionMode);
   if(this.actionMode == DialogActionEnum.Insert){
     this.editRow = {} as FishSpecies;
   }else{
     this.editRow = this.config.data.editRow;
   }
 }

 getActionLabel(action: DialogActionEnum){
   switch(action){
     case DialogActionEnum.Insert:
       this.actionButtonLabel = "Stofna";
       break;
     case DialogActionEnum.Update:
       this.actionButtonLabel = "Uppfæra";
       break;
     case DialogActionEnum.Delete:
       this.actionButtonLabel = "Eyða";
       break;
     default:
       this.actionButtonLabel = "";
       break;
   }
 }

 action(item: FishSpecies, action: DialogActionEnum) {
   if(this.validateForm(item)){
     this.performAction(item, action);
   }
 }

 validateForm(item: FishSpecies){
   if(!this.validateName(item)){
     this.validationErrorMessage = "Viðskiptavinur verður að hafa nafn";
     return false;
   }
   return true;    
 }

 validateName(item: FishSpecies){
   return item?.name?.length > 0 ? true : false;
 }

 performAction(item: FishSpecies, action: DialogActionEnum){
   switch(action){
     case DialogActionEnum.Insert:
       this.insertFishSpecies(item);
       break;
     case DialogActionEnum.Update:
       this.updateFishSpecies(item);
       break;
     case DialogActionEnum.Delete:
       this.deleteFishSpecies(item);
       break;
     default:
       break;
   }
 }

 insertFishSpecies(item: FishSpecies){
   this._adminService.addFishSpecies(item);
   this.ref.close(item);
 }

 updateFishSpecies(item: FishSpecies){
   this._adminService.updateFishSpecies(item);
   this.ref.close(item);
 }

 deleteFishSpecies(item: FishSpecies){
   this._adminService.deleteFishSpecies(item);
   this.ref.close(item);
 }

 ifError(err: any) {
   console.log(err);

   return this._errorHandler.handleError(err);
 };

}

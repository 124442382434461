import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthStoreService } from 'src/app/services/auth-store.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { DataOperation } from 'src/app/shared/enums';
import { AgentShip, Ship, User } from 'src/app/shared/models';
import { AgentShipOperationsRequest, AgentShipOperationsResult, ShipOperationsRequest, UserOperationsRequest, UserOperationsResult } from 'src/app/shared/request-models';

@Component({
  selector: 'app-add-ship-to-user',
  templateUrl: './add-ship-to-user.component.html',
  styleUrls: ['./add-ship-to-user.component.css']
})
export class AddShipToUserComponent implements OnInit {

  slug: string = "";
  currentUser: User = {} as User;

  showActive: string = "active";
  filteredItems: Ship[] = [];
  allShips: Ship[] = [];

  shipsFetched: boolean = false;

  selectedShips: Ship[] = [];

  currentAgents: AgentShip[] = [];
  currentShipAgencies: Ship[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _auth: AuthStoreService,
    private _dataService: DataService,
    private _errorHandler: ErrorHandlerService,
    private _toastr: ToastrService
  ) { }

  ngOnInit(): void {
    try {
      this.slug = this._route.snapshot.paramMap.get('slug');
      this.getItem();
    }
    catch (e) {
      console.error(e);
      this._router.navigate(["/"]);
    }
    console.log(this._auth.currentUser);
  }

  getItem(){
    let request: UserOperationsRequest = new UserOperationsRequest();
    request.item = {} as User;
    request.item.id = this.slug;
    request.action = DataOperation.Read;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.userOperations(request).subscribe((data: UserOperationsResult) => {

      if (data.message.ok === true && data.items?.length > 0) {
        this.currentUser = data.items[0];
        this.getShips();
      }
      else {
        console.error("message", data.message);
      }
    }, (err) => this.ifError(err));
  }

  getShips(){
    let request: ShipOperationsRequest = new ShipOperationsRequest();
    request.action = DataOperation.List;
    request.item = {} as Ship;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.shipOperations(request).subscribe((data) => {

      if (data.message.ok === true) {
        this.allShips = data.items;
        this.shipsFetched = true;
        this.onFilterStringChange();
        this.getAgencies();
      }
      else {
        console.error("message", data.message);
      }
    }, (err) => this.ifError(err));
  }

  getAgencies(){
    let request: AgentShipOperationsRequest = new AgentShipOperationsRequest;
    request.item = {} as AgentShip;
    request.item.agentId = this.currentUser.id;
    request.item.id = "";
    request.action = DataOperation.ListByAgent;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }

    this._dataService.agentShipOperations(request).subscribe((data:AgentShipOperationsResult) => {
      if (data.message.ok === true) {
        if(data.items?.length > 0){
          this.currentAgents = data.items;
          this.getCurrentAgentShips(this.allShips, this.currentAgents);
        }else{
          this.currentAgents = [];
          this.currentShipAgencies = [];
        }
      }
      else {
        console.error("message", data.message);
        this.currentAgents = [];
      }
    }, (err) => this.ifError(err));
  }

  getCurrentAgentShips(ships: Ship[], agentShips: AgentShip[]){
    let agentShipKeys: string[] = [];
    this.currentShipAgencies = [];
    agentShips.forEach(item => agentShipKeys.push(item.shipId))
    ships.forEach(item => {
      if(agentShipKeys.includes(item.id)){
        this.currentShipAgencies.push(item);
      }
    });
    this.onFilterStringChange();
  }

  actionItem(items: Ship[], action: string){
    switch(action.toLowerCase()){
      case "insert":
        let multiRequest: AgentShipOperationsRequest[] = [];
        items.forEach(item => {
          let request: AgentShipOperationsRequest = new AgentShipOperationsRequest();
          request.action = DataOperation.Insert;
          if (this._auth.currentUser) {
            request.key = this._auth.currentUser.id;
          }
          request.item = {} as AgentShip;
          request.item.agentId =  this.currentUser.id;
          request.item.shipId = item.id;
          request.item.id = "";
          multiRequest.push(request);
          console.log(multiRequest);
          this.multiAction(multiRequest);
        });
        break;
      default:
        break;
    }
  }

  multiAction(requests: AgentShipOperationsRequest[]){
    let index: number = 0;
    requests.forEach(request => {
      this._dataService.agentShipOperations(request).subscribe((data: AgentShipOperationsResult) => {
        index += 1;
        if (data.message.ok === true) {
          if (index === requests.length) {
            this.getShips();
            this.selectedShips = [];
            if(requests.length > 1){
              this._toastr.success("Bætti " + this.currentUser.name + " á " + requests.length.toString() + " skip");
            }else{
              this._toastr.success("Bætti " + this.currentUser.name + " á " + data.items[0]?.shipName);
            }
          }
        }
        else {
          console.error("message", data.message);
        }
      }, (err) => this.ifError(err));
    })
  }

  getCreateAgencyText(size: number){
    if(size == 1){
      return "Stofna heimild fyrir " + this.currentUser.name + " á " + this.selectedShips[0]?.name;
    }else if(size > 1){
      return "Stofna heimild fyrir " + this.currentUser.name + " á " + size.toString() + " skip";
    }
  }

  shipSelected(item: Ship){
    return this.selectedShips.includes(item);
  }

  shipAction(item: Ship, action: string){
    switch(action.toLowerCase()){
      case "add":
        this.selectedShips.push(item);
        this.onFilterStringChange();
        break;
      case "remove":
        const index = this.selectedShips.indexOf(item, 0);
        if (index > -1) {
          this.selectedShips.splice(index, 1);
        }
        this.onFilterStringChange();
        break;
      default:
        break;
    }
  }

  onFilterStringChange(){
    let filteredItems = this.allShips;
    switch(this.showActive.toLowerCase()){
      case "all":
        break;
      case "active":
        filteredItems = filteredItems.filter(item => item.active == true);
        break;
      case "inactive":
        filteredItems = filteredItems.filter(item => item.active == false);
        break;
    }
    filteredItems = filteredItems.filter(item => !this.selectedShips.includes(item));
    filteredItems = filteredItems.filter(item => !this.currentShipAgencies.includes(item));
    this.filteredItems = filteredItems;
  }

  deleteAgency(ship: Ship){
    let agentShip = {} as AgentShip;
    agentShip = this.currentAgents.filter(item => item.shipId == ship.id)[0];
    let request: AgentShipOperationsRequest = new AgentShipOperationsRequest();
    request.action = DataOperation.Delete;
    request.item = agentShip;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
      this._dataService.agentShipOperations(request).subscribe((data) => {
        if (data.message.ok === true) {
          this._toastr.success("Fjarlægði " + agentShip.agentName + " af " + ship.name);
          this.getShips();
        }
        else {
          this._toastr.error("Ekki tókst að fjarlægja " + this.currentUser.name + " af " + ship.name);
          console.error("message", data.message);
        }
      }, (err) => this.ifError(err));
  }

  ifError(err: any) {
    //not implemented
    console.log(err);

    return this._errorHandler.handleError(err);
  };

}

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { AgentComponent } from './admin/agent/agent.component';
import { AdminComponent } from './admin/admin.component';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UsersComponent } from './admin/users/users.component';
import { LogInComponent } from './auth/log-in/log-in.component';
import { LogOutComponent } from './auth/log-out/log-out.component';
import { JwtInterceptor } from './helpers/jwt-interceptor';
import { DataService } from './services/data.service';
import { PropertiesStoreService } from './services/properties-store.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { ShipsComponent } from './admin/ships/ships.component';
import { UserDialogComponent } from './admin/users/user-dialog/user-dialog.component';
import { AddUsersToShipComponent } from './admin/ships/add-users-to-ship/add-users-to-ship.component';
import { AddShipToUserComponent } from './admin/agent/add-ship-to-user/add-ship-to-user.component';
import { SumsComponent } from './admin/sums/sums.component';
import { ShipSumsComponent } from './admin/ships/ship-sums/ship-sums.component';
import { ToastrModule } from 'ngx-toastr';
import { DetailRowService, GridModule, PageService, ResizeService, SortService } from '@syncfusion/ej2-angular-grids';
import { LandingSumReportsComponent } from './admin/reports/landing-sum-reports/landing-sum-reports.component';
import { CategoryService, ChartModule, CrosshairService, DataLabelService, DateTimeCategoryService, DateTimeService, LegendService, LineSeriesService, TooltipService } from '@syncfusion/ej2-angular-charts';
import { registerLocaleData } from '@angular/common';
import localeIs from '@angular/common/locales/is';
import { CustomersComponent } from './admin/customers/customers.component';
import { CustomerDialogComponent } from './admin/customers/customer-dialog/customer-dialog.component';
import { AddCustomerToShipComponent } from './admin/ships/add-customer-to-ship/add-customer-to-ship.component';
import { ShipMasterComponent } from './admin/ship-master/ship-master.component';
import { MasterShipDialogComponent } from './admin/ship-master/master-ship-dialog/master-ship-dialog.component';
import { AddShipToMasterShipComponent } from './admin/ship-master/add-ship-to-master-ship/add-ship-to-master-ship.component';
import { AddUsersToMasterShipComponent } from './admin/ship-master/add-users-to-master-ship/add-users-to-master-ship.component';
import { PrimeNgModule } from './shared/prime-ng/prime-ng.module';
import { ProcessRecordsComponent } from './admin/process-records/process-records.component';
import { ProcessRecordsVinnslaComponent } from './admin/process-records/process-records-vinnsla/process-records-vinnsla.component';
import { LostPasswordComponent } from './auth/lost-password/lost-password.component';
import { FishSpeciesComponent } from './admin/fish-species/fish-species.component';
import { FishSpeciesDialogComponent } from './admin/fish-species/fish-species-dialog/fish-species-dialog.component';

registerLocaleData(localeIs, 'is');


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    AgentComponent,
    AdminComponent,
    UsersComponent,
    LogInComponent,
    NavMenuComponent,
    LogOutComponent,
    ShipsComponent,
    UserDialogComponent,
    AddUsersToShipComponent,
    AddShipToUserComponent,
    ShipSumsComponent,
    SumsComponent,
    LandingSumReportsComponent,
    CustomersComponent,
    CustomerDialogComponent,
    AddCustomerToShipComponent,
    ShipMasterComponent,
    MasterShipDialogComponent,
    AddShipToMasterShipComponent,
    AddUsersToMasterShipComponent,
    ProcessRecordsComponent,
    ProcessRecordsVinnslaComponent,
    LostPasswordComponent,
    FishSpeciesComponent,
    FishSpeciesDialogComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    GridModule,
    ChartModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
    { path: '', component: HomeComponent, pathMatch: 'full' },
    { path: 'counter', component: CounterComponent },
    { path: 'fetch-data', component: FetchDataComponent },
], {}),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    PrimeNgModule
  ],
  providers: [
    DataService,
    PropertiesStoreService,
    PageService,
    SortService,
    ResizeService,
    DetailRowService,
    LineSeriesService,
    CategoryService,
    LegendService, 
    TooltipService, 
    CrosshairService,
    DataLabelService, 
    DateTimeService,
    DateTimeCategoryService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'is-IS' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

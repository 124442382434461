import { Component, OnInit, ViewChild, computed, signal } from '@angular/core';
import { GridComponent, GridModel } from '@syncfusion/ej2-angular-grids';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { LandingSum } from 'src/app/shared/models';
import { AdminService } from '../admin.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sums',
  templateUrl: './sums.component.html',
  styleUrls: ['./sums.component.css']
})
export class SumsComponent implements OnInit {
  @ViewChild('grid') public grid: GridComponent;

  initialGridLoad: boolean = true;

  isLoadingItems: boolean = false;
  filterString: string = "";
  dateFrom: Date = new Date();
  dateTo: Date = new Date();
  timeout: any = null;
  isTyping: boolean = false;

  public childGrid: GridModel = {
    queryString: 'id',
    columns: [
        { field: 'framleit_Lysi', headerText: 'Lýsi framleitt', textAlign: 'Right', width: 120 },
        { field: 'raforkunotkun', headerText: 'Raforkunotkun', width: 150 },
        { field: 'oliunotkun', headerText: 'Olíunotkun', width: 150 },
        { field: 'vatnsnotkun_Kaltvatn', headerText: 'Kalt vatn', width: 150 },
        { field: 'vatnsnotkun_Heitttvatn', headerText: 'Heitt vatn', width: 150 },
        { field: 'veidarfaeri', headerText: 'Heitt vatn', width: 150 },
        { field: 'magn_Mjol', headerText: 'Mjöl', width: 150 },
        { field: 'vatn_Londun', headerText: 'Löndun vatn', width: 150 },
        { field: 'farmnumer_HB', headerText: 'Farmnúmer HB', width: 150 },
    ],
  };
  pageSettings: object;

  allLandingSumsSignal = this._adminService.allLandingSumsSignal;
  filteredLandingSumsSignal = computed(() => {
    return this.filterLandingSums(this.allLandingSumsSignal(), this.filterStringSignal(), this.dateFromSignal(), this.dateToSignal());
  });

  filterStringSignal = signal<string>("");
  dateFromSignal = signal<Date>(new Date(new Date().getDate() - 365));
  dateToSignal = signal<Date>(new Date());

  constructor(
    private _errorHandler: ErrorHandlerService,
    private _adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.dateFrom.setDate(this.dateTo.getDate() -365);
    this.pageSettings = { pageSizes: [10,25,50,100,200], pageSize: 25  };
    this._adminService.getLandingSumsByAgencies({} as LandingSum);
  }

  filterLandingSums(landingSums: LandingSum[], filterString: string, dateFrom: Date, dateTo: Date){
    if(landingSums?.length > 0){
      let filteredLandingSums = landingSums;
      if(filterString.length > 0){
        filteredLandingSums = this.filterByString(filteredLandingSums, filterString);
      }
      if(dateFrom != null && dateTo != null){
        filteredLandingSums = this.filterByDate(filteredLandingSums, dateFrom, dateTo);
      }
      return filteredLandingSums;
    }else{
      return [];
    }
  }

  filterByString(landingSums: LandingSum[], filterString: string){
    return landingSums.filter((value) => {
      return (value.weighterName.toLowerCase().includes(filterString)
      || value.fishSpeciesName.toLowerCase().includes(filterString)
      || value.shipName.toLowerCase().includes(filterString));
    });
  }

  filterByDate(landingSums: LandingSum[], dateFrom: Date, dateTo: Date){
    return landingSums.filter((value) => {
      return (new Date(value.dagur) >= new Date(dateFrom)
      && new Date(value.dagur) <= new Date(dateTo));
    });
  }

  onFilterStringChange(event: string){
    if(event.length > 1){
      this.filterStringSignal.set(event.toLowerCase());
    }else{
      this.filterStringSignal.set("");
    }
  }

  onDateFromChange(event: Date){
    this.dateFromSignal.set(new Date(event));
  }

  onDateToChange(event: Date){
    this.dateToSignal.set(new Date(event));
  }

  dataBound() {
    if (this.initialGridLoad) {
        this.initialGridLoad = false;
        const pager = document.getElementsByClassName('e-gridpager');
        let topElement;
        if (this.grid.allowGrouping || this.grid.toolbar) {
            topElement = this.grid.allowGrouping ? document.getElementsByClassName('e-groupdroparea') :
                document.getElementsByClassName('e-toolbar');
        } else {
            topElement = document.getElementsByClassName('e-gridheader');
        }
        this.grid.element.insertBefore(pager[0], topElement[0]);
    }
}

  ifError(err: any) {
    //not implemented
    console.log(err);

    this.isLoadingItems = false;

    return this._errorHandler.handleError(err);
  };

}

<h4 *ngIf="vinnslaid">V_Lota: {{vinnslaid}}</h4>
<h4 *ngIf="dagur">Dagsskýrsla: {{dagurString}}</h4>

<ng-container
    [ngTemplateOutlet]="stats"
    [ngTemplateOutletContext]="{processStats: processingRecordsStatsSignal()}"
></ng-container>

<div class="row">
    <div [ngClass]="isMobileSignal() ? 'p-fluid' : 'col-md-6'">
        <p-selectButton [options]="viewOptions" [(ngModel)]="selectedView" (ngModelChange)="onSelectedViewChange($event)" optionLabel="label" optionValue="value" [allowEmpty]="false"></p-selectButton>
    </div>
    <div [ngClass]="isMobileSignal() ? 'p-fluid' : 'col-md-6'" style="float: right;">
        <p-selectButton [options]="typeOptions" [(ngModel)]="selectedType" (ngModelChange)="onTypeChange($event)" [allowEmpty]="false" optionLabel="label" optionValue="value"></p-selectButton>
    </div>
</div>

<ng-container
    [ngTemplateOutlet]="
        selectedView === heatViewEnum 
        ? heatView 
        : selectedView === NiRViewEnum 
            ? NiRView
            : selectedView === waterUsageViewEnum
                ? waterUsageView
                : selectedView === energyViewEnum
                    ? energyView
                : noView"
    [ngTemplateOutletContext]="{ selectedGraphType: selectedTypeSignal() }"
></ng-container>

<ng-template #heatView>
    <ng-container
    [ngTemplateOutlet]="graphs"
    [ngTemplateOutletContext]="{graphData: heatGraphData(), options: heatOptions, graphTitle: ''}"
    ></ng-container>
</ng-template>

<ng-template #NiRView>
    <ng-container
        [ngTemplateOutlet]="graphs"
        [ngTemplateOutletContext]="{graphData: nirGraphData(), options: nirOptions, graphTitle: ''}"
    ></ng-container>
</ng-template>

<ng-template #waterUsageView>
    <ng-container
        [ngTemplateOutlet]="graphs"
        [ngTemplateOutletContext]="{graphData: waterUsagePerHourGraphData(), options: vatnOptions, graphTitle: 'Ferskvatnsnotkun'}"
    ></ng-container>
    <ng-container
        [ngTemplateOutlet]="graphs"
        [ngTemplateOutletContext]="{graphData: mjolPerHourGraphData(), options: mjolOptions, graphTitle: 'Mjöl'}"
    ></ng-container>
    <ng-container
        [ngTemplateOutlet]="graphs"
        [ngTemplateOutletContext]="{graphData: hraefniGraphData(), options: hraefniOptions, graphTitle: 'Hráefni'}"
    ></ng-container>
    <ng-container
        [ngTemplateOutlet]="graphs"
        [ngTemplateOutletContext]="{graphData: hraefniTankarGraphData(), options: hraefniTankarOptions, graphTitle: 'Hráefni tankar'}"
    ></ng-container>
    <ng-container
        [ngTemplateOutlet]="graphs"
        [ngTemplateOutletContext]="{graphData: thraarvornPerHourGraphData(), options: thraarvornOptions, graphTitle: 'Þráarvarnarefni'}"
    ></ng-container>
</ng-template>

<ng-template #energyView>
    <ng-container
        [ngTemplateOutlet]="graphs"
        [ngTemplateOutletContext]="{graphData: oliaPerHourGraphData(), options: oliaOptions, graphTitle: 'Olía'}"
    ></ng-container>
    <ng-container
        [ngTemplateOutlet]="graphs"
        [ngTemplateOutletContext]="{graphData: rafmagnEiginPerHourGraphData(), options: rafmagnEiginOptions, graphTitle: 'Raforka eigin'}"
    ></ng-container>
    <ng-container
        [ngTemplateOutlet]="graphs"
        [ngTemplateOutletContext]="{graphData: rafmagnForgangurPerHourGraphData(), options: rafmagnForgangurOptions, graphTitle: 'Raforka forgangur'}"
    ></ng-container>
    <ng-container
        [ngTemplateOutlet]="graphs"
        [ngTemplateOutletContext]="{graphData: rafmagnUmframPerHourGraphData(), options: rafmagnUmframOptions, graphTitle: 'Raforka umfram'}"
    ></ng-container>
</ng-template>

<ng-template #noView>
    <h4>Ekkert valið</h4>
</ng-template>

<ng-template #stats  let-processStats="processStats">
    <div class="row" style="margin-bottom: 20px;">
        <div [ngClass]="isMobileSignal() ? 'p-fluid' : 'col-md-4'" >
            <ng-container
                [ngTemplateOutlet]="heatData"
                [ngTemplateOutletContext]="{processStats: processingRecordsStatsSignal()}"
            ></ng-container>
        </div>
        <div [ngClass]="isMobileSignal() ? 'p-fluid' : 'col-md-4'">
            <ng-container
                [ngTemplateOutlet]="hraefni"
                [ngTemplateOutletContext]="{processStats: processingRecordsStatsSignal()}"
            ></ng-container>
        </div>
        <div [ngClass]="isMobileSignal() ? 'p-fluid' : 'col-md-4'">
            <ng-container
                [ngTemplateOutlet]="energy"
                [ngTemplateOutletContext]="{processStats: processingRecordsStatsSignal()}"
            ></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #energy let-processStats="processStats">
    <p-card header="Orka" styleClass="minimum-height">
        <table class="col-md-12">
            <tr>
                <th>Nafn</th>
                <th>Gildi</th>
            </tr>
            <tr>
                <td>Raforka forgangur(MWst)</td>
                <td>{{rafmagnForgangurTotalSignal() | number: '1.1-1'}}</td>
            </tr>
            <tr>
                <td>Raforka eigin framleiðsla(MWst)</td>
                <td>{{rafmagnEiginTotalSignal() | number: '1.1-1'}}</td>
            </tr>
            <tr>
                <td>Raforka umfram(MWst)</td>
                <td>{{rafmagnUmframTotalSignal() | number: '1.1-1'}}</td>
            </tr>
            <tr>
                <td>Olía(rúmmetrar)</td>
                <td>{{oliaTotalSignal() | number: '1.1-1'}}</td>
            </tr>
            <tr height="26px"></tr>
            <tr height="26px" *ngIf="activeView === vinnsluLotaViewEnum"></tr>
        </table>
    </p-card>
</ng-template>

<ng-template #hraefni let-processStats="processStats">
    <p-card header="Hráefni" styleClass="minimum-height">
        <table class="col-md-12">
            <tr>
                <th>Nafn</th>
                <th>Gildi</th>
            </tr>
            <tr>
                <td>Hráefni inn(Tonn)</td>
                <td>{{ingredientLastValueSignal() | number: '1.1-1'}}</td>
            </tr>
            <!-- <tr>
                <td>Blóðvatn inn</td>
                <td>{{cadaverineTotalSignal() | number: '1.1-1'}}</td>
            </tr> -->
            <tr>
                <td>Vatnsnotkun (rúmmetrar)</td>
                <td>{{vatnsnotkunTotalSignal() | number: '1.1-1'}}</td>
            </tr>
            <tr>
                <td>Þráarvarnarefni(ppm)</td>
                <td>{{thraarvornTotalSignal() | number: '1.1-1'}}</td>
            </tr>
            <tr>
                <td>Mjöl(Tonn)</td>
                <td>{{mjolTotalSignal() | number: '1.1-1'}}</td>
            </tr>
            <tr *ngIf="activeView === vinnsluLotaViewEnum">
                <td>Lýsi</td>
                <td>{{processStats.lysi | number: '1.1-1'}}</td>
            </tr>
            <tr height="26px"></tr>
        </table>
    </p-card>
</ng-template>


<ng-template #heatData let-processStats="processStats">
    <p-card header="Hitagildi" styleClass="minimum-height">
        <table class="col-md-12">
                <tr>
                    <th>Heiti</th>
                    <th>Min</th>
                    <th>Max</th>
                    <th>Með</th>
                </tr>
                <ng-container
                    [ngTemplateOutlet]="heatRow"
                    [ngTemplateOutletContext]="{name: 'Haarslev þurrkari', min: processStats.haarslevMin, max: processStats.haarslevMax, avg: processStats.haarslevAvg}"
                ></ng-container>
                <ng-container
                    [ngTemplateOutlet]="heatRow"
                    [ngTemplateOutletContext]="{name: 'Sjóðaralína 1', min: processStats.lina1_Min_Hiti_Fra_Sjodurum, max: processStats.lina1_Max_Hiti_Fra_Sjodurum, avg: processStats.lina1_Avg_Hiti_Fra_Sjodurum}"
                ></ng-container>
                <ng-container
                    [ngTemplateOutlet]="heatRow"
                    [ngTemplateOutletContext]="{name: 'Sjóðaralína 2', min: processStats.lina2_Min_Hiti_Fra_Sjodurum, max: processStats.lina2_Max_Hiti_Fra_Sjodurum, avg: processStats.lina2_Avg_Hiti_Fra_Sjodurum}"
                ></ng-container>
                <ng-container
                    [ngTemplateOutlet]="heatRow"
                    [ngTemplateOutletContext]="{name: 'Skrúfusjóðari', min: processStats.min_Hiti_Fra_Skrufusjoddara, max: processStats.max_Hiti_Fra_Skrufusjoddara, avg: processStats.avg_Hiti_Fra_Skrufusjoddara}"
                ></ng-container>
                <tr height="26px"></tr>
                <tr height="26px" *ngIf="activeView === vinnsluLotaViewEnum"></tr>
        </table>
    </p-card>
</ng-template>


<ng-template #heatRow let-name="name" let-min="min" let-max="max" let-avg="avg">
    <tr>
        <td>{{name}}</td>
        <td>{{min | number: '1.1-1'}}</td>
        <td>{{max | number: '1.1-1'}}</td>
        <td>{{avg | number: '1.1-1'}}</td>
    </tr>
</ng-template>

<ng-template #graphs let-graphData="graphData" let-options="options" let-graphTitle="graphTitle">
    <h4 *ngIf="graphTitle?.length > 0" style="margin-top: 20px; color: #215b9e; font-weight: bold;">{{graphTitle}}</h4>
    <div class="row">
        <div class="col-md-12">
            <p-chart [data]="graphData" [options]="options"></p-chart>
        </div>
    </div>
</ng-template>


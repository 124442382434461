import { Component, ViewChild, signal } from '@angular/core';
import { DialogActionEnum } from 'src/app/shared/enums';
import { FishSpecies } from 'src/app/shared/models';
import { FishSpeciesDialogComponent } from './fish-species-dialog/fish-species-dialog.component';
import { AdminService } from '../admin.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-fish-species',
  templateUrl: './fish-species.component.html',
  styleUrls: ['./fish-species.component.css']
})
export class FishSpeciesComponent {
  pageSettings: object;
  initialGridLoad: boolean = true;
  @ViewChild('grid') public grid: GridComponent;
  filterString: string = "";

  allFishSpeciesSignal = this._adminService.allFishSpeciesSignal;

  filterStringSignal = signal<string>("");

  //Dialog
  ref: DynamicDialogRef | undefined;

  //Action to perform
  actionMode: DialogActionEnum;
  insertAction: DialogActionEnum = DialogActionEnum.Insert;
  updateAction: DialogActionEnum = DialogActionEnum.Update;
  deleteAction: DialogActionEnum = DialogActionEnum.Delete;
  viewAction: DialogActionEnum = DialogActionEnum.View;

  constructor(
    private _adminService: AdminService,
    private _errorHandler: ErrorHandlerService,
    public dialogService: DialogService,

  ) { }

  ngOnInit(): void {
    this.pageSettings = { pageSizes: [10,25,50,100,200], pageSize: 25  };
    this._adminService.getAllFishSpecies({} as FishSpecies);
  }

  setActive(item: FishSpecies, value: boolean){
    item.isActive = value;
    this._adminService.fishSpeciesSetActive(item);
  }

  filterCustomers(items: FishSpecies[], filterString: string){
    return items.filter(item => {
      return item.name.toLowerCase().includes(filterString)
    });
  }

  actionItem(item: FishSpecies, action: DialogActionEnum){
    console.log(item, action);
    this.ref = this.dialogService.open(FishSpeciesDialogComponent, {
      width: '762px',
      header: this.getHeaderText(action, item?.name),
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: { action: action, editRow: item }
    });

    this.ref.onClose.subscribe((fishSpecies: FishSpecies) => {
      if (fishSpecies) {
        console.log("closed");
        this._adminService.getAllFishSpecies({} as FishSpecies);
      }
    });
  }

  dataBound() {
    if (this.initialGridLoad) {
        this.initialGridLoad = false;
        const pager = document.getElementsByClassName('e-gridpager');
        let topElement;
        if (this.grid.allowGrouping || this.grid.toolbar) {
            topElement = this.grid.allowGrouping ? document.getElementsByClassName('e-groupdroparea') :
                document.getElementsByClassName('e-toolbar');
        } else {
            topElement = document.getElementsByClassName('e-gridheader');
        }
        this.grid.element.insertBefore(pager[0], topElement[0]);
    }
  }

  onFilterStringChange(){
    if(this.filterString.length > 1){
      this.filterStringSignal.set(this.filterString);
    }else{
      this.filterStringSignal.set("");
    }
  }

  getHeaderText(action: DialogActionEnum, name: string){
    switch(action){
      case DialogActionEnum.Insert:
        return "Stofna viðskiptavin";
      case DialogActionEnum.Update:
        return "Uppfæra " + name;
      case DialogActionEnum.Delete:
        return "Eyða " + name;
      case DialogActionEnum.View:
        return "Skoða " + name;
      default:
        return ""
    }
  }

  ifError(err: any) {
    //not implemented
    console.log(err);
    return this._errorHandler.handleError(err);
  };
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DetailRowService, GridComponent, GridModel } from '@syncfusion/ej2-angular-grids';
import { ToastrService } from 'ngx-toastr';
import { AuthStoreService } from 'src/app/services/auth-store.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { DataOperation } from 'src/app/shared/enums';
import { LandingSum, MasterShip, Ship } from 'src/app/shared/models';
import { LandingSumOperationsRequest, MasterShipOperationsRequest, ShipOperationsRequest } from 'src/app/shared/request-models';

@Component({
  selector: 'app-ship-sums',
  templateUrl: './ship-sums.component.html',
  styleUrls: ['./ship-sums.component.css']
})
export class ShipSumsComponent implements OnInit {
  @ViewChild('grid') public grid: GridComponent;

  slug: string = "";
  currentShip: MasterShip = {} as MasterShip;

  allLandingSums: LandingSum[] = [];

  isLoadingItems: boolean = false;
  public childGrid: GridModel = {
    queryString: 'id',
    columns: [
        { field: 'framleit_Lysi', headerText: 'Lýsi framleitt', textAlign: 'Right', width: 120 },
        { field: 'raforkunotkun', headerText: 'Raforkunotkun', width: 150 },
        { field: 'oliunotkun', headerText: 'Olíunotkun', width: 150 },
        { field: 'vatnsnotkun_Kaltvatn', headerText: 'Kalt vatn', width: 150 },
        { field: 'vatnsnotkun_Heitttvatn', headerText: 'Heitt vatn', width: 150 },
        { field: 'veidarfaeri', headerText: 'Heitt vatn', width: 150 },
        { field: 'magn_Mjol', headerText: 'Mjöl', width: 150 },
        { field: 'vatn_Londun', headerText: 'Löndun vatn', width: 150 },
        { field: 'farmnumer_HB', headerText: 'Farmnúmer HB', width: 150 },
    ],
};
pageSettings: object;


  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _auth: AuthStoreService,
    private _dataService: DataService,
    private _errorHandler: ErrorHandlerService,
    private _toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.pageSettings = { pageSizes: [10,25,50,100,200], pageSize: 25  };
    try {
      this.slug = this._route.snapshot.paramMap.get('slug');
      this.getItem();
    }
    catch (e) {
      console.error(e);
      this._router.navigate(["/"]);
    }
    console.log(this._auth.currentUser);
  }

  getItem(){
    let request: MasterShipOperationsRequest = new MasterShipOperationsRequest();
    request.action = DataOperation.Read;
    request.item = {} as MasterShip;
    request.item.id = this.slug;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }

    this._dataService.masterShipOperations(request).subscribe((data) => {
      console.log(data.items);
      if (data.message.ok === true) {
        console.log(data.items);
        this.currentShip = data.items[0];
        this.getItems();
      }
      else {
        console.error("message", data.message);
      }
    }, (err) => this.ifError(err));
  }

  getItems(){
    this.isLoadingItems = true;
    let request: LandingSumOperationsRequest = new LandingSumOperationsRequest();
    request.action = DataOperation.ListByMasterShip;
    request.item = {} as LandingSum;
    request.item.masterShipId = this.slug;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    
    this._dataService.landingSumOperations(request).subscribe((data) => {
      console.log(data.items);
      if (data.message.ok === true) {
        console.log(data.items);
        this.allLandingSums = data.items;
        // this.grid.childGrid.dataSource = data.items;
        this.isLoadingItems = false;
      }
      else {
        this.isLoadingItems = false;
        console.error("message", data.message);
      }
    }, (err) => this.ifError(err));
  }

  ifError(err: any) {
    //not implemented
    console.log(err);

    this.isLoadingItems = false;

    return this._errorHandler.handleError(err);
  };

}

<div class="row">
    <div class="row login-form-holder">
        <div class="login-form-container">
            <h4 class="login-title">ÚTSKRÁNING</h4>
            <div class="form-group logout-button-holder">
                <button type="button" class="btn btn-primary mb-2 login-button" (click)="logout()">Útskráning</button>
            </div>
            <div class="form-group logout-button-holder">
                <button type="button" class="btn btn-primary mb-2 lost-password-button" (click)="cancel()">Hætta
                    við</button>
            </div>
        </div>
    </div>
</div>
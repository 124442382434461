import { Injectable } from '@angular/core';
import { PrimeNgGraphDataSet } from '../shared/models';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  constructor() { }

  //
  generateGraphData(dataSetData: number[][], labels: string[], dataSetLabels: string[], colors: string[], graphType: string, postFix: string){
    return {
        labels: labels,
        datasets: this.generateGraphDataSet(dataSetData, dataSetLabels, colors, graphType, postFix)
    }
  }

  generateStackedGraphData(dataSetData: number[][], labels: string[], dataSetLabels: string[], colors: string[], postFix: string, graphType: string = "bar"){
    return {
        labels: labels,
        datasets: this.generateStackedGraphDataSet(dataSetData, dataSetLabels, colors, graphType, postFix)
    }
  }

  generateGraphDataSet(
        dataSetData: number[][], 
        dataSetLabels: string[], 
        colors: string[], 
        graphType: string,
        postFix: string
    ): PrimeNgGraphDataSet[]{
      console.log("dataSetLabels", dataSetLabels);
    let dataSets = [];
    
    for(let i = 0; i < dataSetData.length; i++){
      if(dataSetData[i].length > 0){
        dataSets.push({
          label: dataSetLabels[i],
          data: dataSetData[i],
          fill: false,
          borderColor: colors[i],
          backgroundColor: colors[i],
          tension: 0.1,
          type: graphType
        });
      }
    }
    return dataSets;
  }

  generateStackedGraphDataSet(
    dataSetData: number[][], 
    dataSetLabels: string[], 
    colors: string[], 
    graphType: string,
    postFix: string
    ): PrimeNgGraphDataSet[]{
      console.log("dataSetLabels", dataSetLabels);
    let dataSets = [];

    for(let i = 0; i < dataSetData.length; i++){
      if(dataSetData[i].length > 0){
        dataSets.push({
          label: dataSetLabels[i],
          data: dataSetData[i],
          fill: false,
          backgroundColor: colors[i],
          type: "bar"
        });
      }
    }
    return dataSets;
  }

  generateDonutGraphs(dataSetData: number[][], labels: string[], dataSetLabels: string[], colors: string[]){
    return this.generateDonutGraphDataSet(dataSetData, labels, dataSetLabels, colors);
  }

  generateDonutGraphDataSet(
    dataSetData: number[][], 
    labels: string[], 
    dataSetLabels: string[],
    colors: string[]
    ): PrimeNgGraphDataSet[]{
      console.log("dataSetLabels", labels);
    let dataSets = [];

    for(let i = 0; i < dataSetData.length; i++){
      if(dataSetData[i].length > 0){
        dataSets.push({
          label: labels[i],
          data: dataSetData[i],
          fill: false,
          backgroundColor: colors[i],
          type: "doughnut"
        });
      }
    }
    return dataSets;
  }

  generateOptions(stacked: boolean, postFix: string, suggestedMin: number = null, suggestedMax: number = null){
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    return {
      
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
          legend: {
              labels: {
                  color: textColor
              }
          },
      },
      scales: {
          x: {
              stacked: stacked,
              ticks: {
                  color: textColorSecondary,
              },
              grid: {
                  color: surfaceBorder,
                  drawBorder: false
              }
          },
          y: {
              stacked: stacked,
              ticks: {
                  color: textColorSecondary,
                  callback: value => `${value}${postFix}`
              },
              grid: {
                  color: surfaceBorder,
                  drawBorder: false
              },
              suggestedMax: suggestedMax,
              suggestedMin: suggestedMin
          }
      }
    };
  }

  generateResponsiveOptions(stacked: boolean, postFix: string, suggestedMin: number = null, suggestedMax: number = null){
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    return {
      maintainAspectRatio: false,
      height: 300, // Set the height of the chart
      width: 500, // Set the width of the chart
      plugins: {
          legend: {
              labels: {
                  color: textColor
              }
          },
      },
      scales: {
          x: {
              stacked: stacked,
              ticks: {
                  color: textColorSecondary,
              },
              grid: {
                  color: surfaceBorder,
                  drawBorder: false
              }
          },
          y: {
              stacked: stacked,
              ticks: {
                  color: textColorSecondary,
                  callback: value => `${value}${postFix}`
              },
              grid: {
                  color: surfaceBorder,
                  drawBorder: false
              },
              suggestedMax: suggestedMax,
              suggestedMin: suggestedMin
          }
      }
    };
  }
}

<div class="p-dialog-content">
    <div class="grid p-fluid mt-3">
        <ng-container
            [ngTemplateOutlet]="actionMode === setPasswordAction ? setPasswordTemplate : defaultTemplate"
            [ngTemplateOutletContext]="{editRow: editRow}"
        ></ng-container>
    </div>
</div>

<ng-template #setPasswordTemplate let-editRow="editRow">
    <div class="field col-12 md:col-4 dialog-form-field">
        <span class="p-float-label">
          <input type="password" pInputText [(ngModel)]="password" placeholder="Lykilorð" style="width: 100%" />
          <label htmlFor="password">Lykilorð</label>
        </span>
      </div>
      <div class="field col-12 md:col-4 dialog-form-field">
        <span class="p-float-label">
          <input type="password" pInputText [(ngModel)]="confirmPassword" placeholder="Lykilorð aftur"
            style="width: 100%" />
          <label htmlFor="password2">Lykilorð aftur</label>
        </span>
      </div>
</ng-template>

<ng-template #defaultTemplate let-editRow="editRow">
    <div class="field col-12 md:col-4 dialog-form-field">
        <span class="p-float-label">
            <input pInputText id="ssn" [(ngModel)]="editRow.ssn" />
            <label htmlFor="ssn">Kennitala</label>
        </span>
    </div>
    <div class="field col-12 md:col-4 dialog-form-field">
        <span class="p-float-label">
            <input pInputText id="name" [(ngModel)]="editRow.name" />
            <label htmlFor="name">Nafn</label>
        </span>
    </div>
    <div class="field col-12 md:col-4 dialog-form-field">
        <span class="p-float-label">
            <input pInputText id="email" [(ngModel)]="editRow.email" />
            <label htmlFor="email">Netfang</label>
        </span>
    </div>
    <div class="field col-12 md:col-4 dialog-form-field">
        <span class="p-float-label">
            <input pInputText id="phone" [(ngModel)]="editRow.phone" />
            <label htmlFor="phone">Sími</label>
        </span>
    </div>
    <div class="field col-12 md:col-4 dialog-form-field">
        <p-checkbox binary="true" [(ngModel)]="editRow.admin" id="admin" label="Admin"></p-checkbox>
    </div>
    <div class="field col-12 md:col-4 dialog-form-field">
        <p-checkbox binary="true" [(ngModel)]="editRow.active" id="active" label="Virk/ur"></p-checkbox>
    </div>
</ng-template>


<div class="p-dialog-footer">
    <div class="row">
        <div class="col-md-9">
            <p-message *ngIf="validationErrorMessage.length > 0" severity="error" [text]="validationErrorMessage" styleClass="float-left"></p-message>
        </div>
        <div class="col-md-3">
            <button *ngIf="actionButtonLabel?.length > 0" pButton class="p-button-sm" (click)="action(editRow, actionMode)" [label]="actionButtonLabel" style="float: right;"></button>
        </div>
    </div>
</div>
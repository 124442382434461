<div>
  <div class="top-section">
    <h2 class="page-title">Landanir</h2>
  </div>
    <div class="d-flex">
      <div class="table-search-filter">
        <input pInputText class="table-search-input" placeholder="Leita að löndun (Skip, vigtarmaður, fisktegund)..." [(ngModel)]="filterString" (ngModelChange)='onFilterStringChange($event)'>
      </div>
      <div>
        <p-calendar [(ngModel)]="dateFrom" (ngModelChange)='onDateFromChange($event)' [showIcon]="true"></p-calendar>
        <p-calendar [(ngModel)]="dateTo" (ngModelChange)='onDateToChange($event)' [showIcon]="true"></p-calendar>
      </div>
  </div>

<div>
    <ejs-grid #grid [dataSource]='filteredLandingSumsSignal()'  [allowPaging]='true'  (dataBound)='dataBound()'
    [pageSettings]='pageSettings' [allowSorting]='true' [allowResizing]='true'>
        <e-columns>
            <e-column field='dagur' type="date" format="dd.MM.yyyy" headerText='Dagur' textAlign='Right' width=120></e-column>
            <e-column field='masterShipName' headerText='Skip' width=150></e-column>
            <e-column field='navCode' headerText='Veiðiferð' width=110></e-column>
            <e-column field='weighterName' headerText='Vigtarmaður' width=150></e-column>
            <e-column field='fishSpeciesName' headerText='Fisktegund' width=100></e-column>
            <e-column field='veidarfaeri' headerText='veiðarfæri' width=100></e-column>
            <e-column field='heildarmagn' headerText='Magn heild' width=100></e-column>
            <e-column field='magn_Unnid' headerText='Magn unnið' width=120></e-column>
            <e-column field='veidisvaedi' headerText='veiðsisvæði' width=150></e-column>
            <e-column headerText="Aðgerðir" width=150>
                <ng-template #template let-landingSum>
                    <i class="fal fa-hand-pointer fa-action-button" pTooltip="Skoða Löndunarskýrslu"
                    [routerLinkActive]="['link-active']" [routerLink]="['/londunarskyrslur/' + landingSum.cargoKey]"></i>
                </ng-template>
            </e-column>
        </e-columns>
    </ejs-grid>

</div>
</div>

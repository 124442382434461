import { Injectable, Signal, computed, signal } from '@angular/core';
import { AgentMasterShip, Customer, FishSpecies, GraphOffset, HourlyData, HourlySum, Landing, LandingGraph, LandingStats, LandingSum, MasterShip, ProcessingRecord, ProcessingRecordStats, User, VinnsluLota } from '../shared/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthStoreService } from '../services/auth-store.service';
import { DataService } from '../services/data.service';
import { ErrorHandlerService } from '../services/error-handler.service';
import { AgentMasterShipOperationsRequest, AgentMasterShipOperationsResult, CustomerOperationsRequest, CustomerOperationsResult, FishSpeciesOperationsRequest, FishSpeciesOperationsResult, GraphOffsetOperationsRequest, GraphOffsetOperationsResult, LandingGraphOperationsRequest, LandingGraphOperationsResult, LandingOperationsRequest, LandingOperationsResult, LandingStatsOperationsRequest, LandingStatsOperationsResult, LandingSumOperationsRequest, LandingSumOperationsResult, MasterShipOperationsRequest, MasterShipOperationsResult, ProcessingRecordOperationsRequest, ProcessingRecordOperationsResult, ProcessingRecordStatsOperationsRequest, ProcessingRecordStatsOperationsResult, UserOperationsRequest, UserOperationsResult, VinnsluLotaOperationsRequest, VinnsluLotaOperationsResult } from '../shared/request-models';
import { DataOperation } from '../shared/enums';
import { ToastrService } from 'ngx-toastr';
import { DefaultGraphCrosshair, DefaultGraphMarker, DefaultGraphTooltip, DefaultLandingSumXAxis, DefaultLandingSumXAxisHourly, DefaultLandingSumYAxis, DefaultLandingSumYAxisHourly } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private _auth: AuthStoreService,
    private _dataService: DataService,
    private _errorHandler: ErrorHandlerService,
    private _toastr: ToastrService,
  ) { }
  scrW = signal(0);


  isMobile: Signal<boolean> = computed(() => this.scrW() < 768);



  //MasterShips

  allMasterShipsSignal = signal<MasterShip[]>(null);

  private _selectedMasterShipForAgencySource: BehaviorSubject<MasterShip> = new BehaviorSubject<MasterShip>(null);
  private _selectedMasterShipForAgency$: Observable<MasterShip> = this._selectedMasterShipForAgencySource.asObservable();
  get selectedMasterShipForAgency$(): Observable<MasterShip> { return this._selectedMasterShipForAgency$ }
  get selectedMasterShipForAgency(): MasterShip { return this._selectedMasterShipForAgencySource.getValue()}
  set selectedMasterShipForAgency(newValue: MasterShip){
    this._selectedMasterShipForAgencySource.next(newValue);
  }

  private _currentMasterShipForLandingSumSource: BehaviorSubject<MasterShip> = new BehaviorSubject<MasterShip>(null);
  private _currentMasterShipForLandingSum$: Observable<MasterShip> = this._currentMasterShipForLandingSumSource.asObservable();
  get currentMasterShipForLandingSum$(): Observable<MasterShip> { return this._currentMasterShipForLandingSum$ }
  get currentMasterShipForLandingSum(): MasterShip { return this._currentMasterShipForLandingSumSource.getValue()}
  set currentMasterShipForLandingSum(newValue: MasterShip){
    this._currentMasterShipForLandingSumSource.next(newValue);
  }

  private _masterShipDialogOperationSuccessfullyDoneSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _masterShipDialogOperationSuccessfullyDone$: Observable<boolean> = this._masterShipDialogOperationSuccessfullyDoneSource.asObservable();
  get masterShipDialogOperationSuccessfullyDone$(): Observable<boolean> { return this._masterShipDialogOperationSuccessfullyDone$ }
  get masterShipDialogOperationSuccessfullyDone(): boolean { return this._masterShipDialogOperationSuccessfullyDoneSource.getValue()}
  set masterShipDialogOperationSuccessfullyDone(newValue: boolean){
    this._masterShipDialogOperationSuccessfullyDoneSource.next(newValue);
  }

  //MasterShip calls to operations

  getMasterShip(item: MasterShip, valueToUpdate: string){
    this.masterShipOperations(DataOperation.Read, item, valueToUpdate);
  }

  getAllMasterShips(){
    const item: MasterShip = {} as MasterShip;
    item.id = "";
    this.masterShipOperations(DataOperation.List, item);
  }

  addMasterShip(item: MasterShip){
    this.masterShipOperations(DataOperation.Insert, item);
  }

  updateMasterShip(item: MasterShip){
    this.masterShipOperations(DataOperation.Update, item);
  }

  removeMasterShip(item: MasterShip){
    this.masterShipOperations(DataOperation.Delete, item);
  }

  toggleMasterShipIsActive(item: MasterShip){
    this.masterShipOperations(DataOperation.SetActive, item);

  }

  //MasterShip Filters

  filterMasterShips(){

  }

  private masterShipOperations(action: DataOperation, item: MasterShip, valueToUpdate: string = null){
    let request: MasterShipOperationsRequest = new MasterShipOperationsRequest();
    request.action = action;
    request.item = item;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.masterShipOperations(request).subscribe({
      next: (data: MasterShipOperationsResult) => {
        console.log("masterShipOperations", data.items);
        if (data.message.ok === true) {
          if (action === DataOperation.List) {
            this.allMasterShipsSignal.set(data.items);
          } else if(action === DataOperation.Read){
            if(valueToUpdate === "currentmastershipforlandingsum"){
              data.items.length > 0 
                ? this.currentMasterShipForLandingSum = data.items[0]
                : this.showUserFailedMessage("Ekki tókst að sækja upplýsingar um skip");
            }
          } else if(action === DataOperation.SetActive){
            item.isActive
              ? this.showUserSuccessMessage(item.name + " hefur verið virkjað")
              : this.showUserSuccessMessage(item.name + " hefur verið afvirkjað")
            this.getAllMasterShips();
          } else if(action === DataOperation.Insert){
            this.showUserSuccessMessage(item.name + " hefur verið bætt við");
            this.masterShipDialogOperationSuccessfullyDone = true;
          } else if(action === DataOperation.Update){
            this.showUserSuccessMessage(item.name + " hefur verið uppfært");
            this.masterShipDialogOperationSuccessfullyDone = true;
          } else if(action === DataOperation.Delete){
            this.showUserSuccessMessage(item.name + " hefur verið fjarlægt");
            this.masterShipDialogOperationSuccessfullyDone = true;
          }
        } else {
          console.error('message', data?.message);
        }
      },
      error: (err) => this.ifError(err),
    });
  }

  //AgentMasterShips
  private _allAgentMasterShipsSource: BehaviorSubject<AgentMasterShip[]> = new BehaviorSubject<AgentMasterShip[]>(null);
  private _allAgentMasterShips$: Observable<AgentMasterShip[]> = this._allAgentMasterShipsSource.asObservable();
  get allAgentMasterShips$(): Observable<AgentMasterShip[]> { return this._allAgentMasterShips$ }
  get allAgentMasterShips(): AgentMasterShip[] { return this._allAgentMasterShipsSource.getValue()}
  set allAgentMasterShips(newValue: AgentMasterShip[]){
    this._allAgentMasterShipsSource.next(newValue);
  }


  private _filteredAgentMasterShipsSource: BehaviorSubject<AgentMasterShip[]> = new BehaviorSubject<AgentMasterShip[]>(null);
  private _filteredAgentMasterShips$: Observable<AgentMasterShip[]> = this._filteredAgentMasterShipsSource.asObservable();
  get filteredAgentMasterShips$(): Observable<AgentMasterShip[]> { return this._filteredAgentMasterShips$ }
  get filteredAgentMasterShips(): AgentMasterShip[] { return this._filteredAgentMasterShipsSource.getValue()}
  set filteredAgentMasterShips(newValue: AgentMasterShip[]){
    this._filteredAgentMasterShipsSource.next(newValue);
  }

  private _filteredAgentMasterShipsForAgencySource: BehaviorSubject<AgentMasterShip[]> = new BehaviorSubject<AgentMasterShip[]>(null);
  private _filteredAgentMasterShipsForAgency$: Observable<AgentMasterShip[]> = this._filteredAgentMasterShipsForAgencySource.asObservable();
  get filteredAgentMasterShipsForAgency$(): Observable<AgentMasterShip[]> { return this._filteredAgentMasterShipsForAgency$ }
  get filteredAgentMasterShipsForAgency(): AgentMasterShip[] { return this._filteredAgentMasterShipsForAgencySource.getValue()}
  set filteredAgentMasterShipsForAgency(newValue: AgentMasterShip[]){
    this._filteredAgentMasterShipsForAgencySource.next(newValue);
  }

  //Calls to AgentMasterShipOperations

  getAllAgentMasterShips(){
    const item: AgentMasterShip = {} as AgentMasterShip;
    item.id = "";
    this.agentMasterShipOperations(DataOperation.List, item);
  }

  getAgenciesByMasterShip(item: AgentMasterShip){
    this.agentMasterShipOperations(DataOperation.ListByMasterShip, item);

  }

  deleteAgency(item: AgentMasterShip){
    this.agentMasterShipOperations(DataOperation.Delete, item);
  }

  //agentMasterShipFilters - none implemented

  private agentMasterShipOperations(action: DataOperation, item: AgentMasterShip){
    let request: AgentMasterShipOperationsRequest = new AgentMasterShipOperationsRequest();
    request.action = action;
    request.item = item;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.agentMasterShipOperations(request).subscribe({
      next: (data: AgentMasterShipOperationsResult) => {
        console.log("agentMasterShipOperations", data.items);
        if (data.message.ok === true) {
          if (action === DataOperation.List) {
            this.allAgentMasterShips = data.items;
            this.filteredAgentMasterShips = data.items;
          } else if(action === DataOperation.Delete){
            this.getAllAgentMasterShips();
          }
        } else {
          console.error('message', data?.message);
        }
      },
      error: (err) => this.ifError(err),
    });
  }

  //Users

  private _allUsersSource: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);
  private _allUsers$: Observable<User[]> = this._allUsersSource.asObservable();
  get allUsers$(): Observable<User[]> { return this._allUsers$ }
  get allUsers(): User[] { return this._allUsersSource.getValue()}
  set allUsers(newValue: User[]){
    if(newValue != this.allUsers){
      this._allUsersSource.next(newValue);
      this.usersFilterStringForAgency?.length > 0 
      ? this.filterUsersForAgency(this.usersFilterStringForAgency)
      : this.filteredUsersForAgency = newValue;
    }
  }

  private _filteredUsersSource: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);
  private _filteredUsers$: Observable<User[]> = this._filteredUsersSource.asObservable();
  get filteredUsers$(): Observable<User[]> { return this._filteredUsers$ }
  get filteredUsers(): User[] { return this._filteredUsersSource.getValue()}
  set filteredUsers(newValue: User[]){
    this._filteredUsersSource.next(newValue);
  }

  private _filteredUsersForAgencySource: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);
  private _filteredUsersForAgency$: Observable<User[]> = this._filteredUsersForAgencySource.asObservable();
  get filteredUsersForAgency$(): Observable<User[]> { return this._filteredUsersForAgency$ }
  get filteredUsersForAgency(): User[] { return this._filteredUsersForAgencySource.getValue()}
  set filteredUsersForAgency(newValue: User[]){
    this._filteredUsersForAgencySource.next(newValue);
  }

  private _usersFilterStringSource: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _usersFilterString$: Observable<string> = this._usersFilterStringSource.asObservable();
  get usersFilterString$(): Observable<string> { return this._usersFilterString$ }
  get usersFilterString(): string { return this._usersFilterStringSource.getValue()}
  set usersFilterString(newValue: string){
    this._usersFilterStringSource.next(newValue);
    if(newValue?.length > 0){
      this.filterUsers(newValue);
    }else{
      this.filteredUsers = this.allUsers;
    }
  }

  private _usersFilterStringForAgencySource: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _usersFilterStringForAgency$: Observable<string> = this._usersFilterStringForAgencySource.asObservable();
  get usersFilterStringForAgency$(): Observable<string> { return this._usersFilterStringForAgency$ }
  get usersFilterStringForAgency(): string { return this._usersFilterStringForAgencySource.getValue()}
  set usersFilterStringForAgency(newValue: string){
    this._usersFilterStringForAgencySource.next(newValue);
    if(newValue?.length > 0){
      this.filterUsersForAgency(newValue);
    }else{
      this.filteredUsersForAgency = this.allUsers;
    }
  }

  private _selectedUserForAgencySource: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private _selectedUserForAgency$: Observable<User> = this._selectedUserForAgencySource.asObservable();
  get selectedUserForAgency$(): Observable<User> { return this._selectedUserForAgency$ }
  get selectedUserForAgency(): User { return this._selectedUserForAgencySource.getValue()}
  set selectedUserForAgency(newValue: User){
    this._selectedUserForAgencySource.next(newValue);
    this.filterSelectedUserAgencies(newValue);  
  }

  //Calls to userOperations

  getAllUsers(){
    const item: User = {} as User;
    item.id = "";
    this.userOperations(DataOperation.List, item);
  }

  //User Filters

  filterUsers(filterString: string){
    this.filteredUsers = this.allUsers.filter(item => {
      return item.name?.toLocaleLowerCase().includes(filterString)
      || item.ssn?.toLocaleLowerCase().replace("-","").includes(filterString.replace("-", ""))
      || item.city?.toLocaleLowerCase().includes(filterString)
      || item.postCode?.toString().includes(filterString)
      || item.address?.toString().toLocaleLowerCase().includes(filterString);
    });
  }

  filterUsersForAgency(filterString: string){
    this.filteredUsersForAgency = this.allUsers.filter(item => {
      return item.name?.toLocaleLowerCase().includes(filterString)
      || item.ssn?.toLocaleLowerCase().replace("-","").includes(filterString.replace("-", ""))
      || item.city?.toLocaleLowerCase().includes(filterString)
      || item.postCode?.toString().includes(filterString)
      || item.address?.toString().toLocaleLowerCase().includes(filterString);
    });
  }

  filterSelectedUserAgencies(item: User){
    this.filteredAgentMasterShipsForAgency = this.allAgentMasterShips.filter(masterShip => masterShip?.agentId === item?.id);
  }

  //User setters

  setSelectedUserForAgency(item: User){
    this.selectedUserForAgency = item;
  }

  addUser(item: User){
    this.userOperations(DataOperation.Insert, item);
  }

  updateUser(item: User){
    this.userOperations(DataOperation.Update, item);
  }

  deleteUser(item: User){
    this.userOperations(DataOperation.Delete, item);
  }

  setUserPassword(item: User){
    this.userOperations(DataOperation.SetPassword, item);
  }

  private userOperations(action: DataOperation, item: User){
    let request: UserOperationsRequest = new UserOperationsRequest();
    request.action = action;
    request.item = item;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.userOperations(request).subscribe({
      next: (data: UserOperationsResult) => {
        if (data.message.ok === true) {
          if (action == DataOperation.List) {
            this.allUsers = data.items;
            this.filteredUsers = data.items;
          } else if(action == DataOperation.SetPassword){
            this.showUserSuccessMessage("Uppfærði lykilorð fyrir " + item.name);
          }
        } else {
          console.error('message', data?.message);
        }
      },
      error: (err) => this.ifError(err),
    });
  }

  //Customers

  allCustomersSignal = signal<Customer[]>(null);

  private _customerOperationSuccessfullyDoneSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _customerOperationSuccessfullyDone$: Observable<boolean> = this._customerOperationSuccessfullyDoneSource.asObservable();
  get customerOperationSuccessfullyDone$(): Observable<boolean> { return this._customerOperationSuccessfullyDone$ }
  get customerOperationSuccessfullyDone(): boolean { return this._customerOperationSuccessfullyDoneSource.getValue()}
  set customerOperationSuccessfullyDone(newValue: boolean){
    this._customerOperationSuccessfullyDoneSource.next(newValue);
  }

  getAllCustomers(){
    const item: Customer = {} as Customer;
    item.id = "";
    this.customerOperations(DataOperation.List, item);
  }

  addCustomer(item: Customer){
    this.customerOperations(DataOperation.Insert, item);
  }

  updateCustomer(item: Customer){
    this.customerOperations(DataOperation.Update, item);
  }

  deleteCustomer(item: Customer){
    this.customerOperations(DataOperation.Delete, item);
  }



  private customerOperations(action: DataOperation, item: Customer){
    let request: CustomerOperationsRequest = new CustomerOperationsRequest();
    request.action = action;
    request.item = item;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.customerOperations(request).subscribe({
      next: (data: CustomerOperationsResult) => {
        if (data.message.ok === true) {
          if (action === DataOperation.List) {
            this.allCustomersSignal.set(data.items);
          } else if(action === DataOperation.Insert){
            this.showUserSuccessMessage("Bætti " + item.name + " við sem viðskiptavin");
            this.customerOperationSuccessfullyDone = true;
          } else if(action === DataOperation.Update){
            this.showUserSuccessMessage("Uppfærði " + item.name);
            this.customerOperationSuccessfullyDone = true;
          } else if(action === DataOperation.Delete){
            this.showUserSuccessMessage(item.name + " hefur verið eytt");
            this.customerOperationSuccessfullyDone = true;
          }
        } else {
          console.error('message', data?.message);
          switch(action){
            case DataOperation.List:
              const listErrorMessage: string = "Ekki tókst að sækja viðskiptavini";
              this.showUserFailedMessage(listErrorMessage);
              break;
            case DataOperation.Insert:
              const insertErrorMessage: string = "Ekki tókst að bæta " + item.name + " við sem viðskiptavini";
              this.showUserFailedMessage(insertErrorMessage);
              break;
            case DataOperation.Update:
              const updateErrorMessage: string = "Ekki tókst að uppfæra " + item.name;
              this.showUserFailedMessage(updateErrorMessage);
              break;
            case DataOperation.Delete:
              const deleteErrorMessage: string = "Ekki tókst að eyða " + item.name;
              this.showUserFailedMessage(deleteErrorMessage);
              break;
            default:
              break;
          }
        }
      },
      error: (err) => this.ifError(err),
    });
  }

  //Fish Species
  allFishSpeciesSignal = signal<FishSpecies[]>(null);
  allActiveFishSpeciesSignal = signal<FishSpecies[]>(null);

  getAllFishSpecies(item: FishSpecies){
    this.fishSpeciesOperations(DataOperation.List, item);
  }

  
  getActiveFishSpecies(item: FishSpecies){
    this.fishSpeciesOperations(DataOperation.ListActive, item);
  }
  
  fishSpeciesSetActive(item: FishSpecies){
    this.fishSpeciesOperations(DataOperation.SetActive, item);
  }
  
  addFishSpecies(item: FishSpecies){
    this.fishSpeciesOperations(DataOperation.Insert, item);
  }

  updateFishSpecies(item: FishSpecies){
    this.fishSpeciesOperations(DataOperation.Update, item);
  }

  deleteFishSpecies(item: FishSpecies){
    this.fishSpeciesOperations(DataOperation.Delete, item);
  }



  private fishSpeciesOperations(action: DataOperation, item: FishSpecies){
    let request: FishSpeciesOperationsRequest = {} as FishSpeciesOperationsRequest;
    request.action = action;
    request.item = item;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.fishSpeciesOperations(request).subscribe({
      next: (data: FishSpeciesOperationsResult) => {
        if (data.message.ok === true) {
          if (action === DataOperation.List) {
            this.allFishSpeciesSignal.set(data.items);
          } else if(action === DataOperation.Insert){
            this.allFishSpeciesSignal.set(data.items);
            this.showUserSuccessMessage("Bætti " + item.name + " við sem viðskiptavin");
          } else if(action === DataOperation.Update){
            this.allFishSpeciesSignal.set(data.items);
            this.showUserSuccessMessage("Uppfærði " + item.name);
          } else if(action === DataOperation.ListActive){
            this.allActiveFishSpeciesSignal.set(data.items);
          }  else if(action === DataOperation.SetActive){
            this.allFishSpeciesSignal.set(data.items);
          }
          // else if(action === DataOperation.Delete){
          //   this.showUserSuccessMessage(item.name + " hefur verið eytt");
          // }
        } else {
          console.error('message', data?.message);
          switch(action){
            case DataOperation.ListActive:
              const listErrorMessage: string = "Ekki tókst að sækja fisktegundir";
              this.showUserFailedMessage(listErrorMessage);
              break;
            case DataOperation.Insert:
              const insertErrorMessage: string = "Ekki tókst að bæta " + item.name + " við sem fisktegund";
              this.showUserFailedMessage(insertErrorMessage);
              break;
            case DataOperation.Update:
              const updateErrorMessage: string = "Ekki tókst að uppfæra " + item.name;
              this.showUserFailedMessage(updateErrorMessage);
              break;
            case DataOperation.Delete:
              const deleteErrorMessage: string = "Ekki tókst að eyða " + item.name;
              this.showUserFailedMessage(deleteErrorMessage);
              break;
            default:
              break;
          }
        }
      },
      error: (err) => this.ifError(err),
    });
  }

  insertOrUpdateGraphOffset(item: GraphOffset, cargoKey: string){
    this.graphOffsetOperations(DataOperation.InsertOrUpdate, item, cargoKey);
  }

  private graphOffsetOperations(action: DataOperation, item: GraphOffset, cargoKey: string){
    let request: GraphOffsetOperationsRequest = {} as GraphOffsetOperationsRequest;
    request.action = action;
    request.item = item;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.graphOffsetOperations(request).subscribe({
      next: (data: GraphOffsetOperationsResult) => {
        console.log("GraphOffsetOperations", data);
        console.log("GraphOffsetOperationsRequest",request)
        if (data.message.ok === true) {
          if (action == DataOperation.InsertOrUpdate) {
            const landing: Landing = {} as Landing;
            landing.cargoKey = cargoKey;
            this.graphOffsetFetched.set(false);
            this.getLandingsFromCargoKeyWithOffset(landing);
          }
        } else {
          console.error('message', data?.message);
        }
      },
      error: (err) => this.ifError(err),
    });
  }

  allLandingSumsSignal = signal<LandingSum[]>(null);

  private _currentLandingSumSource: BehaviorSubject<LandingSum> = new BehaviorSubject<LandingSum>(null);
  private _currentLandingSum$: Observable<LandingSum> = this._currentLandingSumSource.asObservable();
  get currentLandingSum$(): Observable<LandingSum> { return this._currentLandingSum$ }
  get currentLandingSum(): LandingSum { return this._currentLandingSumSource.getValue()}
  set currentLandingSum(newValue: LandingSum){
    this._currentLandingSumSource.next(newValue);
  }

  private _landingSumsDateFromSource: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date(new Date().getDate() - 365));
  private _landingSumsDateFrom$: Observable<Date> = this._landingSumsDateFromSource.asObservable();
  get landingSumsDateFrom$(): Observable<Date> { return this._landingSumsDateFrom$ }
  get landingSumsDateFrom(): Date { return this._landingSumsDateFromSource.getValue()}
  set landingSumsDateFrom(newValue: Date){
    this._landingSumsDateFromSource.next(newValue);
    // if(newValue){
    //   this.filterLandingSums(this.landingSumsFilterString, newValue, this.landingSumsDateTo);
    // }
  }

  private _landingSumsDateToSource: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  private _landingSumsDateTo$: Observable<Date> = this._landingSumsDateToSource.asObservable();
  get landingSumsDateTo$(): Observable<Date> { return this._landingSumsDateTo$ }
  get landingSumsDateTo(): Date { return this._landingSumsDateToSource.getValue()}
  set landingSumsDateTo(newValue: Date){
    this._landingSumsDateToSource.next(newValue);
    // if(newValue){
    //   this.filterLandingSums(this.landingSumsFilterString, this.landingSumsDateFrom, newValue);
    // }
  }

  // filterLandingSums(lowerCaseFilterString: string, dateFrom: Date, dateTo: Date){
  //   this.filteredLandingSums = this.allLandingSums.filter((value) => {
  //     return (moment(value.dagur).format('YYYYMMDD') >= moment(dateFrom).format('YYYYMMDD')
  //     && moment(value.dagur).format('YYYYMMDD') <= moment(dateTo).format('YYYYMMDD')
  //     && (value.weighterName.toLowerCase().includes(lowerCaseFilterString)
  //     || value.fishSpeciesName.toLowerCase().includes(lowerCaseFilterString)
  //     || value.shipName.toLowerCase().includes(lowerCaseFilterString)
  //     ));
  //   });
  // }

  getLandingSum(item: LandingSum){
    this.landingSumOperations(DataOperation.Read, item);
  }

  findLandingSumByCargoKey(item: LandingSum){
    this.landingSumOperations(DataOperation.FindByCargoKey, item);
  }

  getLandingSumsByAgencies(item: LandingSum){
    this.landingSumOperations(DataOperation.ListByAgencies, item);
  }

  updateTVNNumber(item: LandingSum){
    this.landingSumOperations(DataOperation.UpdateTVNNumber, item);
  }

  updateNavCode(item: LandingSum){
    this.landingSumOperations(DataOperation.UpdateNavCode, item);
  }

  updateVeidarfaeri(item: LandingSum){
    this.landingSumOperations(DataOperation.UpdateVeidarfaeri, item);
  }
  updateFisktegund(item: LandingSum){
    this.landingSumOperations(DataOperation.UpdateFisktegund, item);
  }


  private landingSumOperations(action: DataOperation, item: LandingSum){
    let request: LandingSumOperationsRequest = new LandingSumOperationsRequest();
    request.action = action;
    request.item = item;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.landingSumOperations(request).subscribe({
      next: (data: LandingSumOperationsResult) => {
        console.log("landingSumOperations", data);
        console.log("LandingSumOperationsRequest",request)
        if (data.message.ok === true) {
          if (action == DataOperation.ListByAgencies) {
            this.allLandingSumsSignal.set(data.items);
          } else if(action == DataOperation.Read){
            this.currentLandingSum = data.items[0];
          } else if(action == DataOperation.UpdateTVNNumber){
            this.currentLandingSum = data.items[0];
            this.showUserSuccessMessage("TVN númer uppfært")
          } else if(action == DataOperation.FindByCargoKey){
            this.currentLandingSum = data.items[0];
          } else if(action == DataOperation.UpdateNavCode){
            this.currentLandingSum = data.items[0];
            this.showUserSuccessMessage("Nav kóði uppfærður")
          } else if(action == DataOperation.UpdateVeidarfaeri){
            this.currentLandingSum = data.items[0];
            this.showUserSuccessMessage("Veiðarfæri uppfært")
          } else if(action == DataOperation.UpdateFisktegund){
            this.currentLandingSum = data.items[0];
            this.showUserSuccessMessage("Fisktegund uppfærð")
          }
        } else {
          console.error('message', data?.message);
        }
      },
      error: (err) => this.ifError(err),
    });
  }

  //Landings

  private _allLandingsSource: BehaviorSubject<Landing[]> = new BehaviorSubject<Landing[]>(null);
  private _allLandings$: Observable<Landing[]> = this._allLandingsSource.asObservable();
  get allLandings$(): Observable<Landing[]> { return this._allLandings$ }
  get allLandings(): Landing[] { return this._allLandingsSource.getValue()}
  set allLandings(newValue: Landing[]){
    if(newValue != this.allLandings){
      this._allLandingsSource.next(newValue);
    }
  }

  scale1HeatDataSignal = signal<Landing[]>(null);

  private _scale1HeatDataSource: BehaviorSubject<Landing[]> = new BehaviorSubject<Landing[]>(null);
  private _scale1HeatData$: Observable<Landing[]> = this._scale1HeatDataSource.asObservable();
  get scale1HeatData$(): Observable<Landing[]> { return this._scale1HeatData$ }
  get scale1HeatData(): Landing[] { return this._scale1HeatDataSource.getValue()}
  set scale1HeatData(newValue: Landing[]){
    this._scale1HeatDataSource.next(newValue);
  }

  scale1WeightDataSignal = signal<Landing[]>(null);

  private _scale1WeightDataSource: BehaviorSubject<Landing[]> = new BehaviorSubject<Landing[]>(null);
  private _scale1WeightData$: Observable<Landing[]> = this._scale1WeightDataSource.asObservable();
  get scale1WeightData$(): Observable<Landing[]> { return this._scale1WeightData$ }
  get scale1WeightData(): Landing[] { return this._scale1WeightDataSource.getValue()}
  set scale1WeightData(newValue: Landing[]){
    this._scale1WeightDataSource.next(newValue);
  }

  scale2HeatDataSignal = signal<Landing[]>(null);

  private _scale2HeatDataSource: BehaviorSubject<Landing[]> = new BehaviorSubject<Landing[]>(null);
  private _scale2HeatData$: Observable<Landing[]> = this._scale2HeatDataSource.asObservable();
  get scale2HeatData$(): Observable<Landing[]> { return this._scale2HeatData$ }
  get scale2HeatData(): Landing[] { return this._scale2HeatDataSource.getValue()}
  set scale2HeatData(newValue: Landing[]){
    this._scale2HeatDataSource.next(newValue);
  }

  scale2WeightDataSignal = signal<Landing[]>(null);

  private _scale2WeightDataSource: BehaviorSubject<Landing[]> = new BehaviorSubject<Landing[]>(null);
  private _scale2WeightData$: Observable<Landing[]> = this._scale2WeightDataSource.asObservable();
  get scale2WeightData$(): Observable<Landing[]> { return this._scale2WeightData$ }
  get scale2WeightData(): Landing[] { return this._scale2WeightDataSource.getValue()}
  set scale2WeightData(newValue: Landing[]){
    this._scale2WeightDataSource.next(newValue);
  }

  allLandingsWithOffsetSignal = signal<Landing[]>([] as Landing[]);

  heat1GraphSignal = signal<LandingGraph[]>([] as LandingGraph[]);
  weight1GraphSignal = signal<LandingGraph[]>([] as LandingGraph[]);
  weight2GraphSignal = signal<LandingGraph[]>([] as LandingGraph[]);
  heatGraphOffsetSignal = signal<GraphOffset>({} as GraphOffset);
  weightGraphOffsetSignal = signal<GraphOffset>({} as GraphOffset);
  weightGraph2OffsetSignal = signal<GraphOffset>({} as GraphOffset);
  weightGraph2Offset: GraphOffset = {} as GraphOffset;
  weightGraphOffset: GraphOffset = {} as GraphOffset;
  heat1GraphOffset: GraphOffset = {} as GraphOffset;

  graphOffsetFetched = signal(false);


  getLandingsFromCargoNumber(item: Landing){
    this.landingOperations(DataOperation.ListByCargoNumber, item);
  }

  getLandingsFromCargoKeyWithOffset(item: Landing){
    this.landingOperations(DataOperation.ListByCargoKeyWithOffset, item);
  }

  getLandingsFromCargoKey(item: Landing){
    this.landingOperations(DataOperation.ListByCargoKey, item);
  }

  filterChartDataForLandings(items: Landing[]){
    this.scale1HeatDataSignal.set(Object.assign([] as Landing[],items.filter(item => item.hitastig_Vigt1 != 0).sort((a, b) => a.dagur > b.dagur ? 1 : -1)));
    this.scale1WeightDataSignal.set(Object.assign([] as Landing[],items.filter(item => item.skammtur_Vigt1 > 0).sort((a, b) => a.dagur > b.dagur ? 1 : -1)));
    this.scale2HeatDataSignal.set(Object.assign([] as Landing[],items.filter(item => item.hitastig_Vigt2 != 0).sort((a, b) => a.dagur > b.dagur ? 1 : -1)));
    this.scale2WeightDataSignal.set(Object.assign([] as Landing[],items.filter(item => item.skammtur_Vigt2 > 0).sort((a, b) => a.dagur > b.dagur ? 1 : -1)));
  }


  private landingOperations(action: DataOperation, item: Landing){
    let request: LandingOperationsRequest = new LandingOperationsRequest();
    request.action = action;
    request.item = item;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.landingOperations(request).subscribe({
      next: (data: LandingOperationsResult) => {
        if (data.message.ok === true) {
          console.log("landingOperations", data);
          if (action == DataOperation.ListByCargoNumber) {
            this.allLandings = data.items;
            this.currentLandingStats = data.stats;
            this.landingStartTime = data.stats.startTime;
            this.landingEndTime = data.stats.endTime;
            this.filterChartDataForLandings(data.items);
          } else if(action == DataOperation.ListByCargoKey){
            this.allLandings = data.items;
            this.currentLandingStats = data.stats;
            this.landingStartTime = data.stats.startTime;
            this.landingEndTime = data.stats.endTime;
            this.filterChartDataForLandings(data.items);
          } else if(action == DataOperation.ListByCargoKeyWithOffset){
            this.allLandingsWithOffsetSignal.set(data.items);
            this.heat1GraphSignal.set(data.heatGraph);
            this.weight1GraphSignal.set(data.weightGraph);
            this.weight2GraphSignal.set(data.weightGraph2);
            this.heatGraphOffsetSignal.set(data.heatGraphOffset);
            this.weightGraphOffsetSignal.set(data.weightGraphOffset);
            this.weightGraph2OffsetSignal.set(data.weightGraph2Offset);
            this.heat1GraphOffset = data.heatGraphOffset;
            this.weightGraphOffset = data.weightGraphOffset;
            this.weightGraph2Offset = data.weightGraph2Offset;
            this.landingStatsSignal.set(data.stats);
            this.graphOffsetFetched.set(true);
          }
        } else {
          console.error('message', data?.message);
        }
      },
      error: (err) => this.ifError(err),
    });
  }

  getVigt1HeatData(item: LandingGraph){
    this.landingGraphOperations(DataOperation.GetHeatGraphDataVigt1, item);
  }

  getVigt1HeatDataWithOffset(item: LandingGraph){
    this.landingGraphOperations(DataOperation.GetHeatGraphDataVigt1Offset, item);
  }

  getVigt2HeatData(item: LandingGraph){
    this.landingGraphOperations(DataOperation.GetHeatGraphDataVigt2, item);
  }

  getVigt1HourlyWeightData(item: LandingGraph){
    this.landingGraphOperations(DataOperation.GetHourlyWeightGraphDataVigt1, item);
  }

  getVigt1HourlyWeightDataWithOffset(item: LandingGraph){
    this.landingGraphOperations(DataOperation.GetHourlyWeightGraphDataVigt1Offset, item);
  }

  getVigt2HourlyWeightData(item: LandingGraph){
    this.landingGraphOperations(DataOperation.GetHourlyWeightGraphDataVigt2, item);
  }

  getVigt2HourlyWeightDataWithOffset(item: LandingGraph){
    this.landingGraphOperations(DataOperation.GetHourlyWeightGraphDataVigt2Offset, item);
  }

  vigt1HeatDataSignal = signal<LandingGraph[]>(null);
  vigt2HeatDataSignal = signal<LandingGraph[]>(null);
  vigt1HourlyWeightDataSignal = signal<LandingGraph[]>(null);
  vigt2HourlyWeightDataSignal = signal<LandingGraph[]>(null);


  landingGraphOperations(action: DataOperation, item: LandingGraph){
    let request: LandingGraphOperationsRequest = new LandingGraphOperationsRequest();
    request.action = action;
    request.item = item;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.landingGraphOperations(request).subscribe({
      next: (data: LandingGraphOperationsResult) => {
        if (data.message.ok === true) {
          console.log("landingGraphOperations", data);
          if (action == DataOperation.GetHeatGraphDataVigt1) {
            this.vigt1HeatDataSignal.set(data.items);
          } else if(action == DataOperation.GetHeatGraphDataVigt2){
            this.vigt2HeatDataSignal.set(data.items);
          } else if(action == DataOperation.GetHourlyWeightGraphDataVigt1){
            this.vigt1HourlyWeightDataSignal.set(data.items);
          } else if(action == DataOperation.GetHourlyWeightGraphDataVigt2){
            this.vigt2HourlyWeightDataSignal.set(data.items);
          } else if(action == DataOperation.GetHeatGraphDataVigt1Offset){
            this.vigt1HeatDataSignal.set(data.items);
          } else if(action == DataOperation.GetHourlyWeightGraphDataVigt1Offset){
            this.vigt1HourlyWeightDataSignal.set(data.items);
          } else if(action == DataOperation.GetHeatGraphDataVigt2Offset){
            this.vigt2HeatDataSignal.set(data.items);
          } else if(action == DataOperation.GetHourlyWeightGraphDataVigt2Offset){
            this.vigt2HourlyWeightDataSignal.set(data.items);
          }
        
        } else {
          console.error('message', data?.message);
        }
      },
      error: (err) => this.ifError(err),
    });
  }

  landingStatsSignal = signal<LandingStats>(null);

  getLandingStatsByCargoKey(item: LandingStats){
    this.landingStatsOperations(DataOperation.GetLandingStats, item);
  }

  landingStatsOperations(action: DataOperation, item: LandingStats){
    let request: LandingStatsOperationsRequest = new LandingStatsOperationsRequest();
    request.action = action;
    request.item = item;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.landingStatsOperations(request).subscribe({
      next: (data: LandingStatsOperationsResult) => {
        if (data.message.ok === true) {
          console.log("LandingStatsOperations", data);
          if (action == DataOperation.GetLandingStats) {
            this.landingStatsSignal.set(data.items[0]);
          }
        } else {
          console.error('message', data?.message);
        }
      },
      error: (err) => this.ifError(err),
    });
  }


  //LandingStats

  private _currentLandingStatsSource: BehaviorSubject<LandingStats> = new BehaviorSubject<LandingStats>(null);
  private _currentLandingStats$: Observable<LandingStats> = this._currentLandingStatsSource.asObservable();
  get currentLandingStats$(): Observable<LandingStats> { return this._currentLandingStats$ }
  get currentLandingStats(): LandingStats { return this._currentLandingStatsSource.getValue()}
  set currentLandingStats(newValue: LandingStats){
    if(newValue != this.currentLandingStats){
      this._currentLandingStatsSource.next(newValue);
    }
  }

  private _landingStartTimeSource: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  private _landingStartTime$: Observable<Date> = this._landingStartTimeSource.asObservable();
  get landingStartTime$(): Observable<Date> { return this._landingStartTime$ }
  get landingStartTime(): Date { return this._landingStartTimeSource.getValue()}
  set landingStartTime(newValue: Date){
    this._landingStartTimeSource.next(newValue);
  }

  private _landingEndTimeSource: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  private _landingEndTime$: Observable<Date> = this._landingEndTimeSource.asObservable();
  get landingEndTime$(): Observable<Date> { return this._landingEndTime$ }
  get landingEndTime(): Date { return this._landingEndTimeSource.getValue()}
  set landingEndTime(newValue: Date){
    this._landingEndTimeSource.next(newValue);
  }

  //HourlyVariables

  private _scale1HourlyDataSource: BehaviorSubject<HourlyData[]> = new BehaviorSubject<HourlyData[]>(null);
  private _scale1HourlyData$: Observable<HourlyData[]> = this._scale1HourlyDataSource.asObservable();
  get scale1HourlyData$(): Observable<HourlyData[]> { return this._scale1HourlyData$ }
  get scale1HourlyData(): HourlyData[] { return this._scale1HourlyDataSource.getValue()}
  set scale1HourlyData(newValue: HourlyData[]){
    this._scale1HourlyDataSource.next(newValue);
  }

  private _scale1HourlyWeightSource: BehaviorSubject<HourlySum[]> = new BehaviorSubject<HourlySum[]>(null);
  private _scale1HourlyWeight$: Observable<HourlySum[]> = this._scale1HourlyWeightSource.asObservable();
  get scale1HourlyWeight$(): Observable<HourlySum[]> { return this._scale1HourlyWeight$ }
  get scale1HourlyWeight(): HourlySum[] { return this._scale1HourlyWeightSource.getValue()}
  set scale1HourlyWeight(newValue: HourlySum[]){
    this._scale1HourlyWeightSource.next(newValue);
  }

  private _scale2HourlyDataSource: BehaviorSubject<HourlyData[]> = new BehaviorSubject<HourlyData[]>(null);
  private _scale2HourlyData$: Observable<HourlyData[]> = this._scale2HourlyDataSource.asObservable();
  get scale2HourlyData$(): Observable<HourlyData[]> { return this._scale2HourlyData$ }
  get scale2HourlyData(): HourlyData[] { return this._scale2HourlyDataSource.getValue()}
  set scale2HourlyData(newValue: HourlyData[]){
    this._scale2HourlyDataSource.next(newValue);
  }

  private _scale2HourlyWeightSource: BehaviorSubject<HourlySum[]> = new BehaviorSubject<HourlySum[]>(null);
  private _scale2HourlyWeight$: Observable<HourlySum[]> = this._scale2HourlyWeightSource.asObservable();
  get scale2HourlyWeight$(): Observable<HourlySum[]> { return this._scale2HourlyWeight$ }
  get scale2HourlyWeight(): HourlySum[] { return this._scale2HourlyWeightSource.getValue()}
  set scale2HourlyWeight(newValue: HourlySum[]){
    this._scale2HourlyWeightSource.next(newValue);
  }


  //Data Manipulation for graphs

  splitDataByHour(dataArray: Landing[]): HourlyData[] {
    const hourlyDataMap: Map<string, HourlyData> = new Map();
    for (const data of dataArray) {
      const dateKey = `${new Date(data.dagur).getFullYear()}-${new Date(data.dagur).getMonth()}-${new Date(data.dagur).getDate()}`;
      const hour = new Date(data.dagur).getHours();
  
      const hourlyData = hourlyDataMap.get(dateKey);
      if (hourlyData) {
        hourlyData.data[hour] = hourlyData.data[hour] || [];
        hourlyData.data[hour].push(data);
      } else {
        const newHourlyData: HourlyData = {
          date: new Date(new Date(data.dagur).getFullYear(), new Date(data.dagur).getMonth(), new Date(data.dagur).getDate()),
          data: Array.from({ length: 24 }, (_, i) => (i === hour ? [data] : [])),
        };
        hourlyDataMap.set(dateKey, newHourlyData);
      }
    }
    const hourlyData: HourlyData[] = Array.from(hourlyDataMap.values());

    // Filter out empty hour arrays
    const filteredHourlyData: HourlyData[] = hourlyData.map(({ date, data }) => ({
      date,
      data: data.filter((hourArray) => hourArray.length > 0),
    })).filter(({ data }) => data.length > 0);

    return filteredHourlyData;
  }

  getHourlyWeight(hourlyData: HourlyData[], scaleNumber: number){
    const hourlySums: HourlySum[] = [];
    
    
    hourlyData.forEach((data, index) => {
      data.data.forEach((landingArray, landingIndex) => {
        let sum = 0;
        if(scaleNumber === 1){
          sum = Math.round((landingArray.reduce((total, landing) => total + landing.magn_Vigt1, 0) / 1000));
        }else if(scaleNumber === 2){
          sum = Math.round((landingArray.reduce((total, landing) => total + landing.magn_Vigt2, 0) / 1000));
        }

        switch(scaleNumber){
          case 1:
            const scale1HourlySum: HourlySum = {
              date: new Date(new Date(landingArray[0].dagur).getFullYear(),new Date(landingArray[0].dagur).getMonth(), new Date(landingArray[0].dagur).getDate(), new Date(landingArray[0].dagur).getHours() ) ,
              sum: sum,
              skammtur: landingArray[landingArray?.length - 1]?.skammtur_Vigt1 
            };
            hourlySums.push(scale1HourlySum);
            break;
          case 2:
              const scale2HourlySum: HourlySum = {
              date: new Date(new Date(landingArray[0].dagur).getFullYear(),new Date(landingArray[0].dagur).getMonth(), new Date(landingArray[0].dagur).getDate(), new Date(landingArray[0].dagur).getHours() ) ,
              sum: sum,
              skammtur: landingArray[landingArray?.length - 1]?.skammtur_Vigt2
            };
            hourlySums.push(scale2HourlySum);
            break;
          default:
            break;
        }

      });
    });
    return hourlySums;
  }


  allProcessingRecordsSignal = signal<ProcessingRecord[]>([] as ProcessingRecord[]);
  allProcessingRecordsByDateSignal = signal<ProcessingRecord[]>([] as ProcessingRecord[]);
  processingRecordsForLotaReadySignal = signal<boolean>(false);
  allProcessingRecordForLondunsSignal = signal<ProcessingRecord[]>([] as ProcessingRecord[]);
  allProcessingDatesByMonthSignal = signal<ProcessingRecord[]>([] as ProcessingRecord[]);

  getAllProcessingRecords(item: ProcessingRecord){
    this.processingRecordOperations(DataOperation.List, item);
  }

  getProcessingRecordsForDay(item: ProcessingRecord){
    this.processingRecordOperations(DataOperation.ListByDate, item);
  }

  getProcessingRecordsForMonth(item: ProcessingRecord){
    this.processingRecordOperations(DataOperation.GetProcessingDatesByMonth, item);
  }

  getProcessingRecordsForVLondun(item: ProcessingRecord){
    this.processingRecordOperations(DataOperation.ListByLota, item);
  }

  private processingRecordOperations(action: DataOperation, item: ProcessingRecord){
    let request: ProcessingRecordOperationsRequest = new ProcessingRecordOperationsRequest();
    request.action = action;
    request.item = item;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.processingRecordOperations(request).subscribe({
      next: (data: ProcessingRecordOperationsResult) => {
        console.log(data.items);
        if (data.message.ok === true) {
          if(action == DataOperation.ListByDate){
            this.allProcessingRecordsSignal.set(data.items);
          }else if(action == DataOperation.ListByLota){
            this.allProcessingRecordsSignal.set(data.items);
          }else if(action == DataOperation.GetProcessingDatesByMonth){
            this.allProcessingDatesByMonthSignal.set(data.items);
          }
        } else {
          console.error('message', data?.message);
        }
      },
      error: (err) => this.ifError(err),
    });
  }

  //Processing Record Stats
  processingRecordsStatsSignal = signal<ProcessingRecordStats>({} as ProcessingRecordStats);

  getProcessingRecordStats(item: ProcessingRecordStats){
    this.processingRecordStatsOperations(DataOperation.ListByLota, item);
  }

  getProcessingRecordStatsByDate(item: ProcessingRecordStats){
    this.processingRecordStatsOperations(DataOperation.ListByDate, item);
  }

  private processingRecordStatsOperations(action: DataOperation, item: ProcessingRecordStats){
    let request: ProcessingRecordStatsOperationsRequest = new ProcessingRecordStatsOperationsRequest();
    request.action = action;
    request.item = item;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.processingRecordStatsOperations(request).subscribe({
      next: (data: ProcessingRecordStatsOperationsResult) => {
        console.log(data.items);
        if (data.message.ok === true) {
          if(action == DataOperation.ListByLota){
            this.processingRecordsStatsSignal.set(data.items[0]);
          }else if(action == DataOperation.ListByDate){
            this.processingRecordsStatsSignal.set(data.items[0]);
          }
        } else {
          console.error('message', data?.message);
        }
      },
      error: (err) => this.ifError(err),
    });
  }

  //VinnsluLota
  vinnsluLotaSignal = signal<VinnsluLota[]>([] as VinnsluLota[]);

  getAllVinnsluLota(item: VinnsluLota){
    this.vinnsluLotaOperations(DataOperation.List, item);
  }

  private vinnsluLotaOperations(action: DataOperation, item: VinnsluLota){
    let request: VinnsluLotaOperationsRequest = new VinnsluLotaOperationsRequest();
    request.action = action;
    request.item = item;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }
    this._dataService.vinnsluLotaOperations(request).subscribe({
      next: (data: VinnsluLotaOperationsResult) => {
        console.log(data.items);
        if (data.message.ok === true) {
          if(action == DataOperation.List){
            this.vinnsluLotaSignal.set(data.items);
          }
        } else {
          console.error('message', data?.message);
        }
      },
      error: (err) => this.ifError(err),
    });
  }


  //Toasts

  public showUserSuccessMessage(messageText: string, detailText: string = ""){
    this._toastr.success(messageText, "Aðgerð tókst");
  }

  public showUserFailedMessage(messageText: string, detailText: string = ""){
    this._toastr.error(messageText, "Eitthvað fór úrskeiðis");
  }


  ifError(err: any) {
    //not implemented
    console.log(err);

    return this._errorHandler.handleError(err);
  };


}

<header>
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm container" >
      <a class="navbar-brand" [routerLink]="['/']">
        <img class="logo" src="/images/Brim_logo-clean.png" alt="Brim - Löndun">
      </a>
      <div class="navigation not-printed d-sm-inline-flex justify-content-end" *ngIf="(currentUser$ | async) && suppressNavigation===false" >
        <ul class="navbar-nav flex-grow" >
          <!-- <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link text-dark" [routerLink]="['/']">Home</a>
          </li> -->
          <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="(currentUser$ | async)?.authenticated">
            <a class="nav-link text-dark" [routerLink]="['/summutolur']" >Landanir</a>
          </li>
          <!-- <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="(currentUser$ | async)?.authenticated">
            <a class="nav-link text-dark" [routerLink]="['/ships']" >Skip</a>
          </li> -->
          <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="(currentUser$ | async)?.admin">
            <a class="nav-link text-dark" [routerLink]="['/skipaskraning']" >Skipaskrá</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']"  *ngIf="(currentUser$ | async)?.admin">
            <a class="nav-link text-dark" [routerLink]="['/agents']">Heimildir</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']"  *ngIf="(currentUser$ | async)?.admin">
            <a class="nav-link text-dark" [routerLink]="['/users']">Notendur</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']"  *ngIf="(currentUser$ | async)?.admin">
            <a class="nav-link text-dark" [routerLink]="['/vidskiptavinir']">Viðskiptavinir</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']"  *ngIf="(currentUser$ | async)?.admin">
            <a class="nav-link text-dark" [routerLink]="['/fisktegundir']">Fisktegundir</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']"  *ngIf="(currentUser$ | async)?.admin">
            <a class="nav-link text-dark" [routerLink]="['/vinnslutolur']">Vinnslutölur</a>
          </li>
          <li class="log-in" [routerLinkActive]="['link-active']" *ngIf="!(currentUser$ | async)?.authenticated">
            <a class="nav-link text-dark" [routerLink]="['/innskraning']">Innskráning</a>
          </li>
        </ul>
        <div class="sign-out-section" *ngIf="(currentUser$ | async)?.authenticated" pTooltip="Útskráning">
          <a class="sign-out text-dark" (click)="logOut()">
            <i class="fal fa-sign-out"></i>
          </a>
        </div>

      </div>
      <div class="hamburger-menu" *ngIf="(currentUser$ | async)  && suppressNavigation===false" >
        <input id="menu__toggle" type="checkbox" #menuToggle  />
        <label class="menu__btn" for="menu__toggle" >
          <span></span>
        </label>

        <ul class="menu__box">
          <li><a class="menu__item" [routerLink]='["/summutolur"]' (click)="closeClick()" *ngIf="(currentUser$ | async)?.authenticated" (click)="onBurgerMenuClick(menuToggle)">Landanir</a></li>
          <li><a class="menu__item" [routerLink]='["/vinnslutolur"]' (click)="closeClick()" *ngIf="(currentUser$ | async)?.authenticated" (click)="onBurgerMenuClick(menuToggle)">Vinnslutölur</a></li>
          <li><a class="menu__item" [routerLink]='["/ships"]' (click)="closeClick()" *ngIf="(currentUser$ | async)?.authenticated" (click)="onBurgerMenuClick(menuToggle)">Skip</a></li>
          <li><a class="menu__item" [routerLink]='["/agents"]' (click)="closeClick()" *ngIf="(currentUser$ | async)?.admin" (click)="onBurgerMenuClick(menuToggle)">Heimildir</a></li>
          <li><a class="menu__item" [routerLink]='["/users"]' (click)="closeClick()" *ngIf="(currentUser$ | async)?.admin" (click)="onBurgerMenuClick(menuToggle)">Notendur</a></li>
          <li><a class="menu__item" [routerLink]='["/vidskiptavinir"]' (click)="closeClick()" *ngIf="(currentUser$ | async)?.admin" (click)="onBurgerMenuClick(menuToggle)">Viðskiptavinir</a>
          <li><a class="menu__item" [routerLink]='["/fisktegundir"]' (click)="closeClick()" *ngIf="(currentUser$ | async)?.admin" (click)="onBurgerMenuClick(menuToggle)">Fisktegundir</a></li>
          <li><a class="menu__item" [routerLink]='["/utskraning"]' (click)="logOut()" *ngIf="(currentUser$ | async)?.admin" (click)="onBurgerMenuClick(menuToggle)">Útskráning</a></li>
        </ul>
      </div>
  </nav>
</header>

import { Component, computed, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../admin.service';
import { ProcessingRecord, ProcessingRecordStats } from 'src/app/shared/models';
import { ProcessingRecordViewModeEnum } from 'src/app/shared/enums';
import { GraphService } from 'src/app/services/graph.service';
import { first } from 'rxjs';
import { AltGreen, Black, BrimBlue, Red, StdBlue, StdBrown, StdGreen, StdGrey, StdOrange, StdPurple } from 'src/app/shared/constants';

@Component({
  selector: 'app-process-records-vinnsla',
  templateUrl: './process-records-vinnsla.component.html',
  styleUrls: ['./process-records-vinnsla.component.css']
})
export class ProcessRecordsVinnslaComponent {
  isMobileSignal = this._adminService.isMobile;

  vinnslaid: string;
  vinnslaidSignal = signal<string>(null);
  dagur: string;
  dagurString: string;

  // allProcessingRecordsSignal = this._adminService.allProcessingRecordsSignal;
  // allProcessingRecordsByDateSignal = this._adminService.allProcessingRecordsByDateSignal;
  allProcessingRecordsSignal = this._adminService.allProcessingRecordsSignal;
  xLabels = computed(() => {
    return this.getKlstLabels(this.allProcessingRecordsSignal());
  });

  haarslevValues = computed(() => {
    return this.getValues('meS_Hiti_Mjoli', this.allProcessingRecordsSignal());
  });

  ammoniakValues = computed(() => {
    return this.getValues('niR_Ammoniak', this.allProcessingRecordsSignal());
  });

  cadaverineValues = computed(() => {
    return this.getCadaverineValues(this.allProcessingRecordsSignal());
  });

  ashValues = computed(() => {
    return this.getValues('niR_ash', this.allProcessingRecordsSignal());
  });

  moistureValues = computed(() => {
    return this.getValues('niR_Mosture', this.allProcessingRecordsSignal());
  });

  proteinValues = computed(() => {
    return this.getValues('niR_Protein', this.allProcessingRecordsSignal());

  });

  saltValues = computed(() => {
    return this.getValues('niR_Salt', this.allProcessingRecordsSignal());
  });

  fatValues = computed(() => {
    return this.getValues('niR_fat', this.allProcessingRecordsSignal());
  });

  sjodaralina1Values = computed(() => {
    return this.getValues('lina1_Hiti_Fra_Sjodurum', this.allProcessingRecordsSignal());
  });

  sjodaralina2Values = computed(() => {
    return this.getValues('lina2_Hiti_Fra_Sjodurum', this.allProcessingRecordsSignal());
  });

  skrufusjodaraValues = computed(() => {
    return this.getValues('hiti_Fra_Skrufusjodara', this.allProcessingRecordsSignal());
  });


  vatnsnotkunValues = computed(() => {
    return this.getVatnsnotkunValues(this.allProcessingRecordsSignal());
  });

  vatnsnotkunPerHourValues = computed(() => {
    return this.getVatnsnotkunPerHourValues(this.allProcessingRecordsSignal());
  });

  rafmagnForgangurPerHourValues = computed(() => {
    return this.getPerHourValues(this.getValues('f_Rafmagn_MWst', this.allProcessingRecordsSignal()), "f_Rafmagn_MWst", true);
  });

  rafmagnUmframPerHourValues = computed(() => {
    return this.getPerHourValues(this.getValues('u_Rafmagn_MWst', this.allProcessingRecordsSignal()), "u_Rafmagn_MWst", true);
  });

  rafmagnEiginPerHourValues = computed(() => {
    return this.getPerHourValues(this.getValues('u_RafmVelar_MWst', this.allProcessingRecordsSignal()), "u_RafmVelar_MWst", true);
  });

  hraefniL1Values = computed(() => {
    return this.getValues('l1_Hraefni_m3', this.allProcessingRecordsSignal());
  });

  hraefniL2Values = computed(() => {
    return this.getValues('l2_Hraefni_m3', this.allProcessingRecordsSignal());
  });

  hraefniT1Values = computed(() => {
    return this.getValues('t1_Hraefni_tonn', this.allProcessingRecordsSignal());
  });

  hraefniT2Values = computed(() => {
    return this.getValues('t2_Hraefni_tonn', this.allProcessingRecordsSignal());
  });

  hraefniT3Values = computed(() => {
    return this.getValues('t3_Hraefni_tonn', this.allProcessingRecordsSignal());
  });

  thraarvornValues = computed(() => {
    return this.getThraarvornValues(this.allProcessingRecordsSignal());
  });

  thraarvornPerHourValues = computed(() => {
    return this.getPerHourValues(this.getThraarvornValues(this.allProcessingRecordsSignal()), "thraavorn_ppm",true);
  });

  oliaPerHourValues = computed(() => {
    return this.getPerHourValues(this.getValues('olia_m3', this.allProcessingRecordsSignal()), "olia_m3", true);
  });

  mjolPerHourValues = computed(() => {
    return this.getPerHourValues(this.getValues('mjol_tonn', this.allProcessingRecordsSignal()), "mjol_tonn", true);
  });

  thraarvornPpmPerHourValues = computed(() => {
    return this.calculatePpmPerHour(this.thraarvornPerHourValues(), this.mjolPerHourValues());
  });

  thraarvornTotalSignal = computed(() => {
    if(this.thraarvornValues()?.length > 0){
      return this.mjolTotalSignal() > 0 ? this.thraarvornPerHourValues().reduce((sum, current) => sum + current, 0) / this.mjolTotalSignal() : 0;
    }else{
      return 0;
    }
  });

  vatnsnotkunTotalSignal = computed(() => {
    if(this.vatnsnotkunValues()?.length > 0){
      return this.vatnsnotkunPerHourValues().reduce((sum, current) => sum + current, 0);
    }else{
      return 0;
    }
  });

  
  rafmagnForgangurTotalSignal = computed(() => {
    if(this.rafmagnForgangurPerHourValues()?.length > 0){
      return (this.rafmagnForgangurPerHourValues().reduce((sum, current) => sum + current, 0) / 1000);
    }else{
      return 0;
    }
  });

  rafmagnUmframTotalSignal = computed(() => {
    if(this.rafmagnUmframPerHourValues()?.length > 0){
      return (this.rafmagnUmframPerHourValues().reduce((sum, current) => sum + current, 0) / 1000);
    }else{
      return 0;
    }
  });

  rafmagnEiginTotalSignal = computed(() => {
    if(this.rafmagnEiginPerHourValues()?.length > 0){
      return (this.rafmagnEiginPerHourValues().reduce((sum, current) => sum + current, 0) / 1000);
    }else{
      return 0;
    }
  });

  oliaTotalSignal = computed(() => {
    if(this.oliaPerHourValues()?.length > 0){
      return this.oliaPerHourValues().reduce((sum, current) => sum + current, 0);
    }else{
      return 0;
    }
  });

  mjolTotalSignal = computed(() => {
    if(this.mjolPerHourValues()?.length > 0){
      return this.mjolPerHourValues().reduce((sum, current) => sum + current, 0);
    }else{
      return 0;
    }
  });

  cadaverineTotalSignal = computed(() => {
    if(this.cadaverineValues()?.length > 0){
      return this.cadaverineValues().reduce((sum, current) => sum + current, 0);
    }else{
      return 0;
    }
  });



  activeView: ProcessingRecordViewModeEnum;
  vinnsluLotaViewEnum = ProcessingRecordViewModeEnum.VinnsluLota;

  processingRecordsStatsSignal = this._adminService.processingRecordsStatsSignal;

  ingredientLastValueSignal = computed(() => {
    if(this.allProcessingRecordsSignal()?.length > 0){
      return this.getLastIngridientValue(this.allProcessingRecordsSignal());
    }else{
      return null;
    }
  });

  waterUsageLastValueSignal = computed(() => {
    if(this.allProcessingRecordsSignal()?.length > 0){
      return this.getLastWaterUsageValue(this.allProcessingRecordsSignal());
    }else{
      return null;
    }
  });

  heatGraphData = computed(() => {
    if(this.haarslevValues()?.length > 0){
      return this.generateGraph(
        [this.haarslevValues(), this.sjodaralina1Values(), this.sjodaralina2Values(), this.skrufusjodaraValues()],
        this.xLabels(),
        ["Haarslev", "Sjóðaralína1", "Sjóðaralína2", "Skrúfusjóðari"],
        [StdBlue,StdBrown, StdGreen, StdPurple],
        this.selectedTypeSignal(),
        "°C"
      );
    }else{
      return {};
    }
  });

  nirGraphData = computed(() => {
    if(this.ammoniakValues()?.length > 0){
      return this.generateStackedGraph(
        [this.ashValues(), this.moistureValues(), this.proteinValues(), this.saltValues(), this.fatValues(),this.ammoniakValues()],
        this.xLabels(),
        ["Ash", "Raki", "Prótein", "Salt", "Fita", "Ammóníak"],
        [StdGreen, StdPurple, StdGrey, StdOrange, AltGreen, StdBlue],
        "bar",
        "%"
      );
    }else{
      return {};
    }
  });

  nirDonutGraphs = computed(() => {
    if(this.ammoniakValues()?.length > 0){

    }
  });


  waterUsageGraphData = computed(() => {
    if(this.vatnsnotkunValues()?.length > 0){
      return this.generateGraph(
        [this.vatnsnotkunValues()],
        this.xLabels(),
        ["Ferskvatn"],
        [BrimBlue],
        this.selectedTypeSignal(),
        "m3"
      );
    }else{
      return {};
    }
  });

  waterUsagePerHourGraphData = computed(() => {
    if(this.vatnsnotkunPerHourValues()?.length > 0){
      return this.generateGraph(
        [this.vatnsnotkunPerHourValues()],
        this.xLabels(),
        ["Ferskvatn"],
        [StdBlue],
        this.selectedTypeSignal(),
        "m3"
      );
    }else{
      return {};
    }
  });

  rafmagnForgangurPerHourGraphData = computed(() => {
    if(this.rafmagnForgangurPerHourValues()?.length > 0){
      return this.generateGraph(
        [this.rafmagnForgangurPerHourValues()],
        this.xLabels(),
        ["Rafmagn: Forgangur", "Rafmagn: Umfram"],
        [AltGreen],
        this.selectedTypeSignal(),
        "MWst"
      );
    }else{
      return {};
    }
  });

  rafmagnUmframGraphData = computed(() => {
    if(this.rafmagnUmframPerHourValues()?.length > 0){
      return this.generateGraph(
        [this.rafmagnUmframPerHourValues()],
        this.xLabels(),
        ["Rafmagn: Umfram"],
        [StdPurple],
        this.selectedTypeSignal(),
        "MWst"
      );
    }else{
      return {};
    }
  });

  rafmagnUmframPerHourGraphData = computed(() => {
    if(this.rafmagnUmframPerHourValues()?.length > 0){
      return this.generateGraph(
        [this.rafmagnUmframPerHourValues()],
        this.xLabels(),
        ["Rafmagn: Umfram"],
        [StdOrange],
        this.selectedTypeSignal(),
        "MWst"
      );
    }else{
      return {};
    }
  });

  rafmagnEiginPerHourGraphData = computed(() => {
    if(this.rafmagnEiginPerHourValues()?.length > 0){
      return this.generateGraph(
        [this.rafmagnEiginPerHourValues()],
        this.xLabels(),
        ["Rafmagn: Eigin framleiðsla"],
        [StdOrange],
        this.selectedTypeSignal(),
        "MWst"
      );
    }else{
      return {};
    }
  });

  hraefniGraphData = computed(() => {
    if(this.hraefniL1Values()?.length > 0){
      return this.generateGraph(
        [this.hraefniL1Values(), this.hraefniL2Values()],
        this.xLabels(),
        ["Lína 1", "Lína 2"],
        [StdGrey, StdBrown],
        this.selectedTypeSignal(),
        "Tonn"
      );
    }else{
      return {};
    }
  });

  hraefniTankarGraphData = computed(() => {
    if(this.hraefniT1Values()?.length > 0){
      return this.generateStackedGraph(
        [this.hraefniT1Values(), this.hraefniT2Values(), this.hraefniT3Values()],
        this.xLabels(),
        ["Tankur 1", "Tankur 2", "Tankur 3"],
        [StdGrey, StdBrown, BrimBlue],
        this.selectedTypeSignal(),
        "Tonn"
      );
    }else{
      return {};
    }

  });

  thraarvornPerHourGraphData = computed(() => {
    if(this.thraarvornPpmPerHourValues()?.length > 0){
      return this.generateGraph(
        [this.thraarvornPpmPerHourValues()],
        this.xLabels(),
        ["Þráarvörn"],
        [StdGreen],
        this.selectedTypeSignal(),
        "ppm"
      );
    }else{
      return {};
    }
  });

  oliaPerHourGraphData = computed(() => {
    if(this.oliaPerHourValues()?.length > 0){
      return this.generateGraph(
        [this.oliaPerHourValues()],
        this.xLabels(),
        ["Olía"],
        [Black],
        this.selectedTypeSignal(),
        "m3"
      );
    }else{
      return {};
    }
  });

  mjolPerHourGraphData = computed(() => {
    if(this.mjolPerHourValues()?.length > 0){
      return this.generateGraph(
        [this.mjolPerHourValues()],
        this.xLabels(),
        ["Mjöl"],
        [BrimBlue],
        this.selectedTypeSignal(),
        "Tonn"
      );
    }else{
      return {};
    }
  });

  options: any;
  oliaOptions: any;
  rafmagnForgangurOptions: any;
  rafmagnUmframOptions: any;
  rafmagnEiginOptions: any;
  vatnOptions: any;
  mjolOptions: any;
  thraarvornOptions: any;
  hraefniOptions: any;
  hraefniTankarOptions: any;
  heatOptions: any;
  nirOptions: any;


  selectedView: ProcessingRecordViewModeEnum = ProcessingRecordViewModeEnum.HeatView;
  heatViewEnum = ProcessingRecordViewModeEnum.HeatView;
  NiRViewEnum = ProcessingRecordViewModeEnum.NiRView;
  waterUsageViewEnum = ProcessingRecordViewModeEnum.WaterUsageView;
  energyViewEnum = ProcessingRecordViewModeEnum.EnergyView;

  selectedType: string = 'line';
  selectedTypeSignal = signal<string>('line');
  typeOptions: any[] = [
    { label: 'Línurit', value: 'line' }, 
    { label: 'Stöplarit', value: 'bar'}
  ];

  viewOptions: any[] = [
    { label: 'Hitastig', value: ProcessingRecordViewModeEnum.HeatView }, 
    { label: 'NiR', value: ProcessingRecordViewModeEnum.NiRView},
    { label: 'Notkun', value: ProcessingRecordViewModeEnum.WaterUsageView},
    { label: 'Orka', value: ProcessingRecordViewModeEnum.EnergyView},
  ];

  
  constructor(
    private _adminService: AdminService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _graphService: GraphService
  ) { 
    this.vinnslaid = this._route.snapshot.paramMap.get('vinnslaid');
    if(this.vinnslaid){
      this.vinnslaidSignal.set(this.vinnslaid);
      this.activeView = ProcessingRecordViewModeEnum.VinnsluLota;
      this.getProcessingRecordStats(
        { v_Lota: this.vinnslaid } as ProcessingRecordStats
        );
        this.getProcessingRecordsForVLondun({ v_Lota: this.vinnslaid } as ProcessingRecord);
    }else{
      this.dagur = this._route.snapshot.paramMap.get('dagur');
      this.dagurString = this.generateDagurString(this.dagur);
      this.activeView = ProcessingRecordViewModeEnum.DayView;
      this.getProcessingRecordStatsByDate(
        { dagur_InT: this.getDateByDagurString(this.dagur) } as ProcessingRecordStats
      );
      this.getProcessingRecordsForDay({ dagur_InT: this.getDateByDagurString(this.dagur) } as ProcessingRecord);
    }

    this.heatOptions = this.generateOptions(false, "°C");
    this.nirOptions = this.generateOptions(true, "%");
    this.oliaOptions = this.generateOptions(false, "m3");
    this.vatnOptions = this.generateOptions(false, "m3");
    this.mjolOptions = this.generateOptions(false, "Tonn");
    this.hraefniOptions = this.generateOptions(false, "Tonn");
    this.hraefniTankarOptions = this.generateOptions(true, "Tonn");
    this.rafmagnForgangurOptions = this.generateOptions(false, "KWst");
    this.rafmagnEiginOptions = this.generateOptions(false, "KWst");
    this.rafmagnUmframOptions = this.generateOptions(false, "KWst");
    this.thraarvornOptions = this.generateOptions(false, "ppm");
  }

  generateDagurString(dagur: string){
    const parts = dagur.split('-');
    const year = parts[0];
    const month = parseInt(parts[1]);
    const day = parts[2];
    return `${day}.${this.getFullMonthString(month)} ${year}`;
  }

  onTypeChange(event: string){
    this.selectedTypeSignal.set(event);
  }

  generateGraph(dataSetData: number[][], labels: string[], dataSetLabels: string[], colors: string[], graphType: string, postFix: string){
    return this._graphService.generateGraphData(dataSetData, labels, dataSetLabels, colors, graphType, postFix);
  }

  generateStackedGraph(dataSetData: number[][], labels: string[], dataSetLabels: string[], colors: string[], graphType: string, postFix: string){
    return this._graphService.generateStackedGraphData(dataSetData, labels, dataSetLabels, colors, graphType, postFix);
  }

  generateDonutGraphs(dataSetData: number[][], labels: string[], dataSetLabels: string[], colors: string[]){
    return this._graphService.generateDonutGraphs(dataSetData, labels, dataSetLabels, colors);
  }

  generateOptions(stacked: boolean, postFix: string, suggestedMin: number = null, suggestedMax: number = null){
    return this._graphService.generateOptions(stacked, postFix, suggestedMin, suggestedMax);
  }

  getNumberedLabels(start: number, end: number){
    let labels: string[] = [];
    for(let i = start; i <= end; i++){
      labels.push(i.toString());
    }
    return labels;
  }

  getProcessingRecordsForVLondun(item: ProcessingRecord){
    this._adminService.getProcessingRecordsForVLondun(item);
  }

  getProcessingRecordsForDay(item: ProcessingRecord){
    this._adminService.getProcessingRecordsForDay(item);
  }

  getDateByDagurString(dagur: string){
    let date = new Date();
    let parts = dagur.split('-');
    const year: number = parseInt(parts[0]);
    const month: number = parseInt(parts[1]);
    const day: number = parseInt(parts[2]);
    return new Date(year, month - 1, day);
  }

  getProcessingRecordStats(item: ProcessingRecordStats){
    this._adminService.getProcessingRecordStats(item);
  }

  getProcessingRecordStatsByDate(item: ProcessingRecordStats){
    this._adminService.getProcessingRecordStatsByDate(item);
  }

  onSelectedViewChange(event: ProcessingRecordViewModeEnum){
    this.activeView = event;
    if(event === this.heatViewEnum){
      this.selectedType = 'line';
      this.selectedTypeSignal.set('line');
    }else{
      this.selectedType = 'bar';
      this.selectedTypeSignal.set('bar');
    }
  }

  getValues(key: string, records: ProcessingRecord[]): number[]{
    console.log("records in: " + key, records)
    if(records?.length > 0){
      const values: number[] = records.map(record => record[key]).filter(value => value !== undefined) as number[];
      console.log("getValues: " + key, values);
      return values;
    }else{
      console.log("no records: " + key)
      return [];
    }
  }

  getPerHourValues(records: number[], key: string = "", useFirst: boolean = false): number[]{
    console.log("records", records)
    if (records.length > 1) { // Check if there are at least two records
      const values: number[] = records.map((record, index) => {
        if(useFirst && index === 0){
          return record;
        }
        if (index > 0) {
          // Calculate the difference between the current vatn_m3 and the one before it
          const difference = record - records[index - 1];
          return 0 > difference ? record : difference;
        }
        return 0; // For the first record, as there is no previous one
      });
  
      console.log("getPerHourValues: " + key, values);
      return values;
    } else {
      return [];
    }
  }

  calculatePpmPerHour(thraarvornPerHourValues: number[], mjolPerHourValues: number[]): number[]{
    if(thraarvornPerHourValues.length > 0 && mjolPerHourValues.length > 0){
      return thraarvornPerHourValues.map((value, index) => {
        if(mjolPerHourValues[index] > 0){
          return value / mjolPerHourValues[index];
        }else{
          return 0;
        }
      });
    }else{
      return [];
    }
  }

  getCadaverineValues(records: ProcessingRecord[]): number[] {
    console.log("records", records)
    const values: number[] = records.map(record => record.niR_Cadaverine != 0 ? record.niR_Cadaverine / 1000 : 0).filter(value => value !== undefined) as number[];
    console.log("getCadaverineValues",values);
    return values;
  }

  getVatnsnotkunValues(records: ProcessingRecord[]): number[] {
    console.log("records", records)
    if(records.length > 0){
      const firstValue = records[0].vatn_m3;
      const values: number[] = records.map(record => record.vatn_m3 - firstValue).filter(value => value !== undefined) as number[];
      console.log("getVatnsnotkunValues",values);
      return values;
    }else{
      return [];
    }
  }

  getThraarvornValues(records: ProcessingRecord[]): number[] {
    console.log("records", records)
    const values: number[] = records.map(record => record.thraavorn_ppm).filter(value => value !== undefined) as number[];
    console.log("getThraarvornValues",values);
    return values;
  }

  getVatnsnotkunPerHourValues(records: ProcessingRecord[]): number[] {
    console.log("records", records)
    if (records.length > 1) { // Check if there are at least two records
      const values: number[] = records.map((record, index) => {
        if (index > 0) {
          // Calculate the difference between the current vatn_m3 and the one before it
          return record.vatn_m3 - records[index - 1].vatn_m3;
        }
        return record.vatn_m3; // For the first record, as there is no previous one
      });
  
      console.log("getVatnsnotkunPerHourValues", values);
      return values;
    } else {
      return [];
    }
  }

  getKlstLabels(landings: ProcessingRecord[]): string[] {
    if(landings.length > 0){
      const values: string[] = landings.map(landing => this.generateXLabelString(new Date(landing.dagur))).filter(value => value !== undefined) as string[];
      console.log("getKlstLabels",values);
      return values;
    }else{
      return []
    }
  }

  getLastIngridientValue(records: ProcessingRecord[]): number {
    console.log("records", records)
    if(records.length > 0){
      const lastRecord = records[records.length - 1];
      const lastValue = lastRecord.hraefni_tonn;
      console.log("getLastIngridientValue", lastValue);
      return lastValue;
    }else{
      return 0;
    }
  }

  getLastWaterUsageValue(records: ProcessingRecord[]): number {
    console.log("records", records)
    if(records.length > 0){
      const firstValue = records[0].vatn_m3;
      const lastRecord = records[records.length - 1];
      const lastValue = lastRecord.vatn_m3;
      console.log("getLastWaterUsageValue", lastValue);
      return lastValue - firstValue;
    }else{
      return 0;
    }
  }

  generateXLabelString(date: Date): string {
    const nextHour = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours());
    const day = nextHour.getDate();
    const month = nextHour.getMonth() + 1;
    const hour = nextHour.getHours();
    const dayString = day.toString();
    const monthString = this.getMonthString(month + 1); 
    const hourString = hour.toString();

    return dayString + "." + monthString + " kl." + hourString;
  }

  getMonthString(month: number): string {
    switch(month){
      case 1:
        return "Jan";
      case 2:
        return "Feb";
      case 3:
        return "Mars";
      case 4:
        return "Apr";
      case 5:
        return "Maí";
      case 6:
        return "Jún";
      case 7:
        return "Júl";
      case 8:
        return "Ágú";
      case 9:
        return "Sept";
      case 10:
        return "Okt";
      case 11:
        return "Nóv";
      case 12:
        return "Des";
      default:
        return "";
    }
  }

  getFullMonthString(month: number): string {
    switch(month){
      case 1:
        return "Janúar";
      case 2:
        return "Febrúar";
      case 3:
        return "Mars";
      case 4:
        return "Apríl";
      case 5:
        return "Maí";
      case 6:
        return "Júní";
      case 7:
        return "Júlí";
      case 8:
        return "Ágúst";
      case 9:
        return "September";
      case 10:
        return "Október";
      case 11:
        return "Nóvember";
      case 12:
        return "Desember";
      default:
        return "";
    }
  }

}

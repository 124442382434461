<ng-container [ngTemplateOutlet]="hasAccessToken ? hasAccessTokenTemplate : noAccessTokenTemplate"></ng-container>

<ng-template #hasAccessTokenTemplate>
  <div class="row" style="padding-top: 20px !important;">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <p-card>
        <ng-template pTemplate="header">
          <div class="routing-card-header">
            <h4>Nýtt lykilorð</h4>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <p-divider align="left">
            <div class="inline-flex align-items-center">
              <b>Netfang</b>
            </div>
          </p-divider>
          <div class="field col-12 md:col-4 dialog-form-field">
            <span class="p-float-label">
              <input type="text" pInputText [(ngModel)]="newPasswordUser.email" placeholder="Netfang"
                style="width: 100%" />
              <label htmlFor="email">Netfang</label>
            </span>
          </div>
          <p-divider align="left">
            <div class="inline-flex align-items-center">
              <b>Lykilorð</b>
            </div>
          </p-divider>
          <div class="field col-12 md:col-4 dialog-form-field">
            <span class="p-float-label">
              <input type="password" pInputText [(ngModel)]="password" placeholder="Lykilorð" style="width: 100%" />
              <label htmlFor="password">Lykilorð</label>
            </span>
          </div>
          <div class="field col-12 md:col-4 dialog-form-field">
            <span class="p-float-label">
              <input type="password" pInputText [(ngModel)]="confirmPassword" placeholder="Lykilorð aftur"
                style="width: 100%" />
              <label htmlFor="password2">Lykilorð aftur</label>
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="footer">
          <div class="row">
            <div class="col-md-8">
              <p-message  severity="error" [text]="validationErrorMessage" *ngIf="validationErrorMessage.length > 0"></p-message>
            </div>
            <div class="col-md-4">
              <div style="display: flex; justify-content: flex-end;">
                <button pButton label="Endurstilla lykilorð" class="p-button-sm"
                  (click)="validatePassword(password, confirmPassword, newPasswordUser.email)"></button>
              </div>
            </div>
          </div>
        </ng-template>
      </p-card>
    </div>
    <div class="col-md-3"></div>
  </div>
</ng-template>

<ng-template #noAccessTokenTemplate>
  <div class="row" style="padding-top: 20px !important;">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <p-card>
        <ng-template pTemplate="header">
          <div class="routing-card-header">
            <h4>Nýtt lykilorð</h4>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="field col-12 md:col-4 dialog-form-field">
            <span class="p-float-label">
              <input type="text" pInputText [(ngModel)]="newPasswordEmail.recipientEmail" placeholder="Netfang"
                style="width: 100%" />
              <label htmlFor="email">Netfang</label>
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="footer">
          <div class="row">
            <div class="col-md-8">
              <p-message *ngIf="validationErrorMessage.length > 0" severity="error" [text]="validationErrorMessage"></p-message>
            </div>
            <div class="col-md-4">
              <div style="display: flex; justify-content: flex-end;">
                <button pButton label="Endurstilla lykilorð" class="p-button-sm"
                  (click)="sendUpdatePasswordEmail(newPasswordEmail)"></button>
              </div>
            </div>
          </div>
        </ng-template>
      </p-card>
    </div>
    <div class="col-md-3"></div>
  </div>
</ng-template>

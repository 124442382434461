export const CurrentUser = "LandingPages2021CurrentUser";

export const CurrentLocaleString  = 'is-IS';
export const CurrentLocaleStringShort = 'is';

export const DefaultLandingSumXAxis: Object =  {
    valueType: 'DateTime',
    title: 'Tími',
    labelFormat: 'dd.MM HH:mm',
    labelIntersectAction: 'Rotate45'
}

export const DefaultLandingSumYAxis: Object =  {
    labelFormat: '{value}°C',
    title: 'Hitastig (°C)'
}

export const DefaultLandingSumXAxisHourly: Object =  {
    valueType: 'DateTimeCategory',
    title: 'Tími',
    labelFormat: 'dd.MM HH:mm',
    interval: 'Hours',
    labelIntersectAction: 'Rotate45'
}

export const DefaultLandingSumYAxisHourly: Object =  {
    labelFormat: '{value}',
    title: 'Þyngd (tonn) per klst.'
}

export const DefaultLandingSumYAxisWeight: Object =  {
    labelFormat: '{value} tonn',
    title: 'Þyngd (tonn)'
}

export const DefaultEmptySeries1: Object =  {
    mode: 'Gap'
}

export const DefaultGraphCrosshair: Object =  {
    enable: true, 
    lineType: 'Vertical'
}

export const DefaultGraphMarker: Object =  {
    visible: true, 
    dataLabel: { visible: true, position: 'Top' }
}

export const DefaultGraphTooltip: Object =  {
    enable: true, 
    shared: true, 
    format: '${series.name} : ${point.x} : ${point.y}'
}

export const DefaultPageSettings: Object =  {
    pageSizes: [10,25,50,100,200], 
    pageSize: 25
}

export const BrimBlue = '#215b9e';
export const White = '#FFFFFF';
export const Black = '#000000';
export const Red = '#ff0000';
export const Green = '#008000';
export const Yellow = '#FFFF00';

export const StdBlue = '#6690bc';
export const StdPurple = '#ae91ac';
export const StdOrange = '#c68a73';
export const StdBrown = '#a47b69';
export const StdGreen = '#b2c699';
export const AltGreen = '#9cb4b4';
export const AltOrange = '#d08d3b';
export const StdGrey = "#847d7f";

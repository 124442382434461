import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor(private _injector: Injector) { }

  handleError(errorResponse: any ): Observable<any>{
    const router = this._injector.get(Router);
    console.log(router);
    console.log(`Request URL: ${router.url}`);
    console.log(errorResponse);

    if(errorResponse instanceof HttpErrorResponse) {
      console.error('Backend returned status code:', errorResponse.status);
      console.error('Response body:', errorResponse.message);
      if(errorResponse.error instanceof ErrorEvent) {   //Error
        console.error('Client Side Error', errorResponse.error.message);

        //return new Observable("There is a problem with the service. We have been notified and are working on the solution");
        //Client side or network error
      } else if(errorResponse.error instanceof ProgressEvent) {
        console.error('Server Side Error - Progress Event', errorResponse);

      } 
      
      else{
        console.error('Server Side Error', errorResponse);
      }
    
    }     
    else if(errorResponse instanceof Observable){
      console.error('Error Observable', errorResponse);
      return errorResponse;

     } 
    else{      
      console.error('An error occurred: ', errorResponse.message);
      //router.navigate(['error']);
    }
    
    return new Observable();
  }

  


}
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthStoreService } from 'src/app/services/auth-store.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { DataOperation, DialogActionEnum } from 'src/app/shared/enums';
import { Customer, MasterShip, Ship } from 'src/app/shared/models';
import { CustomerOperationsRequest, MasterShipOperationsRequest, ShipOperationsRequest } from 'src/app/shared/request-models';

@Component({
  selector: 'app-add-customer-to-ship',
  templateUrl: './add-customer-to-ship.component.html',
  styleUrls: ['./add-customer-to-ship.component.css']
})
export class AddCustomerToShipComponent implements OnInit {
  //Item to edit
  editRow: MasterShip;

  //Action to perform
  actionMode: DialogActionEnum;
  insertAction: DialogActionEnum = DialogActionEnum.Insert;
  updateAction: DialogActionEnum = DialogActionEnum.Update;
  deleteAction: DialogActionEnum = DialogActionEnum.Delete;
  viewAction: DialogActionEnum = DialogActionEnum.View;
  
  actionButtonLabel: string = "";

  validationErrorMessage: string = "";

  customers: Customer[] = [] as Customer[];
  filteredCustomers: Customer[] = [] as Customer[];

  inputError: boolean = false;
  inputErrorMessage: string = '';

  selectedCustomer: Customer = {} as Customer;

  actionForm: UntypedFormGroup = new UntypedFormGroup({
    actionFormCustomerId: new UntypedFormControl('', {
      validators: []
    }),
    actionFormCustomerName: new UntypedFormControl('', {
      validators: []
    }),
  });
  get actionFormCustomerId() {
    return this.actionForm.get('actionFormCustomerId');
  }

  get actionFormCustomerName() {
    return this.actionForm.get('actionFormCustomerName');
  }

  constructor(
    private _auth: AuthStoreService,
    private _dataService: DataService,
    private _errorHandler: ErrorHandlerService,
    private _toastr: ToastrService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
    this.actionMode = this.config.data.action;
    this.getActionLabel(this.actionMode);
    if(this.actionMode == DialogActionEnum.Insert){
      this.editRow = {} as MasterShip;
    }else{
      this.editRow = this.config.data.editRow;
    }
    this.getCustomers();
  }

  getActionLabel(action: DialogActionEnum){
    switch(action){
      case DialogActionEnum.Insert:
        this.actionButtonLabel = "Stofna";
        break;
      case DialogActionEnum.Update:
        this.actionButtonLabel = "Uppfæra";
        break;
      case DialogActionEnum.Delete:
        this.actionButtonLabel = "Eyða";
        break;
      case DialogActionEnum.SetPassword:
        this.actionButtonLabel = "Stilla lykilorð";
        break;
      default:
        this.actionButtonLabel = "";
        break;
    }
  }

  getCustomers(){
    let request: CustomerOperationsRequest = new CustomerOperationsRequest();
    request.action = DataOperation.List;
    request.item = {} as Customer;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }

    this._dataService.customerOperations(request).subscribe((data) => {
      if (data.message.ok === true) {
        this.customers = data.items;
        this.filteredCustomers = data.items;
      }
      else {
        console.error("message", data.message);
      }
    }, (err) => this.ifError(err));
  }

  setSelectedCustomer(option:any){
    console.log(option);
    this.getCurrentCustomer(option.value);
  }

  getCurrentCustomer(id: string){
    let request: CustomerOperationsRequest = new CustomerOperationsRequest();
    request.action = DataOperation.Read;
    request.item = {} as Customer;
    request.item.id = id;
    
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }

    this._dataService.customerOperations(request).subscribe((data) => {
      console.log('data', data);
      this.selectedCustomer = data.items[0];
     
      this.actionFormCustomerId.setValue(this.selectedCustomer.id);
      this.actionFormCustomerName.setValue(this.selectedCustomer.name);

    }, (err) => this.ifError(err));
  }

  onFilterStringChange($event: any){
    if($event.target.value?.length > 0){
      this.filteredCustomers = this.customers.filter(item => {
        return item.ssn.toLowerCase().includes($event.target.value)
        || item.name.toLowerCase().includes($event.target.value)
      });
    }else{
      this.filteredCustomers = this.customers;
    }
  }

  onSubmit(){
    let request: MasterShipOperationsRequest = new MasterShipOperationsRequest();
    request.action = DataOperation.SetCustomerId;
    request.item = this.editRow;
    request.item.customerId = this.actionFormCustomerId.value;
    if (this._auth.currentUser) {
      request.key = this._auth.currentUser.id;
    }

    this._dataService.masterShipOperations(request).subscribe((data) => {
      if (data.message.ok === true) {
        this._toastr.success("Viðskiptavinur á " + this.editRow.name + " uppfærður í " + this.selectedCustomer.name);
        this.ref.close({
          status: "success"
        });
      }
      else {
        this._toastr.success("Ekki gekk að uppfæra viðskiptavin á " + this.editRow.name);
        console.error("message", data.message);
      }
    }, (err) => this.ifError(err));
  }

  ifError(err: any) {
    //not implemented
    console.log(err);
    return this._errorHandler.handleError(err);
  };

}

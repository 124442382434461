import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { CurrentUser } from '../shared/constants';
import { User } from '../shared/models';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private _router: Router) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (localStorage.getItem(CurrentUser) != undefined) {
            let currentUser: User = JSON.parse(atob(localStorage.getItem(CurrentUser)));
            if (currentUser && currentUser.token) {

                try {


                    //console.log('interceptor token expires: ', currentUser.expires, new Date());
                    var date = moment(currentUser.expires);
                    var now = moment();

                    if (date < now) {
                        //console.log('interceptor, token expired');
                        localStorage.clear();

                        this._router.navigate(['/innskraning']);
                    }
                    else {
                        //console.log('interceptor, token NOT expired');
                        request = request.clone({
                            setHeaders: {
                                Authorization: `Bearer ${currentUser.token}`
                            }
                        });

                    }

                }
                catch (e) {

                    console.log('interceptor, token corrupted');
                    localStorage.clear();

                    this._router.navigate(['/innskraning']);
                }

                //console.log('interceptor added header',currentUser.token);
            }
            else {
                //console.log('interceptor no token and no header added..');
            }
        }

        //skoða hvort það sé unauthorized villa.  Ef svo er þá redirect á login síðu.

        return next.handle(request);
    }
}

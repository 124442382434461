<div>
    <h4>Summutölur: {{currentShip.name}}</h4>
    <ejs-grid #grid [dataSource]='allLandingSums'  [allowPaging]='true' 
    [pageSettings]='pageSettings' [allowSorting]='true' [allowResizing]='true'>
        <e-columns>
            <e-column field='dagur' type="date" format="yyyy.MM.dd" headerText='Dagur' textAlign='Right' width=80></e-column>
            <e-column field='farmnumer' headerText='Farm nr.' width=70></e-column>
            <e-column field='weighterName' headerText='Vigtarmaður' width=150></e-column>
            <e-column field='fishSpeciesName' headerText='Fisktegund' width=100></e-column>
            <e-column field='heildarmagn' headerText='Magn heild' width=100></e-column>
            <e-column field='magn_Unnid' headerText='Magn unnið' width=100></e-column>
            <e-column field='veidisvaedi' headerText='veiðsisvæði' width=150></e-column>
            <e-column headerText="Aðgerðir">
                <ng-template #template let-landingSum>
                    <i class="fal fa-hand-pointer fa-action-button" pTooltip="Skoða Löndunarskýrslu" 
                    [routerLinkActive]="['link-active']" [routerLink]="['/londunarskyrslur/' + landingSum.id_Summa]"></i>
                </ng-template>
            </e-column>
        </e-columns>
    </ejs-grid>

</div>
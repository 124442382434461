import { Component } from '@angular/core';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { MasterShip } from 'src/app/shared/models';
import { AdminService } from '../../admin.service';
import { DialogActionEnum } from 'src/app/shared/enums';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-master-ship-dialog',
  templateUrl: './master-ship-dialog.component.html',
  styleUrls: ['./master-ship-dialog.component.css']
})
export class MasterShipDialogComponent {

    //Item to edit
    editRow: MasterShip;
  
    //Action to perform
    actionMode: DialogActionEnum;
    insertAction: DialogActionEnum = DialogActionEnum.Insert;
    updateAction: DialogActionEnum = DialogActionEnum.Update;
    deleteAction: DialogActionEnum = DialogActionEnum.Delete;
    viewAction: DialogActionEnum = DialogActionEnum.View;
    
    actionButtonLabel: string = "";
  
    validationErrorMessage: string = "";

  constructor(
    private _errorHandler: ErrorHandlerService,
    private _adminService: AdminService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
    this.actionMode = this.config.data.action;
    this.getActionLabel(this.actionMode);
    if(this.actionMode == DialogActionEnum.Insert){
      this.editRow = {} as MasterShip;
    }else{
      this.editRow = this.config.data.editRow;
    }
  }

  getActionLabel(action: DialogActionEnum){
    switch(action){
      case DialogActionEnum.Insert:
        this.actionButtonLabel = "Stofna";
        break;
      case DialogActionEnum.Update:
        this.actionButtonLabel = "Uppfæra";
        break;
      case DialogActionEnum.Delete:
        this.actionButtonLabel = "Eyða";
        break;
      case DialogActionEnum.SetPassword:
        this.actionButtonLabel = "Stilla lykilorð";
        break;
      default:
        this.actionButtonLabel = "";
        break;
    }
  }

  action(item: MasterShip, action: DialogActionEnum) {
    if(this.validateForm(item)){
      this.performAction(item, action);
    }
  }

  validateForm(item: MasterShip){
    if(!this.validateName(item)){
      this.validationErrorMessage = "Skipaskrá verður að hafa nafn";
      return false;
    }
    return true;    
  }

  validateName(item: MasterShip){
    return item?.name?.length > 0 ? true : false;
  }

  performAction(item: MasterShip, action: DialogActionEnum){
    switch(action){
      case DialogActionEnum.Insert:
        this.insertMasterShip(item);
        break;
      case DialogActionEnum.Update:
        this.updateMasterShip(item);
        break;
      case DialogActionEnum.Delete:
        this.deleteMasterShip(item);
        break;
      default:
        break;
    }
  }

  insertMasterShip(item: MasterShip){
    this._adminService.addMasterShip(item);
    this.ref.close(item);
  }

  updateMasterShip(item: MasterShip){
    this._adminService.updateMasterShip(item);
    this.ref.close(item);
  }

  deleteMasterShip(item: MasterShip){
    this._adminService.removeMasterShip(item);
    this.ref.close(item);
  }

  ifError(err: any) {
    console.log(err);

    return this._errorHandler.handleError(err);
  };

}

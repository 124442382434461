import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthStoreService } from 'src/app/services/auth-store.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { PropertiesStoreService } from 'src/app/services/properties-store.service';
import { User } from 'src/app/shared/models';
import { LoginResult } from 'src/app/shared/request-models';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})
export class LogInComponent implements OnInit {
  loginForm: UntypedFormGroup;
  isLoading = false;
  loginResult: LoginResult;
  loginError: Boolean = false;
  loginErrorMessage: string = 'Innskráning mistókst';

  constructor(
    private _auth: AuthStoreService,
    private _errorHandler: ErrorHandlerService,
    private _propertiesStoreService: PropertiesStoreService,
    private _router: Router,

  ) { }

  ngOnInit(): void {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', {
        validators: [Validators.required, Validators.email]
      }),
      password: new UntypedFormControl('', { validators: [Validators.required] })
    });
  }

  onSubmit() {
    this.loginError = false;
    this.isLoading = true;

    let email: string = this.loginForm.controls.email.value;
    let password: string = this.loginForm.controls.password.value;

    this._auth.login(email, password).subscribe((response: LoginResult) => {
      console.log('login attempted: ' + this.loginForm.controls.email.value);
      this.loginResult = response;
      // console.log(response);

      let user: User = this.loginResult.user;

      if (user?.authenticated && user?.admin) {
        console.log("user authentication successful");

        this._router.navigate(['/agents']);
      }
      else if (user?.authenticated && !user?.admin && user?.active) {
        console.log("user authentication successful");
        this._router.navigate(['/ships']);
      }
      else {
        console.log("user authentication failed");
        this.loginError = true;
        this.loginErrorMessage = "Innskráning mistókst"; // this.loginResult.message.name;
      }
    }, (err: any) => this.ifError(err));

    this.isLoading = false;
  }

  lostPassword() {
    
  }

  ifError(err: any) {
    //not implemented
    console.log(err);

    this.isLoading = false;
    return this._errorHandler.handleError(err);
  };

}

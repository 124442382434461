import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStoreService } from '../services/auth-store.service';
import { User } from '../shared/models';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;

  currentUser$: Observable<User> = this._auth.currentUser$;

  suppressNavigation = false;

  constructor(
    private _router: Router,
    private _auth: AuthStoreService,
    private _route: ActivatedRoute

    ) {
    console.log('loading...');

  }
  ngOnInit(): void {
    this._route.queryParamMap.subscribe(params => {
      console.log('query params', params);
      if (params.has('suppressNavigation')) {
        this.suppressNavigation = true;
        console.log('suppressNavigation', this.suppressNavigation);
      }
    }); // output:
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  onBurgerMenuClick(element: any){
    console.log("onBurgerMenuClick", element);
    element.checked = false;
  }

  toggleClick() {
    //console.log(this.sidebar.isOpen)
    // if (this.sidebar.isOpen){
    //   this.closeClick()
    // }
    // else{
    //   this.openClick();
    // }
  }
  closeClick() {
    // this.sidebar.hide();
    // this.sidebar.isOpen = false;
    //console.log("close");
  }
  openClick() {
    // this.sidebar.show();
    // this.sidebar.isOpen = true;
  }

  logOut(){
    // this._router.navigate(["/utskraning"]);
    this._auth.logout();
    this._router.navigate(['/innskraning']);
  }
}

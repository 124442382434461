import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { DialogActionEnum } from 'src/app/shared/enums';
import { EmailStringValidator, SsnValidator } from 'src/app/shared/helpers';
import { User } from 'src/app/shared/models';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.css']
})
export class UserDialogComponent implements OnInit {
  //Item to edit
  editRow: User;
  
  //Action to perform
  actionMode: DialogActionEnum;
  insertAction: DialogActionEnum = DialogActionEnum.Insert;
  updateAction: DialogActionEnum = DialogActionEnum.Update;
  deleteAction: DialogActionEnum = DialogActionEnum.Delete;
  viewAction: DialogActionEnum = DialogActionEnum.View;
  setPasswordAction: DialogActionEnum = DialogActionEnum.SetPassword;

  actionButtonLabel: string = "";

  validationErrorMessage: string = "";

  password: string = "";
  confirmPassword: string = "";

  ssnValidator = SsnValidator;
  emailValidator = EmailStringValidator;

  constructor(
    private _errorHandler: ErrorHandlerService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.actionMode = this.config.data.action;
    this.getActionLabel(this.actionMode);
    if(this.actionMode == DialogActionEnum.Insert){
      this.editRow = {} as User;
    }else{
      this.editRow = this.config.data.editRow;
    }
  }

  getActionLabel(action: DialogActionEnum){
    switch(action){
      case DialogActionEnum.Insert:
        this.actionButtonLabel = "Stofna";
        break;
      case DialogActionEnum.Update:
        this.actionButtonLabel = "Uppfæra";
        break;
      case DialogActionEnum.Delete:
        this.actionButtonLabel = "Eyða";
        break;
      case DialogActionEnum.SetPassword:
        this.actionButtonLabel = "Stilla lykilorð";
        break;
      default:
        this.actionButtonLabel = "";
        break;
    }
  }

  validateForm(item: User){
    if(!this.validateHasSsn(item)){
      this.validationErrorMessage = "Notandi verður að hafa kennitölu";
      return false;
    }
    if(!this.validateSsn(item)){
      this.validationErrorMessage = "Kennitala verður að vera á réttu formi";
      return false;
    }
    if(!this.validateName(item)){
      this.validationErrorMessage = "Notandi verður að hafa nafn";
      return false;
    }
    if(!this.validateHasEmail(item)){
      this.validationErrorMessage = "Notandi verður að hafa netfang";
      return false;
    }
    if(!this.validateEmail(item)){
      this.validationErrorMessage = "Notandi verður að hafa gilt netfang";
      return false;
    }
    return true;    
  }

  validateHasSsn(item: User){
    return item?.ssn?.length > 0 ? true : false;
  }

  validateSsn(item: User){
    return this.ssnValidator(item.ssn, true);
  }

  validateName(item: User){
    return item?.name?.length > 0 ? true : false;
  }

  validateHasEmail(item: User){
    return item?.email?.length > 0 ? true : false;
  }

  validateEmail(item: User){
    return this.emailValidator(item.email, true, false);
  }

  validatePassword(password: string, confirmPassword: string, item: User){
    if(!this.passwordsMatch(password, confirmPassword)){
      console.warn('Lykilorð stemma ekki');
      this.validationErrorMessage = 'Lykilorð stemma ekki';
      return
    }
    if(!this.passwordLengthValid(password)){
      console.warn('Lykilorð verður að vera að minnsta kosti 8 stafir að lengd')
      this.validationErrorMessage = 'Lykilorð verður að vera að minnsta kosti 8 stafir að lengd';
      return
    }
    if(!this.passwordHasNumber(password)){
      console.warn('Lykilorð verður að innihalda tölustafi')
      this.validationErrorMessage = 'Lykilorð verður að innihalda tölustafi';
      return
    }
    this.validationErrorMessage = '';
    item.password = password;
    this.updatePassword(item);

  }

  passwordsMatch(password: string, confirmPassword: string){
    return password === confirmPassword;
  }

  passwordLengthValid(password: string){
    return password.length >= 8;
  }

  passwordHasNumber(password: string){
    return /\d/.test(password);
  }

  action(item: User, action: DialogActionEnum) {
    if(action != DialogActionEnum.SetPassword){
      if(this.validateForm(item)){
        this.performAction(item, action);
      }
    }else{
      this.passwordAction(item);
    }
  }
  
  passwordAction(item: User){
    this.validatePassword(this.password, this.confirmPassword, item);
  }

  performAction(item: User, action: DialogActionEnum){
    switch(action){
      case DialogActionEnum.Insert:
        this.insertUser(item);
        break;
      case DialogActionEnum.Update:
        this.updateUser(item);
        break;
      case DialogActionEnum.Delete:
        this.deleteUser(item);
        break;
      default:
        break;
    }
  }

  insertUser(item: User){
    console.log('insertUser', item);
    this._adminService.addUser(item);
    this.ref.close(item);
  }

  updateUser(item: User){
    console.log('updateUser', item);
    this._adminService.updateUser(item);
    this.ref.close(item);
  }

  deleteUser(item: User){
    console.log('deleteUser', item);
    this._adminService.deleteUser(item);
    this.ref.close(item);
  }

  updatePassword(item: User){
    this._adminService.setUserPassword(item);
    this.ref.close(item);
  }

  ifError(err: any) {
    console.log(err);

    return this._errorHandler.handleError(err);
  };

}

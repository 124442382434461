<div [ngClass]="isMobileSignal() ? 'p-fluid' : 'center-container'">
    <p-selectButton [options]="viewOptions" [(ngModel)]="selectedView" (ngModelChange)="viewChanged(selectedView)" optionLabel="label" optionValue="value"></p-selectButton>
</div>


<ng-container
    [ngTemplateOutlet]="
        selectedView === monthViewEnum 
        ? monthView 
        : selectedView === vinnsluLotaEnum 
            ? vinnsluLotaView
            : noView"
    [ngTemplateOutletContext]="{ processingRecords: allProcessingRecordsSignal(), processingRecordMonth: allProcessingDatesByMonthSignal(), vinnsluLotur: vinnsluLotaSignal() }"
></ng-container>

<ng-template #calendarSelection>
    <div [ngClass]="isMobileSignal() ? 'p-fluid' : 'center-container'">
        <p-calendar view="month" dateFormat="MM yy" [(ngModel)]="currentMonth" [showIcon]="true" (ngModelChange)="dateChanged($event)" [maxDate]="today"></p-calendar>
    </div>
</ng-template>

<ng-template #monthView let-processingRecords="processingRecords" let-processingRecordMonth="processingRecordMonth">
    <ng-container
    [ngTemplateOutlet]="calendarSelection"
    ></ng-container>
    <ng-container 
        [ngTemplateOutlet]="monthTable"
        [ngTemplateOutletContext]="{ processingRecordMonth: processingRecordMonth }"
    ></ng-container>
</ng-template>

<ng-template #monthTable let-processingRecordMonth="processingRecordMonth">
    <ejs-grid #grid [dataSource]='processingRecordMonth' [allowSorting]='true' [allowResizing]='true'>
        <e-columns>
            <e-column field='dagur' type="date" format="dd.MM.yyyy" headerText='Dags' width="120"></e-column>
            <e-column headerText="Aðgerðir" width=150>
                <ng-template #template let-landingSum>
                    <i class="fal fa-hand-pointer fa-action-button" pTooltip="Skoða dagsskýrslu"
                    (click)="showDateReport(landingSum.dagur)"></i>
                </ng-template>
            </e-column>
        </e-columns>
    </ejs-grid>
</ng-template>

<ng-template #dayView let-processingRecords="processingRecords">

    <h4>{{currentDate | date:'d.M yyyy'}}</h4>
    <ejs-grid #grid [dataSource]='processingRecords' [allowSorting]='true' [allowResizing]='true'>
    <e-columns>
        <e-column field='dagur_InT' type="date" format="yyyy.MM.dd HH:mm" headerText='dagur_InT' width=120></e-column>
        <e-column field='v_Lota' headerText='v_Lota' width=90></e-column>
        <e-column field='hraefni_tonn' headerText='hraefni_tonn' width=120></e-column>
        <e-column field='vinnsla_TVN' headerText='vinnsla_TVN' width=120></e-column>
        <e-column field='lysi_m3' headerText='lysi_m3.' width=80></e-column>
        <e-column headerText="Aðgerðir" width=150>
            <ng-template #template let-landingSum>
                <i class="fal fa-hand-pointer fa-action-button" pTooltip="Skoða Vinnslutölur"
                [routerLinkActive]="['link-active']" [routerLink]="['/vinnslutolur/' + landingSum.v_Lota]"></i>
            </ng-template>
        </e-column>
        </e-columns>
    </ejs-grid>
</ng-template>

<ng-template #vinnsluLotaView let-vinnsluLotur="vinnsluLotur">
    <ejs-grid #grid [dataSource]='vinnsluLotur' [allowSorting]='true' [allowResizing]='true'>
    <e-columns>
        <e-column field='v_Lota' headerText='v_Lota' width=90></e-column>
        <e-column field='v_Tegund' headerText='v_Tegund' width=90></e-column>
        <e-column field='dagur_Byrja' type="date" format="yyyy.MM.dd HH:mm" headerText='dagur_Byrja' width=120></e-column>
        <e-column field='dagur_Enda' type="date" format="yyyy.MM.dd HH:mm" headerText='dagur_Enda' width=120></e-column>
        <e-column headerText="Aðgerðir" width=150>
            <ng-template #template let-landingSum>
                <i class="fal fa-hand-pointer fa-action-button" pTooltip="Skoða Vinnslutölur"
                [routerLinkActive]="['link-active']" [routerLink]="['/vinnslutolur/vinnsla/' + landingSum.v_Lota]"></i>
            </ng-template>
        </e-column>
        </e-columns>
    </ejs-grid>
</ng-template>

<ng-template #noView>
    Eitthvað fór úrskeiðis
</ng-template>
import { Component } from '@angular/core';
import { AdminService } from './admin/admin.service';
import { debounceTime, fromEvent } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
  resize$ = fromEvent(window, 'resize').pipe(debounceTime(250));
  scrW = this._adminService.scrW;

  constructor(private _adminService: AdminService) { }

  ngOnInit() {
    this.scrW.set(window.innerWidth)
    this.resize$.subscribe(event => {
      let w = event.target as Window
      this.scrW.set(w.innerWidth)
    })

  }
}

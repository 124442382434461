<div>
    <div class="top-section">
        <h2 class="page-title">Viðskiptavinir</h2>
        <button type="button" class="btn btn-primary" (click)="actionItem(null, insertAction)">Stofna Viðskiptavin</button>
    </div>
    <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="agent">Sía viðskiptavini</span>
        </div>
        <input type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"
          [(ngModel)]="filterString" (ngModelChange)="onFilterStringChange()">
      </div>
    <div>
        <ejs-grid #grid [dataSource]='filteredCustomersSignal()' [allowPaging]='true' [pageSettings]='pageSettings'
            [allowSorting]='true' [allowResizing]='true' (dataBound)="dataBound()">
            <e-columns>
                <e-column field='name' headerText='Nafn' width=120></e-column>
                <e-column field='ssn' headerText='Kennitala' width=90></e-column>
                <e-column field='address' headerText='Heimilisfang' width=120></e-column>
                <e-column field='city' headerText='Bæjarfélag' width=120></e-column>
                <e-column field='shipNumber' headerText='Skipanr.' width=80></e-column>
                <e-column headerText="Aðgerðir">
                    <ng-template #template let-landingSum>
                        <i class="fal fa-hand-pointer fa-action-button" pTooltip="Skoða {{landingSum.name}}"
                            (click)="actionItem(landingSum, viewAction)"></i>
                        <i class="far fa-edit fa-action-button" pTooltip="Uppfæra {{landingSum.name}}"
                            (click)="actionItem(landingSum, updateAction)"></i>
                        <i class="fas fa-trash fa-action-button" pTooltip="Eyða {{landingSum.name}}"
                            (click)="actionItem(landingSum, deleteAction)"></i>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>
    </div>
</div>